<style  lang="scss" scoped>
[tooltip] {
    position: relative; /* Base position */
    z-index: 10000; /* Increase z-index to ensure it's on top */
}

/* Applies to all tooltips */
[tooltip]::before,
[tooltip]::after {
    text-transform: none;
    font-size: .9em;
    line-height: 1;
    user-select: none;
    pointer-events: none;
    position: absolute;
    display: none;
    opacity: 0;
    z-index: 10001; /* Ensure tooltip stays above other elements */
}
[tooltip]::before {
    content: '';
    border: 5px solid transparent;
    z-index: 10002; /* Arrow stays on top as well */
}

[tooltip]::after {
    content: attr(tooltip);
    font-family: Helvetica, sans-serif;
    text-align: center;
    min-width: 3em;
    max-width: 21em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1ch 1.5ch;
    border-radius: .3ch;
    box-shadow: 0 1em 2em -.5em rgba(0, 0, 0, 0.35);
    background: #333;
    color: #fff;
    z-index: 10001; /* Ensures it's above the content */
}

/* Tooltip visible on hover */
[tooltip]:hover::before,
[tooltip]:hover::after {
    display: block;
}


/* Tooltip positioning for "up" flow */
[tooltip]:not([flow])::before,
[tooltip][flow^="up"]::before {
    bottom: 100%;
    border-bottom-width: 0;
    border-top-color: #333;
}

[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::after {
  bottom: calc(100% + 5px);
}
[tooltip]:not([flow])::before,
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::before,
[tooltip][flow^="up"]::after {
  left: 50%;
  transform: translate(-50%, -.5em);
}

/* FLOW: DOWN */
[tooltip][flow^="down"]::before {
  top: 100%;
  border-top-width: 0;
  border-bottom-color: #333;
}
[tooltip][flow^="down"]::after {
  top: calc(100% + 5px);
}
[tooltip][flow^="down"]::before,
[tooltip][flow^="down"]::after {
  left: 50%;
  transform: translate(-50%, .5em);
}

/* FLOW: LEFT */
[tooltip][flow^="left"]::before {
  top: 50%;
  border-right-width: 0;
  border-left-color: #333;
  left: calc(0em - 5px);
  transform: translate(-.5em, -50%);
}
[tooltip][flow^="left"]::after {
  top: 50%;
  right: calc(100% + 5px);
  transform: translate(-.5em, -50%);
}

/* FLOW: RIGHT */
[tooltip][flow^="right"]::before {
  top: 50%;
  border-left-width: 0;
  border-right-color: #333;
  right: calc(0em - 5px);
  transform: translate(.5em, -50%);
}
[tooltip][flow^="right"]::after {
  top: 50%;
  left: calc(100% + 5px);
  transform: translate(.5em, -50%);
}

/* KEYFRAMES */
@keyframes tooltips-vert {
  to {
    opacity: .9;
    transform: translate(-50%, 0);
  }
}

@keyframes tooltips-horz {
  to {
    opacity: .9;
    transform: translate(0, -50%);
  }
}

/* FX All The Things */
[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^="up"]:hover::before,
[tooltip][flow^="up"]:hover::after,
[tooltip][flow^="down"]:hover::before,
[tooltip][flow^="down"]:hover::after {
  animation: tooltips-vert 300ms ease-out forwards;
}

[tooltip][flow^="left"]:hover::before,
[tooltip][flow^="left"]:hover::after,
[tooltip][flow^="right"]:hover::before,
[tooltip][flow^="right"]:hover::after {
  animation: tooltips-horz 300ms ease-out forwards;
}

</style>

<template>
    <div class="item-wrapper my-2">
        <a :tooltip="listItem.title" flow="right" :href="listItem.url" class="sidebar-item flex py-1 text-decoration-none collapsed_item" :class="{'active-bg shadow-md rounded-lg' : isActivePage}">
            <div class="icon-box p-2 my-auto mx-auto">
                <icon :icon="listItem.icon" :class="{'active-sidebar-icon' : isActivePage}" style="width: 20px;"></icon>
            </div>
            <div class="list-label flex-grow my-auto p-2 ml-3 d-none z-40">
                <span class="sidenav-link d-none" :class="{'active-link text-white font-semibold' :isActivePage}">{{ listItem.title }}</span>
            </div>

        </a>
    </div>
</template>

<script>
import Icon from '../../../Icon';

    export default {
        data() {
            return {
                isActivePage: false,
            }
        },
        props: {
            listItem: {
                type: Object,
            }
        },
        components: {
            Icon
        },
        mounted()
        {
            if(window.location.href.indexOf(this.listItem.url) > -1)
            {
                this.isActivePage = true;

            }
        }

    }
</script>
