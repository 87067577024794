<style lang="scss">
    .profile-small-header-section {
        border-bottom: 2px solid #0BD2A4;

        h1 {
            font-size: 25px;
            line-height: 12px;
            color: #717170;
            font-weight: 700;
            padding-bottom: 5px;
        }
    }

    .profile-main-content-section {
        p {
            color: #717170;
            font-size: 17px;
            line-height: 28px;
            white-space: pre-wrap;
        }
    }

    .profile-main-content-section {

        .service-list-box {

            .service-list-item {
                margin-right: 80px;


                .icon-round-bg {
                    background-color: #ffff;
                    border-radius: 50%;
                    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 7px 0px;
                }

                h5 {
                    color: #717170;
                    font-size: 18px;
                    font-weight: 500;
                }
            }

        }
    }
    .location-map {

     // position: relative;
    // height: 265px!important;
    }

    #map {
     //  position: absolute;
       margin: 0!important;
       width: 100%;
       height: 300px!important;
    }

    .mapboxgl-canvas-container {
       height: 100%!important;
       width: 100%!important;
       border-radius: 15px!important;
    }

    .mapboxgl-ctrl-bottom-right{
       display: none!important;
    }

    .mapboxgl-canvas {
       border-radius: 15px!important;
    }


</style>

<template>
    <div class="section-wrapper w-full">
        <div class="profile-small-header-section">
            <h1>Reachability Area</h1>

        </div>
        <div class="profile-main-content-section mt-3">
            <div class="service-list-box flex-wrap flex">
                <div class="location-map w-full h-100">
                <div id="map" class="my-2 mx-5" style="border-radius: 15px;"></div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import mapboxgl from 'mapbox-gl';
mapboxgl.accessToken = 'pk.eyJ1IjoibWVkaW1vYmNsaW5pY3MiLCJhIjoiY2xlYnpxZ3VsMWV4MTN5cXg4eW5ueTVndCJ9.rUWK6NsXGGlAxswGX1gGWg';

    export default {
        props: ['clinic'],
        data() {
            return {
                location: {
                    postcode: this.clinic.postcode,
                    city: this.clinic.city,
                    max_radius_range: this.clinic.max_miles_radius,
                },
                coords: [Number(this.clinic.lon), Number(this.clinic.lat)],
            }
        },
        mounted()
        {
            const map = new mapboxgl.Map({
                    container: "map",
                    style: "mapbox://styles/mapbox/streets-v12",
                    center: this.coords,
                    zoom: 8,
                });

            const clearances = {
                type: 'FeatureCollection',
                features: [
                  {
                    type: 'Feature',
                    geometry: {
                      type: 'Point',
                      coordinates: this.coords
                    },
                    properties: {
                      clearance: "13' 2"
                    }
                  }
                ]
            }

            const obstacle = turf.buffer(clearances, this.clinic.max_miles_radius, { units: 'miles' });
            const bbox = [0, 0, 0, 0];
            const polygon = turf.bboxPolygon(bbox);

            map.on('load', () => {
                map.resize();
                map.addLayer({
                    id: 'clearances',
                    type: 'fill',
                    source: {
                        type: 'geojson',
                        data: obstacle
                    },
                    layout: {},
                    paint: {
                        'fill-color': '#0BD2A4',
                        'fill-opacity': 0.5,
                        'fill-outline-color': '#0BD2A4'
                    }
                });
            });

    }

    }
</script>
