<template>
    <div>
        <table class="table">
            <thead>
            <tr>
                <th v-for="col in columns" :key="col">{{ col }}</th>
                <th v-if="hasRowActions"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="row in paginatedData" :key="row.id">
                <td v-for="(col, index) in columns" :key="col" v-html="row[index]"></td>
                <td v-if="hasRowActions" v-for="action in rowActions">
                    <button v-show="row.cancel_at_period_end === 'undefined' || !row.cancel_at_period_end" class="mr-2 btn-secondary capitalize p-2 rounded" @click.prevent="$emit('actionClicked', {action: action, id: row.id})">{{action}}</button>
                </td>
            </tr>
            </tbody>
        </table>

        <!-- Pagination Controls -->
        <div class="pagination-controls">
            <button class="btn btn-secondary" @click="prevPage" :disabled="currentPage === 1">Previous</button>
            <span>Page {{ currentPage }} of {{ totalPages }}</span>
            <button class="btn btn-secondary" @click="nextPage" :disabled="currentPage === totalPages">Next</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "PaginationTable",
    props: {
        data: {
            type: Array | Object,
            required: true
        },
        columns: {
            type: Array | Object,
            required: true
        },
        perPage: {
            type: Number,
            default: 10
        },
        hasRowActions: {
            type: Boolean,
            default: false
        },
        rowActions: {
            type: Array | Object,
        },
    },
    data() {
        return {
            currentPage: 1
        };
    },
    computed: {
        totalPages() {
            return Math.ceil(this.data.length / this.perPage);
        },
        paginatedData() {
            const start = (this.currentPage - 1) * this.perPage;
            const end = start + this.perPage;
            return this.data.slice(start, end);
        }
    },
    methods: {
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        }
    }
};
</script>

<style scoped>
.table {
    width: 100%;
    border-collapse: collapse;
}
.table th, .table td {
    padding: 10px;
    text-align: left;
}
.pagination-controls {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.pagination-controls button {
    padding: 5px 10px;
}
</style>
