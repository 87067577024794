<script>
import mastercard from "../../../../public/images/logos/mastercard.png";
import visa from "../../../../public/images/logos/visa.png";
import amex from "../../../../public/images/logos/amex.png";
import Avatar from "./Avatar.vue";

export default {
    name: "MasterCard",
    props: {
        card: {
            type: Object | Array,
            default: () => ({
                last4: "**** **** **** 1234",
                holderName: "J. Smith",
                expiryMonth: "11",
                expiryYear: "12",
                holderText: "Card Holder",
                expiryText: "Expires",
                background: "dark",
                type: 'mastercard'
            }),
        },
    },
    components: {Avatar},
    data()
    {
        return {

        }
    },
    computed: {
        cardTypeImage()
        {
            switch (this.card.type) {
                case 'visa':
                    return visa;
                case 'mastercard':
                    return mastercard;
                case 'amex':
                    return amex
                default:
                    return mastercard
            }
        }
    }
}
</script>

<template>
    <div class="card bg-transparent shadow-xl">
        <div
            class="overflow-hidden position-relative rounded-xl"
            :style="{
        backgroundImage:
          'url(' + require('../../../../public/images/curved14.jpg') + ')',
      }"
        >
            <span class="mask" :class="`bg-gradient-${card.background}`"></span>
            <div class="card-body position-relative z-index-1 p-3">
                <i class="fas fa-wifi text-white p-2" aria-hidden="true"></i>
                <h5 class="text-white mt-4 mb-5 pb-2">
                    *&nbsp;*&nbsp;*&nbsp;*&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*&nbsp;*&nbsp;*&nbsp;*&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*&nbsp;*&nbsp;*&nbsp;*&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ card.last4 }}
                </h5>
                <div class="d-flex">
                    <div class="d-flex">
                        <div :class="$store.state.isRTL ? 'ms-4' : 'me-4'">
                            <p class="text-white text-sm opacity-8 mb-0">
                                Card Holder
                            </p>
                            <h6 class="text-white mb-0">{{ card.holderName }}</h6>
                        </div>
                        <div>
                            <p class="text-white text-sm opacity-8 mb-0">
                                Expires
                            </p>
                            <h6 class="text-white mb-0">{{ card.expiryMonth }}/{{ card.expiryYear }}</h6>
                        </div>
                    </div>
                    <div
                        class="w-20 d-flex align-items-end justify-content-end ms-auto"
                    >
                        <Avatar class="w-60 mt-2" :img="cardTypeImage" alt="logo" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">

</style>
