<script>
import {mapActions, mapState} from "vuex";
import StripeCardCapture from "../../Utilities/StripeCardCapture.vue";
import StripeTransactions from "../../Utilities/StripeTransactions.vue";
import ActiveServices from "./ActiveServices.vue";
import MasterCard from "../../Utilities/MasterCard.vue";
import DefaultInfoCard from "../../Utilities/DefaultInfoCard.vue";
import PaymentMethodsCard from "../../Utilities/PaymentMethodsCard.vue";
import InvoiceCard from "../../Utilities/InvoiceCard.vue";
import RankingList from "../../Utilities/RankingList.vue";
import TransactionsCard from "../../Utilities/TransactionsCard.vue";
import ActiveServicesCard from "../../Utilities/ActiveServicesCard.vue";

export default {
    name: "AccountBilling",
    components: {
        ActiveServicesCard,
        RankingList,
        TransactionsCard,
        InvoiceCard,
        PaymentMethodsCard, DefaultInfoCard, MasterCard, ActiveServices, StripeTransactions, StripeCardCapture},
    props: ['stripe_key'],
    data()
    {
        return {
            showModal: false,
            modalParams: {
                title: '',
                isViewOnly: false,
                actionButtonFunction: '',
                actionBtnDisabled: false,
                data: {}
            },
            formData: {},
            validationError: false,
            processing: false

        }
    },
    methods: {
        ...mapActions('account', ['GET_USER_HAS_VALID_CARD', 'GET_USER_BILLING_DETAILS', 'GET_USER_CARD_DETAILS']),
        triggerModal(scenario)
        {
            switch (scenario) {
                case 'update_billing_address':
                    this.modalParams.title = 'Update Billing Address';
                    this.modalParams.isViewOnly = false;
                    this.modalParams.actionButtonText = 'Update Billing Address';
                    this.modalParams.actionButtonFunction = 'updateBillingAddress';
                    this.formData = this.user_billing_details;

            }
            this.showModal = true;
        },
        triggerModalFunction()
        {
            this[this.modalParams.actionButtonFunction]();
        },
        updateBillingAddress()
        {
            this.startProcessing();
            if (
                !this.formData.billing_name ||
                !this.formData.billing_address_line_1 ||
                !this.formData.billing_city ||
                !this.formData.billing_zip ||
                !this.formData.billing_country
            ) {
                this.validationError = true;
                this.stopProcessing();
            }

            if(this.validationError)
            {
                this.validationError = false
            }

            axios.put('/user/update-billing-details', this.formData)
                .then((res) => {
                    if(res.status)
                    {
                        this.modalParams.actionButtontext = 'Success';
                        window.location.reload();
                    }
                }).catch((e) => {
                    console.log(e)
            });

        },
        startProcessing()
        {
            this.btnDisabled = true;
            this.modalParams.actionButtontext = 'Updating ... Please Wait';
            this.processing = true;
        },
        stopProcessing()
        {
            this.btnDisabled = false;
            this.processing = true;
        },
    },
    computed: {
      ...mapState('account', ['user_has_card_on_system', 'user_billing_details', 'user_card_details'])
    },
    mounted()
    {
        this.GET_USER_HAS_VALID_CARD();
        this.GET_USER_BILLING_DETAILS();
        this.GET_USER_CARD_DETAILS();

    }
}
</script>

<template>
    <div class="container-fluid mt-4">
        <div class="row">
            <div class="col-lg-8">
                <div class="row mt-4">
                    <div class="col-xl-6 mb-xl-0 mb-4">
                        <MasterCard :card="user_card_details"/>
                    </div>
                    <div class="col-xl-6">
                        <div class="row">
                            <div class="col-md-12">
                                <default-info-card @buttonClicked="triggerModal('update_billing_address')">
                                    <div class="py-3">
                                        <p class="mb-0" v-if="user_billing_details.billing_name"><strong>Billing Name: </strong>{{user_billing_details.billing_name}}</p>
                                        <p class="mb-0" v-if="user_billing_details.billing_address_line_1"><strong>Address: </strong>{{user_billing_details.billing_address_line_1}}<span class="mb-0" v-if="user_billing_details.billing_address_line_2">, {{user_billing_details.billing_address_line_2}}</span><span class="mb-0" v-if="user_billing_details.billing_address_line_3">, {{user_billing_details.billing_address_line_3}}</span></p>
                                        <p class="mb-0" v-if="user_billing_details.billing_city"><strong>City: </strong>{{user_billing_details.billing_city}}</p>
                                        <p class="mb-0" v-if="user_billing_details.billing_county"><strong>County: </strong>{{user_billing_details.billing_county}}</p>
                                        <p class="mb-0" v-if="user_billing_details.billing_zip"><strong>Postcode: </strong>{{user_billing_details.billing_zip}}</p>
                                        <p class="mb-0" v-if="user_billing_details.billing_country"><strong>Country: </strong>{{user_billing_details.billing_country}}</p>
                                    </div>
                                </default-info-card>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mb-4">
                        <PaymentMethodsCard :stripe_key="stripe_key" :card="user_card_details"/>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <InvoiceCard/>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-7">
                <ActiveServices/>
            </div>
            <div class="col-md-5 mt-4">
                <transactions-card :horizontal-break="false"/>
            </div>
        </div>
        <create-update-modal
            v-if="showModal"
            @close="showModal = false;"
            :title="modalParams.title"
            :buttonText="modalParams.actionButtonText"
            @save="triggerModalFunction(formData)"
            :disabled="modalParams.actionBtnDisabled"
        >
            <div class="" slot="content">
                <div class="form-section mt-2 px-4" v-if="validationError">
                    <div class="form-section-content ml-5">
                        <div class="mb-3">
                            <p class="text-center text-red-900 rounded shadow mb-0 bg-red-300 p-3">Fill in all the required fields marked with *</p>
                        </div>
                    </div>
                </div>
                <div class="form-section px-4">
                    <div class="form-section-info flex-col">
                        <label class="block text-gray-500 font-bold mb-1 md:mb-0 my-auto" >Billing Name
                            <span class="text-red-400">*</span>
                        </label>
                    </div>
                    <div class="form-section-content">
                        <div class="mb-3">
                            <input
                                type="text"
                                class="w-full focus:outline-none focus:bg-white focus:border-yellow-500  form-control " v-model="formData.billing_name"
                                required
                                name="billing_name"
                            />
                        </div>
                    </div>
                </div>
                <div class="form-section mt-2 px-4">
                    <div class="form-section-info flex-col">
                        <label class="block text-gray-500 font-bold mb-1 md:mb-0 my-auto" >Address Line 1
                            <span class="text-red-400">*</span>
                        </label>
                    </div>
                    <div class="form-section-content">
                        <div class="mb-3">
                            <input
                                type="text"
                                class="w-full focus:outline-none focus:bg-white focus:border-yellow-500  form-control " v-model="formData.billing_address_line_1"
                                required
                                name="billing_address_line_1"
                            />
                        </div>
                    </div>
                </div>
                <div class="form-section mt-2 px-4">
                    <div class="form-section-info flex-col">
                        <label class="block text-gray-500 font-bold mb-1 md:mb-0 my-auto" >Address Line 2
                        </label>
                    </div>
                    <div class="form-section-content">
                        <div class="mb-3">
                            <input
                                type="text"
                                class="w-full focus:outline-none focus:bg-white focus:border-yellow-500  form-control " v-model="formData.billing_address_line_2"
                                name="billing_address_line_2"
                            />
                        </div>
                    </div>
                </div>
                <div class="form-section mt-2 px-4">
                    <div class="form-section-info flex-col">
                        <label class="block text-gray-500 font-bold mb-1 md:mb-0 my-auto" >Address Line 3
                        </label>
                    </div>
                    <div class="form-section-content">
                        <div class="mb-3">
                            <input
                                type="text"
                                class="w-full focus:outline-none focus:bg-white focus:border-yellow-500  form-control " v-model="formData.billing_address_line_3"
                                name="billing_address_line_3"
                            />
                        </div>
                    </div>
                </div>
                <div class="form-section mt-2 px-4">
                    <div class="form-section-info flex-col">
                        <label class="block text-gray-500 font-bold mb-1 md:mb-0 my-auto" >City
                            <span class="text-red-400">*</span>
                        </label>
                    </div>
                    <div class="form-section-content">
                        <div class="mb-3">
                            <input
                                type="text"
                                class="w-full focus:outline-none focus:bg-white focus:border-yellow-500  form-control " v-model="formData.billing_city"
                                required
                                name="billing_city"
                            />
                        </div>
                    </div>
                </div>
                <div class="form-section mt-2 px-4">
                    <div class="form-section-info flex-col">
                        <label class="block text-gray-500 font-bold mb-1 md:mb-0 my-auto">County
                        </label>
                    </div>
                    <div class="form-section-content">
                        <div class="mb-2">
                            <input
                                type="text"
                                class="w-full focus:outline-none focus:bg-white focus:border-yellow-500  form-control " v-model="formData.billing_county"
                                name="billing_county"
                            />
                        </div>
                    </div>
                </div>
                <div class="form-section mt-2 px-4">
                    <div class="form-section-info flex-col">
                        <label class="block text-gray-500 font-bold mb-1 md:mb-0 my-auto">Postcode
                            <span class="text-red-400">*</span>
                        </label>
                    </div>
                    <div class="form-section-content">
                        <div class="mb-1">
                            <input
                                type="text"
                                class="w-full focus:outline-none focus:bg-white focus:border-yellow-500  form-control " v-model="formData.billing_zip"
                                required
                                name="billing_zip"
                            />
                        </div>
                    </div>
                </div>
                <div class="form-section mt-2 px-4">
                    <div class="form-section-info flex-col">
                        <label class="block text-gray-500 font-bold mb-1 md:mb-0 my-auto">Country
                            <span class="text-red-400">*</span>
                        </label>
                    </div>
                    <div class="form-section-content">
                        <div class="mb-3">
                            <select class="w-full focus:outline-none focus:bg-white focus:border-yellow-500  form-control" v-model="formData.billing_country" name="billing_country" required>
                                <option value="GB">United Kingdom</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>

        </create-update-modal>
    </div>
</template>

<style scoped lang="scss">

</style>
