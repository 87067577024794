<script>
import ChatList from "./ChatList.vue";
import MessageBox from "./MessageBox.vue";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "Chatbox",
    components: {MessageBox, ChatList},
    methods: {
      ...mapActions('chat', ['retrieveActiveChat'])
    },
    computed: {
        ...mapGetters('chat', ['GET_ACTIVE_CONVERSATION'])
    },
    mounted()
    {
        // Get the URLSearchParams object to retrieve query params
        const params = new URLSearchParams(window.location.search);

        // Check if conversation_id exists in the URL
        const conversationId = params.get('conversation_id');

        // If conversation_id exists, trigger the Vuex action to retrieve the conversation
        if (conversationId) {
            this.retrieveActiveChat(conversationId);
        }
    }
}
</script>

<template>
    <div class="w-full" style="height: 100%;">
        <div class="flex w-full h-full">
            <div class="chat-list w-1/3 p-2 bg-white h-full overflow-y-scroll border">
                <div>
                    <chat-list/>
                </div>
            </div>
            <div class="chat-list w-2/3 h-full overflow-hidden">
                <div class="w-full" style="margin-bottom: 50px;height: 100%; width: 100%;">
                    <MessageBox />
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">

</style>
