<template>
    <div class="bg-white shadow rounded-lg h-100" style="max-height: 400px;">
        <div class="p-3 pb-0 card-header">
            <div class="flex">
                <div class="my-auto">
                    <h6 class="mb-0">Transactions</h6>
                </div>
            </div>
        </div>
        <div class="p-3 card-body overflow-auto" style="max-height: 300px;">
            <div v-if="visibleTransactions.length >= 1">
                <ranking-list v-for="transaction in visibleTransactions" :key="transaction.id"
                              :transaction="transaction"
                              :horizontal-break="horizontalBreak"
                />

                <!-- Show More Button -->
                <div v-if="visibleTransactions.length < transactions.length" class="text-center mt-3">
                    <MediButton size="sm" variant="gradient" color="dark" @buttonClicked="showMore()">Show More</MediButton>
                </div>
            </div>
            <p v-else>No Transactions Found</p>
        </div>
    </div>
</template>

<script>
import RankingList from "./RankingList.vue";
import MediButton from "./MediButton.vue";
import { mapActions, mapState } from "vuex";

export default {
    name: "TransactionsCard",
    components: {
        MediButton,
        RankingList,
    },
    data() {
        return {
            itemsToShow: 8,
        };
    },
    props: {
        item: {
            type: Array,
            default: () => [],
            title: String,
            date: String,
            amount: String,
            icon: String,
            color: String,
        },
        horizontalBreak: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        ...mapState('account', ['transactions']),
        visibleTransactions() {
            return this.transactions.slice(0, this.itemsToShow);
        },
    },
    methods: {
        ...mapActions('account', ['GET_TRANSACTIONS']),
        showMore() {
            this.itemsToShow += 10;
        }
    },
    mounted() {
        this.GET_TRANSACTIONS();
    },
};
</script>
