<template>

<div>

<div class="red-line" ></div>
<p class="error-text" >{{error_message ?? 'This field is required'}}</p>

</div>
</template>

<script>
export default {
    props: ['error_message']
}
</script>

<style scoped>

.red-line {
      height: 2px;
      background-color: red;
    }

    .error-text {
      color: red;
      font-size: 0.8rem;
      margin-top: 0.2rem;
    }

</style>
