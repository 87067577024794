<template>
    <div class="mt-[150px]">
        <div class="blog-section bg-white w-full h-full pb-5">
            <div class="container px-5 section-content">
                <div class="why-choose-paragraph how-it-works container title title-complex pt-2 pb-6">
                    <h1 class="font-bold howto-title text-center uppercase">Medimob</h1>
                    <h2 class="howto-sub-title font-bold text-center">Blog</h2>
                </div>
                <div class="grid-container mt-5">
                    <a class="blog-card text-decoration-none" v-for="post in posts" :key="post.id" :href="('/blog/post/' + post.slug)">
                        <div>
                            <div class="blog-card-img">
                                <img class="rounded-2xl" style="object-fit: cover; height: 100%; width: 100%" v-if="post.featured_image" :src="post.featured_image" :alt="post.featured_image_caption">
                                <img class="rounded-2xl w-full" v-else src="data:image/png;base64,...placeholder..." alt="No Image">
                            </div>
                            <div class="blog-card-title mt-2">
                                <h2 class="text-[22px] font-semibold text-slate-700">{{ post.title }}</h2>
                            </div>
                            <div class="blog-card-excerpt" v-if="post.summary">
                                <p class="text-[16px]">{{ truncateSummary(post.summary) }}</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['blogPosts'],
    data() {
        return {
            posts: []
        };
    },
    methods: {
        truncateSummary(summary) {
            return summary.length > 100 ? summary.substring(0, 100) + '...' : summary;
        }
    },
    mounted() {
        this.posts = this.blogPosts;
    }
};
</script>

<style scoped>
.blog-section {
    padding: 20px 0;
}

.section-content {
    text-align: center;
}

.grid-container {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
}

.blog-card {
    box-sizing: border-box;
    margin-bottom: 20px;
}

.blog-card-img {
    height: 300px;
}

@media (max-width: 1279px) {
    .grid-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 550px) {
    .grid-container {
        grid-template-columns: 1fr;
    }
}
</style>
