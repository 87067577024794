<style scoped lang="scss">
    .search-card {
        height: 250px;
        min-height: fit-content;
        width: 100%;

      @media (max-width: 768px)
      {
        height: fit-content!important;
      }
    }
    .clinic-name {
        font-size: 28px;
        font-weight: 400;
        color: #212429;
        line-height: 22px;
    }

    .verification-badge {
        color: #0BD2A4;
    }

    .distance-box {
        .distance {
            font-size: 16px;
            color: #838382;
            line-height: 12px;
            font-weight: 300;
        }
    }

    .service-box {
        bottom: 0;
        .service-list-item {
        font-size: 16px;
        line-height: 12px;
        color: #838382;
            .service-icon {
                color: #599DE0;
            }
        }
    }

    .search-action-btn {
        position:absolute!important;
        bottom: 0;

        .profile-btn {
            background-color: #3490DC;
            color: white;
            height: 100%;
            margin-top: 10px;
            padding: 9px;
            font-weight: 600;
            border-radius: 60px;
            font-size: medium;
        }
    }

    .review-icon {
        color: #0EE2B1;
        font-size: 17px;
    }

    .number-of-reviews {
        p {
            font-size: 12px;
            color: #838382;
        }
    }


</style>

<template>
    <div v-if="typeof searchItem === 'object'" class="search-card-wrapper bg-white rounded-2xl shadow-sm my-3 search-card" @click.prevent="redirectToProfile">
        <div class="card-inner p-4 h-full">
            <div class="md:flex w-full h-full">
                <div class="mx-auto mb-5 md:mb-0">
                    <RoundProfilePicture class="zindexImage"  :profileImage="searchItem.image"></RoundProfilePicture>
                </div>
                <div class="md:flex-grow my-auto position-relative h-full ml-3">
                    <div class="heading">
                        <div class="clinic-name-section flex">
                            <h1 class="clinic-name mr-2 capitalize text-slate-700" style="font-weight:700; line-height:35px;">{{ searchItem.name }}</h1>
                        </div>
                    </div>
                    <div class="distance-box mt-1">
                        <p v-if="searchItem.distance < 1" class="distance" style="line-height:20px;">{{ distance }} meters away (from your location)</p>
                        <p v-else-if="!isNaN(parseFloat(distance))" class="distance" style="line-height:20px;">{{ distance }} miles away (from your location)</p>
                    </div>

                    <div class="status-badge">
                      <ProfileVerificationStatusBadge :verified="searchItem.verified"></ProfileVerificationStatusBadge>
                    </div>

                    <div class="service-box flex w-full mt-2" v-if="visibleServices !== 'undefined' && visibleServices.length > 0">
                      <div class="mr-4">
                          <h6 class="text-muted my-1 font-bold">Services Offered</h6>
                        <ul class="p-0 flex flex-wrap">
                          <li v-for="(item, index) in visibleServices" :key="index"
                              class="flex service-list-item my-2 w-1/2 capitalize"
                              :class="{'w-full': visibleServices.length = 1}"
                              >
                            <span class="mr-2 my-auto" v-if="item">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                   class="bi bi-check2 service-icon" viewBox="0 0 16 16">
                                <path
                                  d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                              </svg>
                            </span>
                              <span class="flex-grow" v-if="item">
                                  {{ item.main_service_name }}
                              </span>
                          </li>
                            <li class="flex service-list-item my-2 w-1/2 capitalize"  v-if="searchItem.services !== 'undefined' && searchItem.services.length > maxVisibleServices">
                                <div class='cursor-pointer my-aut'>
                                    <a @click="toggleShowAllServices">{{ showAllServices ? 'Less...' : 'More...' }}</a>
                                </div>
                            </li>
                        </ul>
                      </div>
                </div>

                </div>
                <div class="lg:w-1/4 h-full relative">
                    <div class="reviews-sections">
                        <div class="rating flex">
                            <ul class="flex p-0 ml-auto my-0">
                                <li v-for="n in searchItem.reviews" :key="n" class="mx-1 star-list"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-star-fill review-icon" viewBox="0 0 16 16">
                                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                                    </svg>
                                </li>
                            </ul>
                        </div>
                        <div class="number-of-reviews flex mr-2">
                            <p class="ml-auto">{{searchItem.number_of_reviews}} Review<span v-if="searchItem.number_of_reviews == 0 || searchItem.number_of_reviews > 1">s</span></p>
                        </div>
                    </div>
                    <div class=" search-action-btn hidden md:flex md:flex-wrap lg:grid-cols-2 lg:gap-4 w-full">
                        <a role="button" :href="'/view-profile/'+searchItem.ref+'/'+searchItem.slug" class="no-underline cursor-pointer my-auto ml-auto profile-btn">View profile</a>
                    </div>
                </div>
            </div>

        </div>

       <MapBoxModal
          v-if="openMapBoxModal"
          @save="closeMapBoxModal()"
          @close="closeMapBoxModal()"
          >
          <div class="" slot="content">
            <search-mapbox-modal :coords="coords" :oldCords="oldCoordinates" :targetClinic="targetClinic"></search-mapbox-modal>
          </div>
       </MapBoxModal>
    </div>
</template>

<script>
import MapBoxModal from '../MapBoxModal.vue'
import SearchMapboxModal from '../SearchMapboxModal.vue'
import RoundProfilePicture from '../../Utilities/RoundProfilePicture';
import ProfileVerificationStatusBadge from "./ProfileVerificationStatusBadge.vue";

    export default {
        data() {
            return {
                openMapBoxModal: false,
                coords: {},
                targetClinic: {},
                oldCoordinates: {},
                maxVisibleServices: 5, // Maximum services to show initially
                collapsedMaxVisibleServices: 3, // Maximum services to show initially
                showAllServices: false, // Whether to show all services or not
            }
        },
        props: ['searchItem'],
        components: {
            RoundProfilePicture,
            MapBoxModal,
            SearchMapboxModal,
            ProfileVerificationStatusBadge
        },
        methods: {
            redirectToProfile()
            {
              window.open('/view-profile/'+this.searchItem.ref+'/'+this.searchItem.slug);
            },
            closeMapBoxModal() {
                this.openMapBoxModal = false
            },
            changeCoords(payload)
            {
                this.coords = payload;
            },
            oldCoords(payload)
            {
                this.oldCoordinates = payload;
            },
            passClinic(clinic)
            {
                this.targetClinic = clinic;
            },
        toggleShowAllServices() {
            this.showAllServices = !this.showAllServices;
        }

        },
        created(){
            this.changeCoords([this.searchItem.lat, this.searchItem.lon])
            this.oldCoords([this.searchItem.lat, this.searchItem.lon])
            this.passClinic(this.searchItem)
        },
        computed: {
            distance() {
                if(this.searchItem.distance < 1) {
                    return Math.round(this.searchItem.distance * 1609.344)
                }else {
                    return Math.round(this.searchItem.distance)
                }
            },
            visibleServices() {
                if(this.searchItem.services)
                {
                    return this.showAllServices
                        ? this.searchItem.services.slice(0, this.maxVisibleServices)
                        : this.searchItem.services.slice(0, this.collapsedMaxVisibleServices);
                } else {
                  return [];
                }

            },
        },
        mounted()
        {
        }
    }
</script>
<style>
.service-box {
  flex-wrap: wrap;
}
</style>
<style lang="scss" scoped>
    .zindexImage{
        z-index: 1;
        position: sticky;
    }

      @media (max-width: 991px)
      {
        .review-icon {

          width: 25px;
          height: 25px;
        }

        .number-of-reviews p {
          font-size: 20px;
        }
      }

</style>
