<template>
    <div class="rounded-lg shadow bg-white h-100 mt-4" style="max-height: 400px;">
        <div class="card-header pb-0 p-3">
            <div class="row">
                <div class="col-6 d-flex align-items-center">
                    <h6 class="mb-0">Invoices</h6>
                </div>
            </div>
        </div>
        <div class="card-body p-3 pb-0 mb-0 overflow-auto" style="max-height: 300px;">
            <ul class="list-group" v-if="visibleInvoices.length >= 1">
                <li
                    class="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                    v-for="invoice in visibleInvoices"
                    :key="invoice.id"
                >
                    <div class="d-flex flex-column">
                        <h6 class="mb-1 text-dark font-weight-bold text-sm">
                            {{invoice.date}}
                        </h6>
                        <span class="text-xs">#{{invoice.id}}</span>
                    </div>
                    <div class="d-flex align-items-center text-sm">
                        <span class="rounded-full px-3 py-1 capitalize text-sm" :style="'background-color:' + invoice.statusStrBgColor + '; color:' + invoice.statusStrTextColor">{{invoice.status}}</span>
                    </div>
                    <div class="d-flex align-items-center text-sm">
                        <span class="text-sm">£{{invoice.charge}}</span>
                    </div>
                    <div class="d-flex align-items-center text-sm">
                        <button class="btn btn-link text-dark text-sm mb-0 px-0 ms-4">
                            <a :href="invoice.pdf" target="_blank">{{invoice.actionText}}</a>
                        </button>
                    </div>
                </li>
            </ul>
            <p class="mb-0" v-else>No invoices to show</p>

            <!-- Show More Button -->
            <div v-if="visibleInvoices.length < invoices.length" class="text-center mt-3">
                <MediButton size="sm" color="dark" variant="gradient" @buttonClicked="showMoreInvoices"
                >Show More</MediButton
                >
            </div>
        </div>
    </div>
</template>

<script>
import MediButton from "./MediButton.vue";

export default {
    name: "InvoiceCard",
    components: {
        MediButton,
    },
    data() {
        return {
            invoices: [],
            itemsToShow: 5, // Initially show 5 invoices
        };
    },
    computed: {
        // Only show the number of invoices based on itemsToShow
        visibleInvoices() {
            return this.invoices.slice(0, this.itemsToShow);
        },
    },
    methods: {
        getInvoices() {
            axios
                .get("/user/invoices")
                .then((res) => {
                    this.invoices = res.data;
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        showMoreInvoices() {
            // Increase the number of invoices shown by 5
            this.itemsToShow += 5;
        }
    },
    mounted() {
        this.getInvoices();
    },
};
</script>

<style scoped>
.card-body {
    overflow-y: auto; /* Make the body scrollable */
    max-height: 300px; /* Set a fixed height for the scrollable area */
}
</style>
