<template>
    <div class="">
        <div class="section-title">
            <h5>Practitioner's profession</h5>
        </div>
        <form @submit.prevent="saveProfession()">
            <div class="bg-white rounded shadow w-full py-5 px-3">
                <div class="info-section">
                    <div v-if="!loadingPractitionerProfession" class="form-group my-2">
                      <label for="profession">Profession</label>
                      <select name="profession" v-model="clinician.profession" >
                        <option disabled>Select a profession</option>
                        <option :value="profession.singular" :selected="profession.singular.toLowerCase() === clinician.profession"   v-for="(profession, index) in professions" :key="index">{{profession.singular}}</option>
                      </select>
                    </div>
                </div>
                <div class="flex mt-4">
                    <PrimaryButton :type="'submit'">Update</PrimaryButton>
                </div>
            </div>
        </form>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import PrimaryButton from "../../PrimaryButton.vue";
export default {
    components: {PrimaryButton},
    props: ['profession', 'idRef'],
    data() {
        return {
            loadingPractitionerProfession: true,
            professions: [],
            clinician: {
                profession: this.profession,
                id: this.idRef,
            }
        }
    },
    methods: {
        ...mapActions('commonFunctions', ['redirectAction', 'capitalizeFirstLetter']),
        ...mapActions('practitioner', ['setUpPractitionerPage']),
        saveProfession()
        {
            axios.put('/dashboard/practitioners/update-profession/'+this.idRef, this.clinician).then(() => {
                    Swal.fire({
                        toast: true,
                        icon: 'success',
                        title: 'Profession Saved',
                        animation: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true
                    }).then(() => {
                        this.redirectAction('Profession')
                    });
                }).catch(() => {
                    Swal.fire({
                        toast: true,
                        icon: 'error',
                        title: 'Something went wrong. Contact support',
                        animation: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true
                    });
                });
        },
        getProfessions()
        {
            axios.get('/dashboard/practitioners/get-professions/')
                .then((res) => {
                   this.professions = res.data
                });
        }
    },
    created(){
        this.setUpPractitionerPage(this.idRef)
        .then(res => {
            this.clinician.id = res.data.ref
            this.clinician.profession = res.data.profession
            this.loadingPractitionerProfession = false
        }).catch(err => {
            this.loadingPractitionerProfession = false
        })
    },
    computed: {
        ...mapGetters('practitioner', ['GET_PRACTITIONER'])
    },
    mounted()
    {
        this.getProfessions();
    }
}
</script>
