<template>
  <div v-if="!loadworkflow"  class="py-2 px-5 flex-col">
      <div class="" v-if="GET_ACTIVE_TAB === 0">
        <div class="info-modal-box">
            <div class="img-box" v-if="GET_WORKFLOW['steps'][GET_ACTIVE_TAB+1]['illustration'] !== 'undefined' && GET_WORKFLOW['steps'][GET_ACTIVE_TAB+1]['illustration']">
                <img
                :src="GET_WORKFLOW['steps'][GET_ACTIVE_TAB+1]['illustration']" alt=""
                >
            </div>
            <p
            class="step-description text-center"
            v-text="GET_WORKFLOW['steps'][GET_ACTIVE_TAB+1]['description']"
            ></p>
        </div>
      </div>
      <div v-if="!loadworkflow" class="modal-form w-full">
        <div class="" v-if="GET_ACTIVE_TAB === 1">
          <div class="flex mb-6">
            <div class="w-1/3 mr-3">
              <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" :for="GET_WORKFLOW['steps'][2]['components']['clinicName']['inputName']">{{ GET_WORKFLOW['steps'][2]['components']['clinicName']['description'] }}<span class="text-red" v-if="GET_WORKFLOW['steps'][2]['components']['clinicName']['required']">*</span></label>
              <p class="text-gray-500 text-right text-sm" v-if="GET_WORKFLOW['steps'][2]['components']['clinicName']['help']" v-text="GET_WORKFLOW['steps'][2]['components']['clinicName']['help']"></p>

            </div>
            <div class="ml-3 w-2/3">
                <input
                :type="GET_WORKFLOW['steps'][2]['components']['clinicName']['inputType']"
                :name="GET_WORKFLOW['steps'][2]['components']['clinicName']['inputName']"
                :placeholder="GET_WORKFLOW['steps'][2]['components']['clinicName']['placeholder']"
                :disabled="formData.use_same_name === true"
                v-model="formData.name"
                >
                <InputErrorMessage v-if="GET_ERROR_NEXT && (!formData.name || formData.name === undefined)"/>
                <div class="flex mt-2" v-if="GET_WORKFLOW['steps'][2]['components']['asOldName']['inputType'] == 'checkbox'">
                    <input
                    class="mr-3"
                    :type="GET_WORKFLOW['steps'][2]['components']['asOldName']['inputType']"
                    :name="GET_WORKFLOW['steps'][2]['components']['asOldName']['inputName']"
                    :required="GET_WORKFLOW['steps'][2]['components']['asOldName']['required']"
                    @change="useSameDetails(formData.use_same_name, 'name')"
                    v-model="formData.use_same_name">

                    <label
                    :for="GET_WORKFLOW['steps'][2]['components']['asOldName']['inputType']"
                    v-text="GET_WORKFLOW['steps'][2]['components']['asOldName']['description']"
                    >
                    </label>
                </div>
            </div>
          </div>
        </div>
        <div class="" v-if="GET_ACTIVE_TAB === 2">
          <div class="info-modal-box">
            <p
            class="step-description text-center"
            v-text="GET_WORKFLOW['steps'][3]['description']"
            ></p>
          </div>
          <div class="">
            <div class="flex mb-6">
              <div class="w-1/3 mr-3">
                <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" :for="GET_WORKFLOW['steps'][3]['components']['addressLine1']['inputName']">{{ GET_WORKFLOW['steps'][3]['components']['addressLine1']['description'] }}<span class="text-red" v-if="GET_WORKFLOW['steps'][3]['components']['addressLine1']['required']">*</span></label>
                <p class="text-gray-500 text-right text-sm" v-if="GET_WORKFLOW['steps'][3]['components']['addressLine1']['help']" v-text="GET_WORKFLOW['steps'][3]['components']['addressLine1']['help']"></p>
              </div>
              <div class="ml-3 w-2/3">
                <input
                :type="GET_WORKFLOW['steps'][3]['components']['addressLine1']['inputType']"
                :name="GET_WORKFLOW['steps'][3]['components']['addressLine1']['inputName']"
                :placeholder="GET_WORKFLOW['steps'][3]['components']['addressLine1']['placeholder']"
                v-model="formData.address_line1"
                >
                <InputErrorMessage v-if="GET_ERROR_NEXT && (!formData.address_line1 || formData.address_line1 === undefined)"/>
              </div>
            </div>
            <div class="flex mb-6">
              <div class="w-1/3 mr-3">
                <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" :for="GET_WORKFLOW['steps'][3]['components']['addressLine2']['inputName']">{{ GET_WORKFLOW['steps'][3]['components']['addressLine2']['description'] }}<span class="text-red" v-if="GET_WORKFLOW['steps'][3]['components']['addressLine2']['required']">*</span></label>
                <p class="text-gray-500 text-right text-sm" v-if="GET_WORKFLOW['steps'][3]['components']['addressLine2']['help']" v-text="GET_WORKFLOW['steps'][3]['components']['addressLine2']['help']"></p>
              </div>
              <div class="ml-3 w-2/3">
                <input
                :type="GET_WORKFLOW['steps'][3]['components']['addressLine2']['inputType']"
                :name="GET_WORKFLOW['steps'][3]['components']['addressLine2']['inputName']"
                :placeholder="GET_WORKFLOW['steps'][3]['components']['addressLine2']['placeholder']"
                v-model="formData.address_line2"
                >
              </div>
            </div>
            <div class="flex mb-6">
              <div class="w-1/3 mr-3">
                <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" :for="GET_WORKFLOW['steps'][3]['components']['addressLine3']['inputName']">{{ GET_WORKFLOW['steps'][3]['components']['addressLine3']['description'] }}<span class="text-red" v-if="GET_WORKFLOW['steps'][3]['components']['addressLine3']['required']">*</span></label>
                <p class="text-gray-500 text-right text-sm" v-if="GET_WORKFLOW['steps'][3]['components']['addressLine3']['help']" v-text="GET_WORKFLOW['steps'][3]['components']['addressLine3']['help']"></p>
              </div>
              <div class="ml-3 w-2/3">
                <input
                :type="GET_WORKFLOW['steps'][3]['components']['addressLine3']['inputType']"
                :name="GET_WORKFLOW['steps'][3]['components']['addressLine3']['inputName']"
                :placeholder="GET_WORKFLOW['steps'][3]['components']['addressLine3']['placeholder']"
                v-model="formData.address_line3"
                >
              </div>
            </div>
            <div class="flex mb-6">
              <div class="w-1/3 mr-3">
                <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" :for="GET_WORKFLOW['steps'][3]['components']['postcode']['inputName']">{{ GET_WORKFLOW['steps'][3]['components']['postcode']['description'] }}<span class="text-red" v-if="GET_WORKFLOW['steps'][3]['components']['postcode']['required']">*</span></label>
                <p class="text-gray-500 text-right text-sm" v-if="GET_WORKFLOW['steps'][3]['components']['postcode']['help']" v-text="GET_WORKFLOW['steps'][3]['components']['postcode']['help']"></p>
              </div>
              <div class="ml-3 w-2/3">
                <input
                v-model="formData.postcode"
                @change="formatPostcode"

                :type="GET_WORKFLOW['steps'][3]['components']['postcode']['inputType']"
                :name="GET_WORKFLOW['steps'][3]['components']['postcode']['inputName']"
                :placeholder="GET_WORKFLOW['steps'][3]['components']['postcode']['placeholder']"
                @input="handleInput"
                >

                <div v-if="showResults" class="absolute z-10 bg-white border border-gray-300 rounded-lg mt-1 w-full">
                  <div
                    v-for="(result, index) in autocompleteOptions"
                    :key="index"
                    class="py-2 px-4 cursor-pointer hover:bg-blue-100"
                    @click="selectResult(result)"
                  >
                    {{ result }}
                  </div>
                </div>
                <InputErrorMessage v-if="GET_ERROR_NEXT && (!formData.postcode || formData.postcode === undefined)"/>
              </div>
            </div>
            <div class="flex mb-6">
              <div class="w-1/3 mr-3">
                <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" :for="GET_WORKFLOW['steps'][3]['components']['city']['inputName']">{{ GET_WORKFLOW['steps'][3]['components']['city']['description'] }}<span class="text-red" v-if="GET_WORKFLOW['steps'][3]['components']['city']['required']">*</span></label>
                <p class="text-gray-500 text-right text-sm" v-if="GET_WORKFLOW['steps'][3]['components']['city']['help']" v-text="GET_WORKFLOW['steps'][3]['components']['city']['help']"></p>
              </div>
              <div class="ml-3 w-2/3">
                <input
                :type="GET_WORKFLOW['steps'][3]['components']['city']['inputType']"
                :name="GET_WORKFLOW['steps'][3]['components']['city']['inputName']"
                :placeholder="GET_WORKFLOW['steps'][3]['components']['city']['placeholder']"
                v-model="chosenCity"
                :disabled="GET_WORKFLOW['steps'][3]['components']['city']['disabled']"
                @input="handleCityInput"
                >

                <div v-if="showCityResults" class="absolute z-10 bg-white border border-gray-300 rounded-lg mt-1 w-full">
                  <div
                    v-for="(result, index) in autocompleteCityOptions"
                    :key="index"
                    class="py-2 px-4 cursor-pointer hover:bg-blue-100"
                    @click="selectCityResult(result.display_name)"
                  >
                    {{ result.display_name }}
                  </div>
                </div>
                <InputErrorMessage v-if="GET_ERROR_NEXT && (!formData.city || formData.city === undefined)"/>
              </div>
            </div>
            <div class="flex mb-6">
              <div class="w-1/3 mr-3">
                <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" :for="GET_WORKFLOW['steps'][3]['components']['county']['inputName']">{{ GET_WORKFLOW['steps'][3]['components']['county']['description'] }}<span class="text-red" v-if="GET_WORKFLOW['steps'][3]['components']['county']['required']">*</span></label>
                <p class="text-gray-500 text-right text-sm" v-if="GET_WORKFLOW['steps'][3]['components']['county']['help']" v-text="GET_WORKFLOW['steps'][3]['components']['county']['help']"></p>
              </div>
              <div class="ml-3 w-2/3">
                <input
                :type="GET_WORKFLOW['steps'][3]['components']['county']['inputType']"
                :name="GET_WORKFLOW['steps'][3]['components']['county']['inputName']"
                :placeholder="GET_WORKFLOW['steps'][3]['components']['county']['placeholder']"
                v-model="formData.county"
                >
              </div>
            </div>
          </div>
        </div>
        <div class="" v-if="GET_ACTIVE_TAB === 3">
          <div class="info-modal-box">
            <p
            class="step-description text-center"
            v-text="GET_WORKFLOW['steps'][4]['description']"
            ></p>
          </div>
          <div class="mt-3">
            <div class="flex mb-6">
              <div class="w-1/3 mr-3">
                <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" :for="GET_WORKFLOW['steps'][4]['components']['email']['inputName']">{{ GET_WORKFLOW['steps'][4]['components']['email']['description'] }}<span class="text-red" v-if="GET_WORKFLOW['steps'][4]['components']['email']['required']">*</span></label>
                <p class="text-gray-500 text-right text-sm" v-if="GET_WORKFLOW['steps'][4]['components']['email']['help']" v-text="GET_WORKFLOW['steps'][4]['components']['email']['help']"></p>
              </div>
              <div class="ml-3 w-2/3">
                <input
                :type="GET_WORKFLOW['steps'][4]['components']['email']['inputType']"
                :name="GET_WORKFLOW['steps'][4]['components']['email']['inputName']"
                :placeholder="GET_WORKFLOW['steps'][4]['components']['email']['placeholder']"
                :disabled="formData.use_same_email === true"
                v-model="formData.email"
                >
                <InputErrorMessage v-if="GET_ERROR_NEXT && (!formData.email || formData.email === undefined)"/>
                <div class="flex mt-2" v-if="GET_WORKFLOW['steps'][4]['components']['oldEmail']['inputType'] == 'checkbox'">
                    <input
                    class="mr-3"
                    :type="GET_WORKFLOW['steps'][4]['components']['oldEmail']['inputType']"
                    :name="GET_WORKFLOW['steps'][4]['components']['oldEmail']['inputName']"
                    :required="GET_WORKFLOW['steps'][4]['components']['oldEmail']['required']"
                    @change="useSameDetails(formData.use_same_email, 'email')"
                    v-model="formData.use_same_email"
                    >

                    <label
                    :for="GET_WORKFLOW['steps'][4]['components']['oldEmail']['inputType']"
                    v-text="GET_WORKFLOW['steps'][4]['components']['oldEmail']['description']"
                    >
                    </label>
                </div>
              </div>
            </div>
            <div class="flex mb-6">
              <div class="w-1/3 mr-3">
                <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" :for="GET_WORKFLOW['steps'][4]['components']['phone']['inputName']">{{ GET_WORKFLOW['steps'][4]['components']['phone']['description'] }}<span class="text-red" v-if="GET_WORKFLOW['steps'][4]['components']['phone']['required']">*</span></label>
                <p class="text-gray-500 text-right text-sm" v-if="GET_WORKFLOW['steps'][4]['components']['phone']['help']" v-text="GET_WORKFLOW['steps'][4]['components']['phone']['help']"></p>
              </div>
              <div class="ml-3 w-2/3">
                <input
                :type="GET_WORKFLOW['steps'][4]['components']['phone']['inputType']"
                :name="GET_WORKFLOW['steps'][4]['components']['phone']['inputName']"
                :placeholder="GET_WORKFLOW['steps'][4]['components']['phone']['placeholder']"
                :disabled="formData.use_same_telephone"
                v-model="formData.telephone"
                >
                <div class="flex mt-2" v-if="GET_WORKFLOW['steps'][4]['components']['oldEmail']['inputType'] == 'checkbox'">
                    <input
                    class="mr-3"
                    :type="GET_WORKFLOW['steps'][4]['components']['oldPhone']['inputType']"
                    :name="GET_WORKFLOW['steps'][4]['components']['oldPhone']['inputName']"
                    :required="GET_WORKFLOW['steps'][4]['components']['oldPhone']['required']"
                    @change="useSameDetails(formData.use_same_telephone, 'telephone')"
                    v-model="formData.use_same_telephone"
                    >
                    <label
                    :for="GET_WORKFLOW['steps'][4]['components']['oldPhone']['inputType']"
                    v-text="GET_WORKFLOW['steps'][4]['components']['oldPhone']['description']"
                    >
                    </label>
                </div>
              </div>
            </div>
            <div class="flex mb-6">
              <div class="w-1/3 mr-3">
                <label class="block text-gray-500 font-bold text-right mb-1 md:mb-0" :for="GET_WORKFLOW['steps'][4]['components']['mobilePhone']['inputName']">{{ GET_WORKFLOW['steps'][4]['components']['mobilePhone']['description'] }}<span class="text-red" v-if="GET_WORKFLOW['steps'][4]['components']['mobilePhone']['required']">*</span></label>
                <p class="text-gray-500 text-right text-sm" v-if="GET_WORKFLOW['steps'][4]['components']['mobilePhone']['help']" v-text="GET_WORKFLOW['steps'][4]['components']['mobilePhone']['help']"></p>
              </div>
              <div class="ml-3 w-2/3">
                <input
                :type="GET_WORKFLOW['steps'][4]['components']['mobilePhone']['inputType']"
                :name="GET_WORKFLOW['steps'][4]['components']['mobilePhone']['inputName']"
                :placeholder="GET_WORKFLOW['steps'][4]['components']['mobilePhone']['placeholder']"
                :disabled="formData.use_same_mobile"
                v-model="formData.mobile_phone"
                >

                <div class="flex mt-2" v-if="GET_WORKFLOW['steps'][4]['components']['oldMobilePhone']['inputType'] == 'checkbox'">
                    <input
                    class="mr-3"
                    :type="GET_WORKFLOW['steps'][4]['components']['oldMobilePhone']['inputType']"
                    :name="GET_WORKFLOW['steps'][4]['components']['oldMobilePhone']['inputName']"
                    :required="GET_WORKFLOW['steps'][4]['components']['oldMobilePhone']['required']"
                    @change="useSameDetails(formData.use_same_mobile, 'mobile')"
                    v-model="formData.use_same_mobile"
                    >
                    <label
                    :for="GET_WORKFLOW['steps'][4]['components']['oldMobilePhone']['inputType']"
                    v-text="GET_WORKFLOW['steps'][4]['components']['oldMobilePhone']['description']"
                    >
                    </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="" v-if="GET_ACTIVE_TAB === 4">
          <div class="info-modal-box">
            <p
            class="step-description text-center"
            v-text="GET_WORKFLOW['steps'][5]['description']"
            ></p>
          </div>
          <div class="mt-3">
            <div class="flex mb-1">
              <div class="w-1/3 mr-3">
                <p class="font-bold text-right">Clinic Name</p>
              </div>
              <div class="ml-3 w-2/3">
                <p class="">{{ formData.name }}</p>
              </div>
            </div>
            <div class="flex mb-1">
              <div class="w-1/3 mr-3">
                <p class="font-bold text-right">Clinic City</p>
              </div>
              <div class="ml-3 w-2/3">
                <p class="">{{ formData.city }}</p>
              </div>
            </div>
            <div class="flex mb-1">
              <div class="w-1/3 mr-3">
                <p class="font-bold text-right">Clinic Postcode</p>
              </div>
              <div class="ml-3 w-2/3">
                <p class="">{{ formData.postcode }}</p>
              </div>
            </div>
            <div class="flex mb-1">
              <div class="w-1/3 mr-3">
                <p class="font-bold text-right">Clinic Email</p>
              </div>
              <div class="ml-3 w-2/3">
                <p class="">{{ formData.email }}</p>
              </div>
            </div>
            <hr>
              <!--
            <TotalMonthlyPrice :whichCase="'addClinic'" />
            -->
          </div>
        </div>
      </div>
  </div>
</template>

  <script>
  // importing the helpers for calling actions and getters from vuex
  import { mapActions, mapGetters } from 'vuex';
  import TotalMonthlyPrice from '../../Utilities/TotalMonthlyPrice.vue'
  import InputErrorMessage from '../../Utilities/InputErrorMessage.vue'
      export default {
          components: {
            TotalMonthlyPrice,
            InputErrorMessage
          },
          props: {
            formData: {
              type: Object,
            }
          },
          data() {
              return {
                  chosenCity: '',
                  // attributes necessary for the city autocomplete
                  showCityResults: false,
                  autocompleteCityOptions: [],
                  // attributes necessary for the poscode autocomplete
                  showResults: false,
                  autocompleteOptions: [],

                  addClinicModal: false,
                  current_step: 0,
                  errors: [],
                  loadworkflow: true
              }
          },
          methods: {
            // calling the actions from the modules
         //     ...mapActions('practitioner', ['getPractitioners']),
              ...mapActions('commonFunctions', ['formatPostcodeAction']),
              ...mapActions('mainClinic', ['saveClinic', 'getUserSateliteClinics']),

              // formats postcode
              formatPostcode()
              {

                // if there are no options from what the user typed then check if it's an acceptable postcode
                if(this.autocompleteOptions === null){
                  // calling the postcode action from vuex and assigning the value got from the Getter to the local variable
                  this.formatPostcodeAction(this.formData.postcode)
                  this.formData.postcode = this.GET_POSTCODE
                }
              },
              saveNewClinic()
              {
                // calling the saving new clinic action
                this.saveNewClinicAction(this.formData)
                .then(() => {
                  this.$emit('close')
                  Swal.fire({
                      position: 'top-end',
                      icon: 'success',
                      title: 'Clinic Successfully Added!',
                      showConfirmButton: false,
                      timer: 1500
                    }).then(() => {
                      window.location.reload();
                  })
                })
              },
              useSameDetails(parameter, element) {
                switch(element) {
                  case 'name':
                    if(parameter === true) {
                      this.formData.name = this.MAIN_CLINIC.name
                    }
                    ;break;
                  case 'email':
                    if(parameter === true) {
                      this.formData.email = this.MAIN_CLINIC.email
                    }
                    ;break;
                  case 'telephone':
                    if(parameter === true) {
                      this.formData.telephone = this.MAIN_CLINIC.telephone
                    }
                    ;break;
                  case 'mobile':
                    if(parameter === true) {
                      this.formData.mobile_phone = this.MAIN_CLINIC.mobile_phone
                    }
                    ;break;
                }
              },
              saveForm(evt)
              {
                this.$refs.formData.submit()
              },

              // method to handle the postcode input
              async handleInput() {
                if (this.formData.postcode.trim() === '') {
                  this.showResults = false;
                } else {
                  try {
                    const response = await axios.get(`https://api.postcodes.io/postcodes/${encodeURIComponent(this.formData.postcode)}/autocomplete`);
                    this.autocompleteOptions = response.data.result;
                    this.showResults = true;
                  } catch (error) {
                    console.error(error);
                  }
                }
              },
              selectResult(result) {
                this.formData.postcode = result;

                axios.get(`/postcode-get-info/${encodeURIComponent(this.formData.postcode)}`)
                .then(res => {
                  if (res.data.status === 200) {
                    const adminDistrict = res.data.result.admin_district;

                    if (adminDistrict.toLowerCase().includes('city of')) {
                      const splittedDistrict = adminDistrict.split(',')[0].trim();
                      this.$emit('setCity', splittedDistrict)
                      this.chosenCity = splittedDistrict
                    }else if (adminDistrict.toLowerCase().includes('city')) {
                      const splittedDistrict = adminDistrict.split('City')[0].trim();
                      this.$emit('setCity', splittedDistrict)
                      this.chosenCity = splittedDistrict

                    } else {
                      this.$emit('setCity', adminDistrict)
                      this.chosenCity = adminDistrict

                    }
                  } else if (res.data.status === 404) {
                    this.$emit('setCity', '')

                  }
                })
                this.showResults = false;
              },

              // method to handle the city input
              handleCityInput() {
                const city = this.formData.city.trim();
                if (city === '' || city.includes('/')) {
                  this.showCityResults = false;
                } else {
                  try {
                    const response = axios.get('/setup/get-locations/' + city);
                    this.autocompleteCityOptions = response.data;
                    this.showCityResults = true;
                  } catch (error) {
                    console.error(error);
                  }
                }
              },
              selectCityResult(result) {
                this.$emit('setCity', result)
                this.showCityResults = false;
              },

          },
          computed: {
            // getting the postcode stored in vuex
            ...mapGetters('commonFunctions', ['GET_POSTCODE']),
            ...mapGetters('mainClinic', ['GET_WORKFLOW', 'GET_WORKFLOW_STEPS', 'GET_ACTIVE_TAB', 'MAIN_CLINIC', 'GET_ERROR_NEXT']),
          },
          created() {
     //       this.getPractitioners();
            this.getUserSateliteClinics();
          },
          watch: {
            GET_WORKFLOW: {
                handler(newValue) {
                  this.loadworkflow = false
                }
            },
            chosenCity: {
              handler(newValue) {

                this.$emit('setCity', newValue)
                this.formData.city = newValue
              }
            }
          },
          mounted() {
            document.addEventListener('click', (event) => {
              if (!this.$el.contains(event.target)) {
                this.showResults = false;
              }
            });
            document.addEventListener('click', (event) => {
              if (!this.$el.contains(event.target)) {
                this.showCityResults = false;
              }
            });
          },

      }
  </script>

  <style scoped>

  </style>
