import axios from 'axios';
export default {
    namespaced: true,
    state: {
        conversations: [],
        active_conversation: []
    },
    getters: {
        GET_CONVERSATIONS(state)
        {
            return state.conversations
        },
        GET_ACTIVE_CONVERSATION(state)
        {
            return state.active_conversation
        }
    },
    actions: {
        retrieveConversations({commit})
        {
            axios.get('conversations/get-conversations')
                .then((res) => {
                    commit('SET_CONVERSATIONS', res.data)
                }).catch((e) => {
                    console.log(e)
            })
        },
        retrieveActiveChat({commit}, payload)
        {
            axios.get('conversations/view/' + payload)
                .then((res) => {
                    commit('SET_ACTIVE_CONVERSATION', res.data)
                    const url = new URL(window.location);
                    url.searchParams.set('conversation_id', payload);
                    window.history.pushState({}, '', url);
                }).catch((e) => {
                console.log(e)
            })
        },
        sendMessage({commit}, payload)
        {
            axios.put('/conversations/send-message', payload)
                .then((res) => {
                    commit('SET_ACTIVE_CONVERSATION', res.data)
                    this.retrieveConversations();
                }).catch((e) => {
                    console.log(e)
            });
        },
    },
    mutations: {
        SET_CONVERSATIONS(state, data)
        {
            state.conversations = data;
        },
        SET_ACTIVE_CONVERSATION(state, data)
        {
            state.active_conversation = data;
        },

    },
}
