/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import 'boxicons';

require('./bootstrap');

window.Vue = require('vue').default;

import {store} from './store/index';

 //SWEET ALERT
 import Swal from 'sweetalert2';
 window.Swal = Swal;

 //Register toaster for sweet alert.
 const toast = Swal.mixin({
   toast: true,
   position: 'top-end',
   showConfirmButton: false,
   timer: 5000,
   timerProgressBar: true,
   didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
 });
 window.toast = toast;

 //Register Modal message for sweet alert
 const swalWithBootstrapButtons = Swal.mixin({
   customClass: {
     confirmButton: 'btn btn-success',
     cancelButton: 'btn btn-danger'
   },
   buttonsStyling: false
 });
 window.swalWithBootstrapButtons = swalWithBootstrapButtons;

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

 // - END SWEET ALERT

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))
//Loader

import "vue-select/dist/vue-select.css";
import "../sass/app.scss"
import vSelect from "vue-select";
import Vue from 'vue';

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.component("v-select", vSelect);
Vue.component('vertical-card', require('./components/VerticalCard.vue').default);

// footer
Vue.component('site-footer', require('./components/HomePage/HomePageFooter.vue').default);


Vue.component('circular-image', require('./components/CircularImg.vue').default);
Vue.component('loader', require('./components/Loader.vue').default);
Vue.component('table-framework', require('./components/Table.vue').default);
//Modal
Vue.component('create-update-modal', require('./components/CreateUpdateModal.vue').default);
Vue.component('mini-statistics-card', require('./components/MiniStatisticsCard.vue').default);
Vue.component('mini-action-card', require('./components/MiniActionCard.vue').default);

// Logs
Vue.component('logs-card', require('./components/Dashboard/Logs/LogsCard.vue').default);


//home page
Vue.component('why-choose-medimob', require('./components/HomePage/WhyChooseMedimob.vue').default);
Vue.component('how-it-works', require('./components/HomePage/HowItWorks.vue').default);
Vue.component('home-page-components', require('./components/HomePage/HomePageComponents.vue').default);
Vue.component('need-healthcare', require('./components/HomePage/NeedHealthCare.vue').default);



//Tables
Vue.component('create-update-table', require('./components/CreateUpdateTable.vue').default);

// Dropdown
Vue.component('dropdown', require('./components/Dropdown.vue').default);
Vue.component('edit-delete-dropdown-options', require('./components/EditDeleteDropdownOptions.vue').default);

Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('stepper-home-page', require('./components/StepperHomePage.vue').default);
Vue.component('search-form', require('./components/SearchForm.vue').default);

//Search
Vue.component('single-search-card', require('./components/Search/SearchCards/SingleServiceSearchCard.vue').default);
Vue.component('search-result-page', require('./components/Search/SearchResultPage.vue').default);
Vue.component('search-form-topbar', require('./components/Search/SearchFormTopBar.vue').default);
Vue.component('search-form-topbar-modal', require('./components/Search/SearchFormTopBarModal.vue').default);
Vue.component('search-mapbox', require('./components/Search/SearchMapbox.vue').default);
Vue.component('search-form-white-wrap', require('./components/Search/SearchFormWhiteWrap.vue').default);

// progress stepper
Vue.component('progress-stepper', require('./components/Utilities/ProgressStepper.vue').default);
Vue.component('help-center', require('./components/Utilities/helpCenter.vue').default);

//Dashboard
Vue.component('dashboard', require('./components/Dashboard/Dashboard/Dashboard.vue').default);

//Sidebar
Vue.component('dashboard-topbar', require('./components/Dashboard/Navigation/DashboardTopbar.vue').default);
Vue.component('sidebar', require('./components/Dashboard/Navigation/Sidebar/Sidebar.vue').default);
Vue.component('sidebar-list', require('./components/Dashboard/Navigation/Sidebar/SidebarList.vue').default);

// Search Profile
Vue.component('single-clinic-profile', require('./components/Search/Profile/SingleClinicProfile').default);
Vue.component('single-clinic-profile-main-card', require('./components/Search/Profile/SingleClinicProfileMainCard').default);
Vue.component('email-enquiry-modal-content', require('./components/Search/Profile/EmailEnquiryModalContent').default);


//Dashboard Utilities
Vue.component('clinic-info-create-update-modal', require('./components/Dashboard/Clinics/ClinicInfoCreateUpdateModal.vue').default);
Vue.component('dashboard-info-topbar', require('./components/Dashboard/DashboardInfoTopbar.vue').default);
Vue.component('analytic-dashboard', require('./components/Dashboard/Dashboard/AnalyticDashboard.vue').default);

//Profile
Vue.component('profile-topbar', require('./components/Dashboard/Profile/ProfileTopbar.vue').default);
Vue.component('main-setup', require('./components/Dashboard/Setup/MainSetup.vue').default);

Vue.component('password-validator', require('./components/Utilities/PasswordValidator.vue').default);
Vue.component('register', require('./components/Auth/Register.vue').default);

Vue.component('last-saved-badge', require('./components/Utilities/Badges/LastSavedBadge.vue').default);
Vue.component('clinic-profile', require('./components/Dashboard/Profile/ClinicProfile.vue').default);
Vue.component('profile-company-logo-section', require('./components/Dashboard/Profile/ProfileCompanyLogoSection.vue').default);
Vue.component('profile-clinic-short-bio', require('./components/Dashboard/Profile/ProfileClinicShortBio.vue').default);
Vue.component('profile-clinic-full-bio', require('./components/Dashboard/Profile/ProfileClinicFullBio.vue').default);
Vue.component('profile-clinic-contacts', require('./components/Dashboard/Profile/ProfileClinicContacts.vue').default);
Vue.component('single-practitioner-fe', require('./components/Frontend/Practitioner/SinglePractitionerFE.vue').default);
//Clinics
Vue.component('clinics', require('./components/Dashboard/Clinics/Clinics.vue').default);
Vue.component('all-clinics', require('./components/Dashboard/Clinics/AllClinics.vue').default);
Vue.component('add-satelite-clinic-modal-form', require('./components/Dashboard/Clinics/AddSateliteClinicModalForm.vue').default);
Vue.component('edit-satelite-clinic-modal-form', require('./components/Dashboard/Clinics/EditSateliteClinicModalForm.vue').default);
Vue.component('update-create-clinic-legal-entity', require('./components/Dashboard/Clinics/UpdateCreateClinicLegalEntity.vue').default);
Vue.component('update-clinic-legal-entity', require('./components/Dashboard/Clinics/UpdateClinicLegalEntity.vue').default);
Vue.component('update-clinic-main-address', require('./components/Dashboard/Clinics/UpdateClinicMainAddress.vue').default);
Vue.component('update-clinic-payment-method', require('./components/Dashboard/Clinics/UpdateClinicPaymentMethod.vue').default);
Vue.component('single-clinic', require('./components/Dashboard/Clinics/SingleClinic.vue').default);
Vue.component('edit-profile-single-clinic', require('./components/Dashboard/Clinics/EditProfileSingleClinic.vue').default);
Vue.component('edit-location-single-clinic', require('./components/Dashboard/Clinics/EditLocationSingleClinic.vue').default);
Vue.component('edit-profile-single-clinic-modal-content', require('./components/Dashboard/Clinics/EditProfileSingleClinicModalContent.vue').default);
Vue.component('history-logs', require('./components/Dashboard/Clinics/HistoryLogs.vue').default);

// Practitioners
Vue.component('single-practitioner-topbar', require('./components/Dashboard/Practitioners/SinglePractitionerTopbar.vue').default);
Vue.component('practitioners', require('./components/Dashboard/Practitioners/Practitioners.vue').default);
Vue.component('all-practitioners', require('./components/Dashboard/Practitioners/AllPractitioners.vue').default);
Vue.component('single-practitioner', require('./components/Dashboard/Practitioners/SinglePractitioner.vue').default);
Vue.component('single-practitioner-personal-information', require('./components/Dashboard/Practitioners/SinglePractitionerPersonalInformation.vue').default);
Vue.component('single-practitioner-profession', require('./components/Dashboard/Practitioners/SinglePractitionerProfession.vue').default);
Vue.component('single-practitioner-association', require('./components/Dashboard/Practitioners/SinglePractitionerAssociation.vue').default);
Vue.component('single-practitioner-qualifications', require('./components/Dashboard/Practitioners/SinglePractitionerQualifications.vue').default);
Vue.component('single-practitioner-publications', require('./components/Dashboard/Practitioners/SinglePractitionerPublications.vue').default);
Vue.component('edit-personal-information-single-practitioner', require('./components/Dashboard/Practitioners/EditPersonalInformationSinglePractitioner.vue').default);
Vue.component('edit-profession-single-practitioner', require('./components/Dashboard/Practitioners/EditProfessionSinglePractitioner.vue').default);
Vue.component('edit-regulatory-body-single-practitioner', require('./components/Dashboard/Practitioners/EditRegulatoryBodySinglePractitioner.vue').default);
Vue.component('edit-association-single-practitioner', require('./components/Dashboard/Practitioners/EditAssociationSinglePractitioner.vue').default);
Vue.component('edit-qualifications-single-practitioner', require('./components/Dashboard/Practitioners/EditQualificationsSinglePractitioner.vue').default);
Vue.component('edit-publications-single-practitioner', require('./components/Dashboard/Practitioners/EditPublicationsSinglePractitioner.vue').default);
Vue.component('cteate-update-practitioner-publication', require('./components/Dashboard/Practitioners/CreateUpdatePractitionerPublication.vue').default);
Vue.component('practitioner-bio', require('./components/Dashboard/Practitioners/PractitionerBio.vue').default);
//Services
Vue.component('services-page', require('./components/Dashboard/Services/ServicesPage.vue').default);
Vue.component('reviews-page', require('./components/Dashboard/Reviews/ReviewsPage.vue').default);
Vue.component('compliance-page', require('./components/Dashboard/Compliance/CompliancePage.vue').default);
Vue.component('all-services', require('./components/Dashboard/Services/AllServices.vue').default);
Vue.component('all-reviews', require('./components/Dashboard/Reviews/AllReviews.vue').default);
Vue.component('add-service-modal-content', require('./components/Dashboard/Services/AddServiceModalContent.vue').default);
Vue.component('add-child-service-modal-content', require('./components/Dashboard/Services/AddChildServiceModalContent.vue').default);
Vue.component('single-service-page', require('./components/Dashboard/Services/SingleServicePage.vue').default);
Vue.component('single-service-category-basic-info', require('./components/Dashboard/Services/SingleServiceCategoryBasicInfo.vue').default);
Vue.component('give-review', require('./components/Dashboard/Reviews/GiveReview.vue').default);
Vue.component('give-peer-endorsement', require('./components/Dashboard/Reviews/GivePeerEndorsement.vue').default);
Vue.component('resend-review-request', require('./components/Dashboard/Reviews/ResendReviewRequest.vue').default);

// account
Vue.component('account-page', require('./components/Dashboard/Account/AccountPage.vue').default);

// profile compliance
Vue.component('profile-compliance-page', require('./components/Dashboard/Compliance/ProfileCompliancePage.vue').default);
Vue.component('dashboard-help-centre', require('./components/Dashboard/DashboardHelpCentre.vue').default);

// admin
Vue.component('admin-dashboard', require('./components/Admin/AdminDashboard.vue').default);
Vue.component('enquiry-match-multiselections', require('./components/Admin/EnquiryMatchMultiselections.vue').default);

// blog
Vue.component('blog-posts', require('./components/Blog/BlogPosts.vue').default);

// Get Matched
Vue.component('get-matched-page', require('./components/HomePage/GetMatchedPage.vue').default);

// patients
Vue.component('patient-register', require('./components/Patient/Auth/Register.vue').default);

Vue.component('chatbox', require('./components/Chat/Chatbox.vue').default);
Vue.component('testchat', require('./components/Chat/TestChat.vue').default);
Vue.component('payment-processing-button', require('./components/Utilities/PaymentProcessingButton.vue').default);


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

import Popover from 'vue-js-popover'

Vue.use(Popover)
const app = new Vue({
    store,
    el: '#app',
});
