import axios from 'axios';
export default {
    namespaced: true,
    state: {
    },
    getters: {
    },
    actions:{
        register({commit}, payload) {
          return new Promise((resolve, reject) => {
            axios.post('/register', {
              clinic_name: payload.clinic_name,
              first_name: payload.first_name,
              last_name: payload.last_name,
              email: payload.email,
              password: payload.password,
              agreed_with_terms: payload.agreed_with_terms
            })
            .then(res => {
              window.location.href = '/setup/filling-informations';
              resolve(res)

            }).catch((e) => {
           console.log(e)
                                       Swal.fire({
                            toast: true,
                            icon: 'error',
                            title: 'Something went wrong. Contact support',
                            animation: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 1500,
                            timerProgressBar: true
                            });
              reject(e)
            })
          })
        },
    },
    mutations: {
    },

}
