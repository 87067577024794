var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"flex"},[_c('button',{staticClass:"ml-auto p-2 rounded text-white font-bold bg-[#0BD2A4] mb-3",on:{"click":function($event){$event.preventDefault();return _vm.goToPractitioner.apply(null, arguments)}}},[_vm._v("Add New Practitioner")])]),_vm._v(" "),_c('table-framework',{attrs:{"theads":_vm.theads,"tableTitle":'Your Practitioners',"showTableSearch":true,"showTableToggle":true,"tableSearchText":_vm.searchInput,"showTableActionButton":false,"allSelected":_vm.allSelected,"toggle":_vm.toggle,"filteredOptions":_vm.filteredPractitioners,"selectedIds":_vm.selectedIds,"paginationEnabled":true,"previousPaginationButtonDisabled":_vm.previousPaginationButtonDisabled,"nextPaginationButtonDisabled":_vm.nextPaginationButtonDisabled,"paginationCurrentPage":_vm.currentPage,"paginationPerPage":_vm.perPage,"paginationTotalResults":_vm.totalPractitioners},on:{"textSearch":_vm.textSearch,"onToggleChange":_vm.onToggleChange,"changePage":_vm.changePage}},[(_vm.filteredPractitioners.length > 0)?_c('tbody',{attrs:{"slot":"table-body"},slot:"table-body"},_vm._l((_vm.filteredPractitioners),function(practitioner,index){return _c('tr',{key:practitioner.id,class:{
                'selectedRow': _vm.selectedIds.includes(practitioner.ref),
                'bg-white': !_vm.selectedIds.includes(practitioner.ref),
                'border-b': true,
                'dark:border-gray-700': index !== _vm.filteredPractitioners.length - 1,
                'hover:bg-gray-30': true,
                'dark:hover:bg-gray-600': true,
                }},[_c('th',{staticClass:"ml-2 py-4 px-6 whitespace-nowrap"},[_c('InputToggle',{attrs:{"selectedIds":_vm.selectedIds,"element":practitioner.ref},on:{"toggleSelected":_vm.toggleSelected}})],1),_vm._v(" "),_c('th',{staticClass:"py-2 px-6 whitespace-nowrap",attrs:{"scope":"row"}},[_c('img',{staticClass:"roundImage ml-2 w-50 bg-light",attrs:{"src":practitioner.image_path}})]),_vm._v(" "),_c('th',{staticClass:"py-4 px-6 whitespace-nowrap",attrs:{"scope":"row"}},[_vm._v("\n                    "+_vm._s(practitioner.first_name)+" "+_vm._s(practitioner.middle_name)+" "+_vm._s(practitioner.last_name)+"\n                ")]),_vm._v(" "),_c('th',{staticClass:"py-4 px-6 whitespace-nowrap",attrs:{"scope":"row"}},[_vm._v("\n                    "+_vm._s(practitioner.ref)+"\n                ")]),_vm._v(" "),_c('th',{staticClass:"py-4 px-6 whitespace-nowrap",attrs:{"scope":"row"}},[_vm._v("\n                    "+_vm._s(practitioner.known_as)+"\n                ")])])}),0):_vm._e(),_vm._v(" "),(_vm.filteredPractitioners.length === 0)?_c('div',{staticClass:"mt-5"},[_c('p',[_vm._v("You don't have any practitioner yet.")])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }