<script>
import InputErrorMessage from "../Utilities/InputErrorMessage.vue";

export default {
    name: "GetMatchedPage",
    props: {
        user: {
            type: Object | Array,
            required: false
        },
        cfkey: {
            type: String,
            required: false
        }
    },
    components: {InputErrorMessage},
    data()
    {
        return {
            formData: {},
            maxCharacters: 2000,
            textLength: 0,
            hasReachedMax: false,
            successfullySubmitted: false,
            buttonText: 'Submit',
            buttonDisabled: false
        }
    },
    methods: {
        submitForm()
        {
            this.buttonText = 'Submitting... Please Wait'
            this.buttonDisabled = true;
            axios.post('/submit-get-matched-form', this.formData)
                .then((res) => {
                  this.buttonText = 'Successfully Sent'
                  this.successfullySubmitted = true;
                }).catch((e) => {
               console.log(e)
            });
        },
        countDetails()
        {
            if(this.textLength > this.maxCharacters)
            {
                this.hasReachedMax = true;
            } else {
                this.hasReachedMax = false;
            }
            this.textLength = this.formData.details.length
        }
    },
    mounted() {
        if(!this.user)
        {
            this.formData.firstname = '';
            this.formData.surname = '';
            this.formData.email = '';
        }
    },
    beforeMount() {
        if(this.user)
        {
            this.formData.firstname = this.user.firstname
            this.formData.surname = this.user.surname
            this.formData.email = this.user.email
        }
    }
}
</script>

<template>
    <div class="container flex">
        <div class="my-4 w-full lg:w-1/2 mx-auto shadow p-4 rounded-xl" v-if="!successfullySubmitted">
            <div class="mb-3">
                <h4 class="font-bold text-slate-700">Fill the form below</h4>
                <hr class="my-3">
            </div>

            <form class="mt-3" @submit.prevent="submitForm">
                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-1" for="first_name">
                        First Name<span class="text-red-600">*</span>
                    </label>
                    <BInput class="shadow-sm appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required v-model="formData.firstname" placeholder="i.e. Sara" :disabled="user !== null"></BInput>
                </div>
                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-1" for="last_name">
                        Last Name<span class="text-red-600">*</span>
                    </label>
                    <BInput class="shadow-sm appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required v-model="formData.surname" placeholder="i.e. Smith" :disabled="user !== null"></BInput>
                </div>
                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-1" for="email">
                        Email<span class="text-red-600">*</span>
                    </label>
                    <BInput class="shadow-sm appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"  placeholder="i.e. sara.smith@email.com" type="email" required v-model="formData.email" :disabled="user !== null"></BInput>
                </div>
                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-1" for="telephone">
                        Telephone
                    </label>
                    <BInput class="shadow-sm appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="i.e. 07111123456" v-model="formData.telephone"></BInput>
                </div>
                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-1" for="telephone">
                        Who are you looking for?<span class="text-red-600">*</span>
                    </label>
                    <BInput class="shadow-sm appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" placeholder="i.e. Doctor, Phlebotomist, Nurse" v-model="formData.looking_for" required></BInput>
                </div>
                <div class="mb-4">
                    <label class="block text-gray-700 text-sm font-bold mb-1" for="telephone">
                        Details<span class="text-red-600">*</span> (max: {{maxCharacters}} characters)
                    </label>
                    <BTextarea rows="8" class="shadow-sm appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" @keyup="countDetails" required v-model="formData.details" placeholder="i.e. 'Looking for a phlebotomist in London to come and take some blood tests in my house.'"></BTextarea>
                    <input-error-message v-if="textLength > maxCharacters" :error_message="'This field has be max '+ maxCharacters + ' characters'"></input-error-message>
                </div>
                <div class="mb-4">
                    <b-checkbox v-model="formData.terms_agreed" required class="cursor-pointer"><span class="ml-3">I agree with the <a href="/terms-and-conditions" target="_blank">Terms & Conditions</a> and <a href="/privacy-policy" target="_blank">Privacy Policy</a>.<span class="text-red-600">*</span></span></b-checkbox>
                </div>
                <div class="cf-turnstile my-4 w-full"
                     :data-sitekey="cfkey"
                     data-callback="onTurnstileSuccess"
                ></div>
                <p class="bg-red-200 text-red-700 text-center p-2 rounded" v-if="!formData.firstname || !formData.surname || !formData.email || !formData.details || !formData.terms_agreed || hasReachedMax">Please complete all the required fields (*)</p>
                <div class="form-group mb-3">
                    <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit" :disabled="!formData.firstname || !formData.surname || !formData.email || !formData.details || !formData.terms_agreed || hasReachedMax || buttonDisabled">
                        {{buttonText}}
                    </button>
                </div>

            </form>
        </div>
        <div class="my-4 w-1/2 mx-auto" v-else>
            <div class="border-b p-3 my-3">
                <p>Thank you for your enquiry. You will shortly receive a confirmation email.</p>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">

</style>
