<template>
    <div class="md:mx-5">
        <div class="my-auto w-full bg-white rounded !md:rounded-full p-2">
          <search-form
              :last_search_method="last_search_method"
              :last_search_location="last_search_location"
              :last_search_profession="last_search_profession"
              :last_search_professional="last_search_professional"
              :last_search_clinic="last_search_clinic"
              :last_search_condition="last_search_condition"
              :last_search_service="last_search_service"
          ></search-form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';

export default {

    props: ['servicesession', 'last_search_method', 'last_search_location', 'last_search_clinic', 'last_search_condition', 'last_search_profession', 'last_search_professional', 'last_search_service'],
}
</script>

<style lang="scss" scoped>.cityresult {
    position: relative;
    z-index: 99999 !important;
}</style>
