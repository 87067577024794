<script>
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import InputErrorMessage from "./InputErrorMessage.vue";
import {mapActions, mapState} from "vuex";
import CreditCard from "./CreditCard.vue";
import MediButton from "./MediButton.vue";


export default {
    name: "StripeCardCapture",
    components: {MediButton, CreditCard, InputErrorMessage, StripeElementCard},
    props: ['stripe_key', 'buttonText'],
    data()
    {
        return {
            publishableKey: null,
            formData: {},
            btnDisabled: false,
            processing: false,
            validationError: false,
            modifyBilling: false,
            cardOptions: {
                hidePostalCode: true
            },
            showCardForm: true,
            showErrorMessage: false,
            errorMessage: ''
        }
    },
    computed: {
      ...mapState('account', ['user_billing_details', 'user_card_details'])
    },
    methods: {
        ...mapActions('account', ['GET_USER_CARD_DETAILS']),
      submit()
      {
          this.startProcessing();
          // Initiate the tokenization of the card
          this.$refs.stripeCard.submit();
      },
        tokenCreated (token) {
           this.formData.tokenizedCard = token;

           // Submit the form
            axios.post('/dashboard/account/save-payment-info', this.formData).then((res) => {
                this.showErrorMessage = false;
                window.location.reload();
            }).catch((e) => {
                this.stopProcessing()
                this.errorMessage = e.response.data.message;
                this.showErrorMessage = true;
                console.error(e)
            });
        },
        startProcessing()
        {
            this.btnDisabled = true;
            this.buttonText = 'Processing... please wait'
            this.processing = true;
        },
        stopProcessing()
        {
            this.btnDisabled = false;
            this.buttonText = 'Save Payment Method'
            this.processing = false;
        },
        toggleModify()
        {
            if(!this.modifyBilling)
            {
                this.modifyBilling = true;
            } else {
                this.modifyBilling = false;
            }
        },
        toggleCardForm()
        {
            if(!this.showCardForm)
            {
                this.showCardForm = true
            } else {
                this.showCardForm = false
            }
        }
    },
    mounted()
    {
        this.GET_USER_CARD_DETAILS();
        if(this.stripe_key)
        {
            this.publishableKey = this.stripe_key
        }
    },
    created() {
        // Set showCardForm based on the value of has_card_on_system
        this.showCardForm = !this.has_card_on_system;
    },
    watch: {
        // Watch for changes in the prop to dynamically update showCardForm
        has_card_on_system(newVal) {
            this.showCardForm = !newVal;
        }
    }
}
</script>

<template>
    <div class="w-full">
        <div class="flex w-full">
            <stripe-element-card
                ref="stripeCard"
                v-if="this.publishableKey"
                :pk="publishableKey"
                @token="tokenCreated"
                :hide-postal-code="true"
                class="flex-grow border rounded mr-2"
            />
            <div>
                <MediButton color="dark" variant="gradient" @buttonClicked="submit" :is-disabled="btnDisabled">
                    <span>{{buttonText}}</span>
                </MediButton>
            </div>
        </div>
        <div class="p-2 rounded bg-red-100 flex mt-3" v-if="showErrorMessage">
            <p class="text-red-800 m-auto">{{errorMessage}}</p>
        </div>
    </div>


</template>

<style scoped lang="scss">

</style>
