<template>

    <div class="profile-wrapper w-full pb-4">
        <div class="" style="margin-top: -125px; position: fixed; width: 90%; z-index: 1001;">
            <profile-topbar :mainClinic="head_office"></profile-topbar>
        </div>

        <div class="main-profile-content p-4 max-height">
            <div class="overview-cards mt-5">
            <div class="row" style="margin-top: -40px;">
                <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                    <mini-action-card
                        class="cursor-pointer"
                        title="Update Clinic Logo"
                        :modalId="'updateClinicLogo'"
                        @openUpdateModal="openUpdateModal"
                        :icon="{
                          component: 'image-o',
                          background: 'bg-gradient-green',
                        }"
                        direction-reverse
                    ></mini-action-card>
                </div>
                <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                    <mini-action-card
                        class="cursor-pointer"
                        title="Update Legal Entity"
                        :modalId="'updateLegalEntity'"
                        @openUpdateModal="openUpdateModal"
                        :icon="{
                          component: 'weight_scale',
                          background: 'bg-gradient-green',
                        }"
                        direction-reverse
                    ></mini-action-card>
                </div>
                <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                    <mini-action-card
                        class="cursor-pointer"
                        title="Update Main Address"
                        :modalId="'updateMainAddress'"
                        @openUpdateModal="openUpdateModal"
                        :icon="{
                          component: 'location',
                          background: 'bg-gradient-green',
                        }"
                        direction-reverse
                    ></mini-action-card>
                </div>
                <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                    <mini-action-card
                        @click="paymentMethodModal = true"
                        class="cursor-pointer"
                        title="Update Payment Methods"
                        :modalId="'updatePaymentMethodModal'"
                        @openUpdateModal="openUpdateModal"
                        :icon="{
                          component: 'wallet',
                          background: 'bg-gradient-green',
                        }"
                        direction-reverse
                    ></mini-action-card>
                </div>
            </div>
        </div>
        <UpdateCreateClinicModal @closeModal="closeModal" v-if="updateCreateClinicModal" :chosenModal="modalId"/>
        <UpdateProfileImagesModal @closeModal="closeModal" v-if="UpdateProfileImagesModal" :pictureConcerned="MAIN_CLINIC.image_path" :chosenModal="modalId"/>
            <div class="flex my-4">
                <div class="w-1/2 mr-3">
                    <profile-clinic-full-bio class="my-4 h-full" :head_office="head_office"></profile-clinic-full-bio>
                </div>
                <div class="w-1/2 ml-3">
                    <profile-clinic-contacts class="my-4" :head_office="head_office"></profile-clinic-contacts>
                </div>
            </div>
            <div class="flex my-4" v-if="logs.length > 0">
                <div class="w-2/8 mr-3">
                    <LogsCard :logs="logs"></LogsCard>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import LastSavedBadge from '../../Utilities/Badges/LastSavedBadge.vue';
import Breadcrumbs from '../../Breadcrumbs.vue';
import MiniActionCard from '../../MiniActionCard.vue';
import UpdateCreateClinicModal from '../Clinics/UpdateCreateClinicModal.vue';
import UpdateProfileImagesModal from '../Clinics/UpdateProfileImagesModal.vue';
import LogsCard from '../Logs/LogsCard.vue';
// page loader component import
// import pageloader from '../../Utilities/PageLoader.vue'

export default {
    props: ['head_office', 'logs'],
    components: {
    "last-saved-badge": LastSavedBadge,
    Breadcrumbs,
    MiniActionCard,
    UpdateCreateClinicModal,
    UpdateProfileImagesModal
    // page loader component
    // pageloader
    ,
    LogsCard
},
    data() {
        return {
            errors: '',
            crumbs: [
                {name: 'Profile', url: '/dashboard/profile'},
            ],
            paymentMethodModal: false,
            updateCreateClinicModal: false,
            UpdateProfileImagesModal: false,
            modalId: ''
        }
    },
    methods: {
        ...mapActions('profile', ['getHeadOffice']),
        ...mapActions('commonFunctions', ['loadBreadcrumbs']),
        openUpdateModal(modalId){
            if(modalId === 'updateClinicLogo'){
                this.UpdateProfileImagesModal = true
                this.modalId = modalId
            } else {
                this.updateCreateClinicModal = true
                this.modalId = modalId
            }
        },
        closeModal(){
            this.updateCreateClinicModal = false
        }
    },
    computed: {
        ...mapGetters('mainClinic', ['MAIN_CLINIC']),
        ...mapGetters('profile', {
            headOffice: 'headOffice',
        }),
        ...mapState('profile', ['lastUpdated', 'formErrors', 'errorModal']),
        setErrors()
        {
            this.errors = this.formErrors;

        }

    },
    mounted() {
        this.getHeadOffice();
        this.loadBreadcrumbs(this.crumbs);
    },

}
</script>
