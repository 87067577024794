<template>
    <section class="w-full section-separation">
        <div class="text-center left-pom my-auto flex flex-col items-center">
            <div class="flex-col">
            <div class="why-choose-paragraph container title title-complex pt-2 pb-6">
                <h3 class="howto-sub-title">Either Ways <span class="accent-text" >Do It With...</span></h3>
            </div>
            </div>
            <div class="body mt-6">

                <div class="bg-white container  rounded-lg md:p-4">
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div class="bg-white rounded-lg overflow-hidden">
                            <img :src="'img/Rectangle_38.png'" alt="Image 1" class="md:p-4 w-full rounded-t-lg">
                            <div class="text-left md:p-4">
                                <p class="howto-title mod-tit mt-3 mb-2">Convenient</p>
                                <h2 class="howto-sub-title mod-sub-tit">Home visit</h2>
                                <p class="mt-4 mod-text">Healthcare professionals from Medimob extend their medical expertise to
                                    patients&#39; homes, providing an extensive array of medical services. This approach offers
                                    patients, especially the elderly, those with disabilities, and individuals facing health
                                    challenges, a convenient and flexible avenue for receiving personalized medical care. Our
                                    services cater to the unique needs of each patient, ensuring comfort and accessibility in the
                                    comfort of their own homes.</p>
                            </div>
                        </div>

                        <div class="bg-white rounded-lg overflow-hidden">
                            <img :src="'img/Rectangle_45.png'" alt="Image 2" class="md:p-4 w-full rounded-t-lg">
                            <div class="text-left md:p-4">
                                <p class="howto-title mod-tit mt-3 mb-2">Online & Secure</p>
                                <h2 class="howto-sub-title mod-sub-tit">Video Consultation</h2>
                                <p class="mod-text mt-4">Medimob&#39;s healthcare service enables patients to engage in virtual
                                    consultations with medical professionals online. Offering a convenient and flexible option,
                                    this service is tailored for patients facing challenges such as distance, time constraints, or
                                    health concerns, providing accessible healthcare solutions from the comfort of their own
                                    space. Our platform ensures seamless access to medical expertise, emphasizing the ease
                                    and flexibility of online healthcare consultations for those who may find it difficult to visit
                                    practitioners in person.</p>
                            </div>
                        </div>
                    </div>

                </div>
                <a href="/about-medimob" class="flex blue-home-button mx-auto text-decoration-none items-center my-5 w-1/5 bg-blue-500 text-white rounded-lg px-4 py-3 text-[20px]">
                    <span>Learn More</span>
                </a>
            </div>
        </div>
    </section>
</template>

<script>
export default {

    methods: {
        getImageUrl(imagePath) {
        return "{{ asset('') }}" + imagePath;
        }
    }
}
</script>
