<script>
export default {
    name: "CreditCard",
    props: ['last_four', 'card_type']
}
</script>

<template>
     <div>
         <p><strong>Payment Type: </strong>{{card_type}}</p>
         <p><strong>Card Number: </strong>**** **** **** {{last_four}}</p>
     </div>
</template>

<style scoped lang="scss">

</style>
