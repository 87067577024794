<script>
export default {
    name: "SingleChatPreview",
    props: ['conversation']
}
</script>

<template>
    <div class="w-full p-2 border-b cursor-pointer single-line-item rounded">
        <div class="flex w-full">
<!--            <div class="1/4 flex">-->
<!--                <div class="m-auto">-->
<!--                    <span>Hey</span>-->
<!--                </div>-->
<!--            </div>-->
            <div class="flex-grow">
                <div class="flex">
                    <div class="">
                        <p class="mb-0 font-semibold text-[16px] capitalize" v-html="conversation.client"></p>
                    </div>
                    <div class="ml-auto">
                        <p class="text-muted mb-0">{{conversation.last_message_date}}</p>
                    </div>
                </div>
                <div>
                    <p class="text-muted mb-0">{{conversation.last_message_text}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .single-line-item {
        &:hover {
            background-color: #e6ebeb;
        }
    }
</style>
