<script>
import {mapActions, mapGetters} from "vuex";
import TestChat from "./TestChat.vue";

export default {
    name: "MessageBox",
    components: {TestChat},
    data()
    {
      return {
          formData: {
              id: '',
              message_type: 'text',
              message: ''
          },
          messages: [],
          counter: 0
      }
    },
    methods: {
        ...mapActions('chat', ['sendMessage', 'retrieveActiveChat']),
        submitMessage()
        {
            this.formData.id = this.GET_ACTIVE_CONVERSATION.conversations.id
            this.sendMessage(this.formData);
            this.formData.message = '';
        },
        scrollToBottom() {
            this.$nextTick(() => {
                const container = this.$refs.messageContainer;
                if (container) {
                    container.scrollTop = container.scrollHeight;
                }
            });
        },
        updateChat()
        {
            const params = new URLSearchParams(window.location.search);

            const conversationId = params.get('conversation_id');
            if (conversationId) {
                this.retrieveActiveChat(conversationId);
            }
        }

    },
    watch: {
        'GET_ACTIVE_CONVERSATION.conversations.messages'(newMessages) {
            this.scrollToBottom();
        },
    },
    computed: {
      ...mapGetters('chat', ['GET_ACTIVE_CONVERSATION'])
    },
    mounted()
    {
        this.scrollToBottom()

        this.interval = setInterval(this.updateChat, 60000);


    },
    beforeDestroy() {
        clearInterval(this.interval);
    }
}
</script>

<template>

    <div v-if="GET_ACTIVE_CONVERSATION.conversations" class="h-full">
        <!-- chatbox -->
        <div class="chatbox">
            <div class="modal-dialog-scrollable" style="height:100%">
                <div class="modal-content" style="height:100%;">
                    <div class="msg-head">
                        <div class="row">
                            <div class="col-8">
                                <div class="d-flex align-items-center">
                                    <span class="chat-icon"><img class="img-fluid" src="/images/logos/MedimobIso.png" alt="image title" style="width: 50px; height: 50px;"></span>
                                    <div class="flex-shrink-0">
                                        <img class="img-fluid" src="/images/logos/MedimobIso.png" alt="user img" style="width: 50px; height: 50px;">
                                    </div>
                                    <div class="flex-grow-1 ms-3">
                                        <div v-html="GET_ACTIVE_CONVERSATION.counterpartName"></div>
                                        <div v-html="GET_ACTIVE_CONVERSATION.counterpartSubName"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <ul class="moreoption flex h-full">
                                    <span class="px-2 py-1 rounded-full shadow my-auto" :style="'background-color:' + GET_ACTIVE_CONVERSATION.conversationSourceBgColor + '; color: '+ GET_ACTIVE_CONVERSATION.conversationSourceTextColor ">{{ GET_ACTIVE_CONVERSATION.conversationSourceTitle}}</span>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="modal-body"  ref="messageContainer">
                        <div class="msg-body">
                            <ul>
                                <li v-for="message in GET_ACTIVE_CONVERSATION.conversations.messages" :class="{ 'reply': GET_ACTIVE_CONVERSATION.loggedInUserId === message.sender_id, 'sender': GET_ACTIVE_CONVERSATION.loggedInUserId !== message.sender_id}">
                                    <div v-if="message.message">
                                        <p> {{ message.message }}</p>
                                        <span class="time">{{message.created_timestamp}}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="send-box">
                        <form @submit.prevent="submitMessage">
                            <textarea :disabled="GET_ACTIVE_CONVERSATION.conversations.closed === 1" type="text" @keyup.enter="submitMessage" v-model="formData.message" class="form-control" aria-label="message…" placeholder="Write message…"></textarea>
                            <button :disabled="GET_ACTIVE_CONVERSATION.conversations.closed === 1" type="submit" style="background-color: #247ba0!important;"><i class="fa fa-paper-plane" aria-hidden="true"></i> Send</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-else style="height:100%;">
        <div class="flex w-full" style="height: 100%;">
            <div class="m-auto flex-col">
                <img class="mx-auto mb-4" alt="Medimob chat feature, messages vector" src="../../../../public/images/illustrations/chat_vector.png">
                <p>Click on a conversation to see the messages</p>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.chatbox {
    width: auto;
    overflow: hidden;
    height: 100%;
    border-left: 1px solid #ccc;
}

.chatbox .modal-dialog,
.chatlist .modal-dialog {
    max-width: 100%;
    margin: 0;
}

.msg-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.chat-area .form-control {
    display: block;
    width: 80%;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #222;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.chat-area .form-control:focus {
    outline: 0;
    box-shadow: inherit;
}

a.add img {
    height: 36px;
}

.chat-area .nav-tabs {
    border-bottom: 1px solid #dee2e6;
    align-items: center;
    justify-content: space-between;
    flex-wrap: inherit;
}

.chat-area .nav-tabs .nav-item {
    width: 100%;
}

.chat-area .nav-tabs .nav-link {
    width: 100%;
    color: #180660;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    text-transform: capitalize;
    margin-top: 5px;
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.chat-area .nav-tabs .nav-item.show .nav-link,
.chat-area .nav-tabs .nav-link.active {
    color: #222;
    background-color: #fff;
    border-color: transparent transparent #000;
}

.chat-area .nav-tabs .nav-link:focus,
.chat-area .nav-tabs .nav-link:hover {
    border-color: transparent transparent #000;
    isolation: isolate;
}

.msg-head h3 {
    color: #222;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 0;
}

.msg-head p {
    color: #343434;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    text-transform: capitalize;
    margin-bottom: 0;
}

.msg-head {
    padding: 15px;
    border-bottom: 1px solid #ccc;
}

.moreoption {
    display: flex;
    align-items: center;
    justify-content: end;
}

.moreoption .navbar {
    padding: 0;
}

.moreoption li .nav-link {
    color: #222;
    font-size: 16px;
}

.moreoption .dropdown-toggle::after {
    display: none;
}

.moreoption .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: auto;
    right: 0;
    margin-top: 0.125rem;
}

.msg-body ul {
    overflow: hidden;
}

.msg-body ul li {
    list-style: none;
    margin: 15px 0;
}

.msg-body ul li.sender {
    display: block;
    width: 100%;
    position: relative;
}

.msg-body ul li.sender:before {
    display: block;
    clear: both;
    content: '';
    position: absolute;
    top: -6px;
    left: -7px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 15px 12px;
    border-color: transparent transparent #f5f5f5 transparent;
    -webkit-transform: rotate(-37deg);
    -ms-transform: rotate(-37deg);
    transform: rotate(-37deg);
}

.msg-body ul li.sender p {
    color: #000;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
    padding: 15px;
    background: #f5f5f5;
    display: inline-block;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-bottom: 0;
}

.msg-body ul li.sender p b {
    display: block;
    color: #180660;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 500;
}

.msg-body ul li.reply {
    display: block;
    width: 100%;
    text-align: right;
    position: relative;
}

.msg-body ul li.reply:before {
    display: block;
    clear: both;
    content: '';
    position: absolute;
    bottom: 15px;
    right: -7px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 12px 15px 12px;
    border-color: transparent transparent #0BD2A4 transparent;
    -webkit-transform: rotate(37deg);
    -ms-transform: rotate(37deg);
    transform: rotate(37deg);
}

.msg-body ul li.reply p {
    color: #fff;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
    padding: 15px;
    background: #0BD2A4;
    display: inline-block;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    margin-bottom: 0;
}

.msg-body ul li.reply p b {
    display: block;
    color: #061061;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 500;
}

.msg-body ul li.reply:after {
    display: block;
    content: '';
    clear: both;
}

.time {
    display: block;
    color: #000;
    font-size: 12px;
    line-height: 1.5;
    font-weight: 400;
}

li.reply .time {
    margin-right: 20px;
}

.divider {
    position: relative;
    z-index: 1;
    text-align: center;
}

.msg-body h6 {
    text-align: center;
    font-weight: normal;
    font-size: 14px;
    line-height: 1.5;
    color: #222;
    background: #fff;
    display: inline-block;
    padding: 0 5px;
    margin-bottom: 0;
}

.divider:after {
    display: block;
    content: '';
    clear: both;
    position: absolute;
    top: 12px;
    left: 0;
    border-top: 1px solid #EBEBEB;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.send-box {
    padding: 15px;
    border-top: 1px solid #ccc;
}

.send-box form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.send-box .form-control {
    display: block;
    width: 85%;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #222;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.send-box button {
    border: none;
    background: #3867d6;
    padding: 0.375rem 5px;
    color: #fff;
    border-radius: 0.25rem;
    font-size: 14px;
    font-weight: 400;
    width: 24%;
    margin-left: 1%;
}

.send-box button i {
    margin-right: 5px;
}

.send-btns .button-wrapper {
    position: relative;
    width: 125px;
    height: auto;
    text-align: left;
    margin: 0 auto;
    display: block;
    background: #F6F7FA;
    border-radius: 3px;
    padding: 5px 15px;
    float: left;
    margin-right: 5px;
    margin-bottom: 5px;
    overflow: hidden;
}

.send-btns .button-wrapper span.label {
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
    color: #343945;
    font-weight: 400;
    text-transform: capitalize;
    font-size: 13px;
}

#upload {
    display: inline-block;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
}

.send-btns .attach .form-control {
    display: inline-block;
    width: 120px;
    height: auto;
    padding: 5px 8px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.5;
    color: #343945;
    background-color: #F6F7FA;
    background-clip: padding-box;
    border: 1px solid #F6F7FA;
    border-radius: 3px;
    margin-bottom: 5px;
}

.send-btns .button-wrapper span.label img {
    margin-right: 5px;
}

.button-wrapper {
    position: relative;
    width: 100px;
    height: 100px;
    text-align: center;
    margin: 0 auto;
}

button:focus {
    outline: 0;
}

.add-apoint {
    display: inline-block;
    margin-left: 5px;
}

.add-apoint a {
    text-decoration: none;
    background: #F6F7FA;
    border-radius: 8px;
    padding: 8px 8px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.2;
    color: #343945;
}

.add-apoint a svg {
    margin-right: 5px;
}

.chat-icon {
    display: none;
}

.closess i {
    display: none;
}



@media (max-width: 767px) {
    .chat-icon {
        display: block;
        margin-right: 5px;
    }
    .chatlist {
        width: 100%;
    }
    .chatbox {
        width: 100%;
        position: absolute;
        left: 1000px;
        right: 0;
        background: #fff;
        transition: all 0.5s ease;
        border-left: none;
    }
    .showbox {
        left: 0 !important;
        transition: all 0.5s ease;
    }
    .msg-head h3 {
        font-size: 14px;
    }
    .msg-head p {
        font-size: 12px;
    }
    .msg-head .flex-shrink-0 img {
        height: 30px;
    }
    .send-box button {
        width: 28%;
    }
    .send-box .form-control {
        width: 70%;
    }
    .chat-list h3 {
        font-size: 14px;
    }
    .chat-list p {
        font-size: 12px;
    }
    .msg-body ul li.sender p {
        font-size: 13px;
        padding: 8px;
        border-bottom-left-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }
    .msg-body ul li.reply p {
        font-size: 13px;
        padding: 8px;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        border-bottom-left-radius: 6px;
    }
}
</style>
