<template>
    <div class="container">

      <div class="mt-5">
        <ul class="flex">
          <li v-for="tab in compliance_page_tabs" :key="tab" class="mr-4 px-4 py-2 cursor-pointer rounded-lg" :class="{'active-tab': active_tab == tab}" @click="active_tab = tab">{{tab}}</li>
        </ul>
      </div>
      <div class="" v-if="active_tab === 'Clinics'">
        <div class="mt-5">
          <div class="p-3 bg-white shadow rounded-lg">
            <table class="w-full table-auto">
              <thead class="border-b">
                <th class="p-2 font-bold">Clinic Name</th>
                <th class="p-2 font-bold">Ref</th>
                <th class="p-2 font-bold">Critical actions</th>
                <th class="p-2 font-bold">Improvements</th>
                <th class="p-2 font-bold"></th>
              </thead>
              <tbody>
                <tr v-for="clinic in compliancearr.clinics">
                  <td class="p-2 border-b">{{clinic.info.name}}</td>
                  <td class="p-2 border-b">{{clinic.info.ref}}</td>
                  <td class="p-2 border-b" v-if="!clinic.isCompliant">Critical actions needed</td>
                  <td class="p-2 border-b" v-else>No actions required</td>
                  <td class="p-2 border-b" v-if="clinic.isImprovable">Improvements needed</td>
                  <td class="p-2 border-b" v-else>None</td>
                  <td class="p-2 border-b"><button class="btn btn-blue-grad text-white" @click.prevent="showModal(clinic)">View</button></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <create-update-modal v-if="modal" @close="modal = false; activeClinic = {}" :showActionButton="false" :title="activeClinic.info.name + ' Compliance'">
          <div class="" slot="content">
            <div class="">
              <div class="border-b" v-for="(action, index) in activeClinic.complianceActions">
                <h6 class="font-bold bg-teal-500 border text-white rounded text-[18px] p-2">{{ action.name }}</h6>
                <div class="description p-3">
                  <p><strong>Impact:</strong><span> {{action.impact}}</span></p>
                  <p>{{action.implications}}</p>
                  <div class="">
                    <strong>How to fix it:</strong>
                    <div class="mt-3">
                      <a class="btn btn-blue-grad text-white font-bold py-2 px-3 rounded" v-for="suggested in action.suggested_actions" :href="suggested.action_url" target="blank">{{suggested.action_name}}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </create-update-modal>
      </div>
      <div class="" v-if="active_tab === 'Practitioners'">
<!--        <div class="mt-5" v-if="compliancearr.practitioners.data.length == 0">-->
<!--          <p>You don't have any practitioner yet. </p>-->
<!--        </div>-->
<!--        <div v-else class="main-page my-10">-->
<!--          <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">-->
<!--            <div v-for="practitioner in compliancearr.practitioners.data"  :key="practitioner.ref"  class="flex items-center justify-center">-->
<!--              <OnePractitionerCompliance :practitioner="practitioner" />-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
          <table-framework
              :theads="theads"
              :tableTitle="'Practitioners\'s Compliance'"
              :showTableSearch="true"
              :tableSearchText="searchInput"
              @textSearch="textSearch"
              :showTableActionButton="false"
              :filteredOptions="filteredPractitioners"
              :paginationEnabled="true"
              :previousPaginationButtonDisabled="previousPaginationButtonDisabled"
              :nextPaginationButtonDisabled="nextPaginationButtonDisabled"
              :paginationCurrentPage="currentPage"
              :paginationPerPage="perPage"
              :paginationTotalResults="totalPractitioners"
              @changePage="changePage"
          >

              <tbody slot="table-body"  v-if="filteredPractitioners.length > 0">
              <tr
                  v-for="(practitioner, index) in filteredPractitioners"
                  :key="practitioner.id"
                  :class="{
                    'border-b': true,
                    'dark:border-gray-700': index !== filteredPractitioners.length - 1,
                    'hover:bg-gray-30': true,
                    'dark:hover:bg-gray-600': true,
                    }"
              >

                  <td scope="row" class="py-2 px-6  whitespace-nowrap" >
                      <img class="roundImage ml-2 w-50 bg-light" :src="practitioner.image_path" />
                  </td>
                  <td scope="row" class="py-4 px-6  whitespace-nowrap capitalize" >
                      {{practitioner.title}} {{practitioner.first_name}} {{practitioner.middle_name}} {{practitioner.last_name}} {{practitioner.known_as ? '('+ practitioner.known_as + ')' : ''}}
                  </td>
                  <td scope="row" class="py-4 px-6  whitespace-nowrap capitalize" >
                      {{practitioner.profession}}
                  </td>
                  <td class="py-4 px-6">
                    <span
                        class="badge"
                        :class="getComplianceBadgeColor(practitioner)"
                    >
                        {{ getComplianceBadgeText(practitioner) }}
                    </span>
                  </td>
                  <td class="py-4 px-6 my-auto">
                      <button class="btn btn-blue-grad text-white" role="button" @click.prevent="openPractitionerComplianceModal(practitioner);">View</button>
                  </td>
              </tr>
              </tbody>
              <div class="mt-5" v-if="filteredPractitioners.length === 0">
                  <p>You don't have any practitioner yet.</p>
              </div>
          </table-framework>
      </div>
        <create-update-modal v-if="modalOpen" @close="modalOpen = false; targetedPractitioner = []" :show-action-button="false" :title="'Practitioner Compliance'">
            <div class="" slot="content">
                <complianceDetailsModal :checkingCompliance="checkingCompliance" :practitioner="targetedPractitioner" />
            </div>
        </create-update-modal>

    </div>
</template>

<script>
import OnePractitionerCompliance from './OnePractitionerCompliance'
import complianceDetailsModal from "./complianceDetailsModal.vue";
import {mapActions} from "vuex";
import UpdateCreateClinicModal from "../Clinics/UpdateCreateClinicModal.vue";
import DeleteClinicOrPractitionerModal from "../Clinics/DeleteClinicOrPractitionerModal.vue";
export default {
    props: ['compliancearr'],
    components: {
        DeleteClinicOrPractitionerModal,
        UpdateCreateClinicModal,
        complianceDetailsModal, OnePractitionerCompliance},
    data()
    {
      return {
          theads: ['picture', 'name', 'profession', 'compliant', ''],
        compliance_page_tabs: [
            'Clinics',
            'Practitioners'
        ],
        active_tab: "Clinics",
        modal: false,
        activeClinic: {},
        crumbs: [
          {name: 'Compliance', url: '/dashboard/compliance'},
        ],
          currentPage: 1,
          totalPractitioners: 0,
          perPage: 10,
          filteredPractitioners: [],
          searchInput: '',
          nextPaginationButtonDisabled: false,
          previousPaginationButtonDisabled: true,
          practitioners: [],
          modalOpen: false,
          targetedPractitioner: [],
          checkingCompliance: 'COMPLIANT',
      }
    },
  methods: {
    ...mapActions('commonFunctions', ['loadBreadcrumbs']),
      openPractitionerComplianceModal(practitioner)
      {
          if (practitioner.compliances.length === 0) {
              this.checkingCompliance = 'NON-COMPLIANT';
          } else {
              practitioner.compliances.forEach((element) => {
                  if (element.status == 1) {
                      this.checkingCompliance = 'PENDING';
                      return;
                  }
                  if (element.status != 5) {
                      this.checkingCompliance = 'NON-COMPLIANT';
                      return;
                  }
                  if (element.status == 5) {
                      this.checkingCompliance = 'COMPLIANT';
                      return;
                  }
              });
          }
          this.targetedPractitioner = practitioner;
          this.modalOpen = true;
      },
      getComplianceBadgeColor(practitioner) {
          const allCompliant = practitioner.compliances.every(
              (compliance) => compliance.status == 5
          );
          return allCompliant ? 'badge-green' : 'badge-orange';
      },
      getComplianceBadgeText(practitioner) {
          const allCompliant = practitioner.compliances.every(
              (compliance) => compliance.status == 5
          );
          return allCompliant ? 'Compliant' : 'Non-Compliant';
      },
    showModal(clinic)
      {
        this.modal = true;
        this.activeClinic = clinic;
      },
      closeModal()
      {
        this.modal = false;
        this.activeClinic = {};
      },
      async loadPractitioners(page = 1)
      {
           await axios.get(`/dashboard/practitioners/get-practitioners-for-compliance?page=${page}&perPage=${this.perPage}&search=${this.searchInput}`)
               .then(async res => {
                   this.filteredPractitioners = await res.data.data;
                   this.practitioners = await res.data.data;
                   this.totalPractitioners = res.data.total;
                   this.currentPage = page;
               }).catch(error => {
                   console.log(error);
               });

      },
      textSearch(Searchtext)
      {
          this.searchInput = Searchtext;
          this.loadPractitioners();
      },
      changePage(page) {
          if(page === -1)
          {
              if(this.currentPage !== 1)
              {
                  this.loadPractitioners(this.currentPage - 1);
                  this.currentPage -= 1;
              }
          } else {
              this.loadPractitioners(this.currentPage + 1);
              this.currentPage += 1;

          }
          this.previousPaginationButtonDisabled = this.currentPage === 1;
          if(this.currentPage === (Math.ceil(this.totalPractitioners/this.perPage)))
          {
              this.nextPaginationButtonDisabled = true;
          }
      },
  },
    mounted()
    {
        this.loadPractitioners();
        this.loadBreadcrumbs(this.crumbs);
    }
}
</script>
<style scoped lang="scss">
.active-tab {
  background-color: #d2f7efb3;
  color:  #0EE2B1;
}
.badge-green {
    background-color: green;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
}

.badge-orange {
    background-color: orange;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
}
</style>
