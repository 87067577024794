<script>
import vSelect from 'vue-select'

export default {
    name: "EnquiryMatchMultiselections",
    components: {vSelect},
    props: ['dropdowndata', 'id', 'selected_clinic_relationships'],
    data()
    {
        return {
            selections: [],
            selectionList: []
        }
    },
    methods: {
      submitForm()
      {
          axios.post('/admin/get-matched/update-relationships/'+this.id, this.selections)
              .then((res) => {

              }).catch((e) => {
                  console.log(e)
          });
      }
    },
    mounted()
    {

    },
    created() {
        // Convert the object to an array of objects
        this.selectionList = Object.entries(this.dropdowndata).map(([key, value]) => ({
            key: key,
            value: value.title
        }));

        if(this.selected_clinic_relationships)
        {
            this.selections = this.selected_clinic_relationships
        }
    }
}
</script>

<template>
    <div>
        <hr>
        <form @submit.prevent="submitForm">
            <div class="form-group">
                <label class="font-bold text-[20px]">Matches</label>
                <v-select label="value" multiple class="vue-select1" name="select1" :options="selectionList" v-model="selections">
                </v-select>
            </div>
            <div class="my-5">
                <button class="btn btn-secondary" type="submit">
                    Send Matches
                </button>
            </div>
        </form>
    </div>
</template>

<style scoped lang="scss">

</style>
