<template>
    <div>
        <ul class="list-group">
            <li
                class="pb-0 border-0 list-group-item justify-content-between ps-0 border-radius-lg"
            >
                <div class="d-flex">
                    <div class="d-flex align-items-center">

                        <div class="d-flex flex-column">
                            <h6 class="mb-1 text-sm text-dark">{{ transaction.description }}</h6>
                            <span class="text-xs">{{ transaction.date }}</span>
                        </div>
                    </div>
                    <div
                        class="text-sm d-flex align-items-center text-gradient font-weight-bold ms-auto"
                    >
                        <span class="px-2 py-1 rounded bg-gray-100 text-gray-600" v-if="transaction.refunded">Refunded</span>
                        <span class="px-2 py-1 rounded bg-green-100 text-green-600" v-else>Paid</span>
                    </div>
                    <div
                        class="text-sm d-flex align-items-center text-gradient font-weight-bold ms-auto"
                    >
                        <p class="mb-0">£{{ transaction.amount_charged }}</p>
                    </div>
                </div>
                <hr
                    v-if="horizontalBreak && index !== transaction.length - 1"
                    class="mt-3 mb-2 horizontal dark"
                />
                <div v-else class="mt-3 mb-2" />
            </li>
        </ul>
    </div>

</template>
<script>
export default {
  name: "RankingList",
  props: {
    title: {
      type: String,
      default: "",
    },
    transaction: {
      type: Array | Object,
    },
    horizontalBreak: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
