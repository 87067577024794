<template>
<div>
<div v-if="isDrawerOpen" class="fixed top-0 left-0 z-20 w-full h-full bg-gray-900 bg-opacity-50" @click="$emit('closeDrawer')"></div>
<div
  class="fixed scrollableDrawer top-0 bg-white left-0 z-50 w-64 h-full transition-all duration-500 transform"
  :class="{ '-translate-x-full': !isDrawerOpen, 'translate-x-0': isDrawerOpen }"
>
<div class="px-6 py-4">
    <h1 class="text-lg font-semibold">Search Filters</h1>
</div>
<div class="px-4 py-3 flex-grow">
      <!-- Filter options go here -->
      <div v-if="distanceFilter" class="mb-4">
        <label class="block text-sm font-bold text-gray-700">Distance in miles</label>
        <select v-model="filterForm.selectedDistance" class="mt-1 form-select block w-full py-2 px-3 border border-gray-300 bg-white rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
          <option v-for="(oneDistance, index) in distances" :key="index" :value="oneDistance.radius" :disabled="oneDistance.disabled" >{{ oneDistance.radius - 5 }} - {{ oneDistance.radius }} miles ({{ oneDistance.count }})</option>
        </select>
      </div>
      <br>

      <div v-if="serviceFilter" class="mb-4">
        <label class="block text-sm font-bold text-gray-700">Services</label>
        <label :class="oneService.disabled && 'opacity-30'" v-for="(oneService, index) in resultServices" :key="index" class="flex items-center justify-between">
          <input  type="radio" :value="oneService.service" v-model="filterForm.selectedService" :disabled="oneService.disabled" />
          <span class="pl-1">{{ oneService.service }}</span>
          <span class="ml-auto">{{ oneService.number }}</span>
        </label>
      </div>
      <br>
      <div v-if="mainServicePriceFilter" class="mb-4">
        <label class="block text-sm font-bold text-gray-700">Services Price Scale</label>
        <label :class="onePriceRange.disabled && 'opacity-30'" v-for="(onePriceRange, index) in mainServicePriceRange" :key="index" class="flex items-center justify-between">
          <input  type="radio" :value="onePriceRange" v-model="filterForm.selectedPriceRange" :disabled="onePriceRange.disabled"/>
          <span class="pl-1">£{{ onePriceRange.start }} - £{{ onePriceRange.end }}</span>
          <span class="ml-auto">{{ onePriceRange.count }}</span>
        </label>
      </div>
      <br>
      <div v-if="mainServiceRadiusTierFilter" class="mb-4">
        <label class="block text-sm font-bold text-gray-700">Services Radius Tiers</label>
        <label :class="oneRadiusTier.disabled && 'opacity-30'" v-for="(oneRadiusTier, index) in mainServiceaRadiusTierRange" :key="index" class="flex items-center justify-between">
          <input   type="radio" :value="oneRadiusTier" v-model="filterForm.selectedRadiusTier" :disabled="oneRadiusTier.disabled" />
          <span class="pl-1">{{ oneRadiusTier.start }} Miles - {{ oneRadiusTier.end }} Miles</span>
          <span class="ml-auto">{{ oneRadiusTier.count }}</span>
        </label>
      </div>
      <br>
      <div v-if="childServiceFilter" class="mb-4">
        <label class="block text-sm font-bold text-gray-700">Extras</label>
        <label :class="oneService.disabled && 'opacity-30'" v-for="(oneService, index) in resultChildServices" :key="index" class="flex items-center justify-between">
          <input type="radio" :value="oneService.service" v-model="filterForm.selectedChildService" :disabled="oneService.disabled"/>
          <span class="pl-1">{{ oneService.service }}</span>
          <span class="ml-auto">{{ oneService.number }}</span>
        </label>
      </div>
      <br>

      <!-- <div v-if="mainServiceFixedSurchargeFilter" class="mb-4">
        <label class="block text-sm font-bold text-gray-700">Child Services fixed surcharge range</label>
        <label :class="oneService.disabled && 'opacity-30'" v-for="(oneChildFixed, index) in childServiceFixedSurchargeRange" :key="index" class="flex items-center justify-between">
          <input  type="radio" :value="oneChildFixed" v-model="filterForm.selectedChildFixedSurcharge" />
          From {{ oneChildFixed.start }}£ To {{ oneChildFixed.end }}£
        </label>
      </div> -->
      <div v-if="ratingFilter" class="mb-4">
        <label class="block text-sm font-bold text-gray-700">Reviews</label>
        <label :class="review.disabled && 'opacity-30'" v-for="(review, index) in maxReview" :key="index" class="flex items-center justify-between">
          <input type="radio" :value="review.rating" v-model="filterForm.selectedReview" :disabled="review.disabled" />
          <span class="pl-1">{{ review.rating }} stars</span>
          <span class="ml-auto">{{ review.number }}</span>
        </label>
      </div>
      <br>
      <div v-if="professionFilter" class="mb-4">
        <label class="block text-sm font-bold text-gray-700">Profession</label>
        <label :class="oneprofession.disabled && 'opacity-30'" v-for="(oneprofession, index) in resultProfessions" :key="index" class="flex items-center justify-between">
          <input  type="radio" :value="oneprofession.profession" v-model="filterForm.selectedProfession" :disabled="oneprofession.disabled" />
          <span class="pl-1">{{ oneprofession.profession }}</span>
          <span class="ml-auto">{{ oneprofession.number }}</span>
        </label>
      </div>

      <br>
      <div v-if="genderFilter" class="mb-4">
        <label class="block text-sm font-bold text-gray-700">Gender</label>
        <label :class="onegender.disabled && 'opacity-30'" v-for="(onegender, index) in resultGenders" :key="index" class="flex items-center justify-between">
          <input  type="radio" :value="onegender.gender" v-model="filterForm.selectedGender" :disabled="onegender.disabled" />
          <span  class="pl-1">{{ onegender.gender }}</span>
          <span class="ml-auto">{{ onegender.number }}</span>
        </label>
      </div>
    </div>
    <div class="px-4 py-3 border-t flex" style="margin-bottom: 80px;">
      <button @click="resetFilters" class="bg-blue-500 w-full text-white mx-2  py-2 border border-transparent rounded-full shadow-sm font-semibold focus:outline-none">
        Reset Filters
      </button>
    </div>
</div>

</div>
</template>


<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'

export default {
    data() {
      return {
        // variables for decising whether to show or hide the element from the filter
        ratingFilter: false,
        genderFilter: false,
        distanceFilter: false,
        mainServicePriceFilter: false,
        mainServiceRadiusTierFilter: false,
        // mainServiceFixedSurchargeFilter: false,
        serviceFilter: false,
        childServiceFilter: false,
        professionFilter: false,


        // v-models
        filterForm: {
          selectedService: null,
          selectedChildService: null,
          selectedPriceRange: null,
          selectedRadiusTier: null,
          selectedChildFixedSurcharge: null,
          selectedReview: null,
          selectedGender: null,
          selectedDistance: null,
          selectedProfession: null
        },
        // Original filters before selecting
        maxReviewOriginal: [],
        distancesOriginal: [],
        resultOriginalGenders: [],
        resultOriginalProfessions: [],
        resultOriginalServices: [],
        resultOriginalChildServices: [],

        maxReview: [],
        distances: [],
        resultServices: [],
        resultChildServices: [],
        resultGenders: [],
        resultProfessions: [],
        // tiers and prices for services
        mainServicePriceRange: [],
        mainServiceaRadiusTierRange: [],
        minServiceRadiusPrice: 0,
        maxServiceRadiusPrice: 0,
        minServiceRadiusTier: 0,
        maxServiceRadiusTier: 0,


        allRadiusPrices: [],
        allRadiusTiers: [],

        // fixed modality range
        minFixedModality: 0,
        maxFixedModality: 0,

        // child service fixed modality range
        childServiceFixedSurchargeRange: [],
        minChildFixedSurcharge: 0,
        maxChildFixedSurcharge: 0,

        // included price for extra service
        included: false
      }
    },
    props: ['isDrawerOpen', 'method', 'searchResults'],
    methods: {
      ...mapMutations('search', ['SET_FILTERED_SEARCH_RESULTS']),
      resetFilters(){
        this.filterForm.selectedService = null
        this.filterForm.selectedChildService = null
        this.filterForm.selectedPriceRange = null
        this.filterForm.selectedRadiusTier = null
        this.filterForm.selectedChildFixedSurcharge = null
        this.filterForm.selectedReview = null
        this.filterForm.selectedGender = null
        this.filterForm.selectedDistance = null
        this.filterForm.selectedProfession = null
        this.SET_FILTERED_SEARCH_RESULTS(this.GET_SEARCH_RESULTS)

      },
      generateRanges(min, max, parameter) {
        let start = min;
        let range = [];
        while (start <= max) {
          range.push({ start: start, end: start + parameter, disabled: true, count: 0 });
          start += parameter;
        }
        return range
      },

      // calling the functions according to the search type
      getFilterParameters() {


        switch(this.method) {
          case 'postcode':
            this.getMaxReviews();
            this.getResultServices();
            this.getDistanceFilter();
            this.getClinicResultPractitionerProfessions();
            this.getClinicResultPractitionerGenders();
            break;
          case 'service':
            this.getMaxReviews();
            this.getResultServices();
            this.getDistanceFilter();
            this.getClinicResultPractitionerProfessions();
            this.getClinicResultPractitionerGenders();
            break;
          case 'clinic':
            this.getMaxReviews();
            this.getResultServices();
            this.getDistanceFilter();
            this.getClinicResultPractitionerProfessions();
            this.getClinicResultPractitionerGenders();
            break;
          case 'practitioner':
            this.getMaxReviews();
            this.getResultGenders();
            this.getResultServices();
            this.getResultProfessions();
            break;
          case 'profession':
            this.getMaxReviews();
            this.getResultGenders();
            this.getResultServices();
            this.getResultProfessions();
            break;
        }

      },

      // getting the distance filter according to how far each element is
      getDistanceFilter() {
        const distanceMap = new Map();
        const distanceOriginalMap = new Map();

        this.distanceFilter = false;

        // Initialize count map
        const countMap = new Map();

        for (const obj of this.GET_FILTERED_SEARCH_RESULTS) {
          const distance = Math.round(obj.distance);
          let nearestFive = Math.ceil(distance / 5) * 5;
          if (!distanceMap.has(nearestFive)) {
            if (nearestFive > 0) {
              distanceMap.set(nearestFive, {
                radius: nearestFive,
                disabled: false, // Initialize as not disabled
                count: 0, // Initialize count to 0
              });
            }
          }
          // Increment count for the distance
          const distanceObj = distanceMap.get(nearestFive);
          if (distanceObj) {
            distanceObj.count = (distanceObj.count || 0) + 1;
          }

        }

        for (const obj of this.GET_SEARCH_RESULTS) {
          const distance = Math.round(obj.distance);
          let nearestFive = Math.ceil(distance / 5) * 5;
          if (!distanceOriginalMap.has(nearestFive)) {
            if (nearestFive > 0) {
              distanceOriginalMap.set(nearestFive, {
                radius: nearestFive,
                disabled: false,  // Initialize as not disabled
                count: 0, // Initialize count to 0
              });
            }
          }
          // Increment count for the distance
          const distanceObj = distanceOriginalMap.get(nearestFive);
          if (distanceObj) {
            distanceObj.count = (distanceObj.count || 0) + 1;
          }
        }

        this.distances = Array.from(distanceMap.values());
        this.distancesOriginal =  Array.from(distanceOriginalMap.values());

        // Preserve old results and disable the ones that don't appear in new results
        for (const distanceObj of this.distancesOriginal) {
          const radius = distanceObj.radius;
          if (!distanceMap.has(radius)) {
            distanceObj.disabled = true;
          }
        }
        this.distances = this.distancesOriginal;

        if (this.distances.length > 0) {
          this.distanceFilter = true;
        }
      },

      // getting the max reviews from the result services
      getMaxReviews() {
        const ratingMap = new Map();
        const ratingOriginalMap = new Map();
        this.ratingFilter = false
        for (const obj of this.GET_FILTERED_SEARCH_RESULTS) {
          const rating = obj.reviews;
          if (ratingMap.has(rating)) {
            if(rating > 0){
              const ratingObj = ratingMap.get(rating);
              ratingObj.number++;
            }
          } else {
            if(rating > 0){
              ratingMap.set(rating, {
                rating: rating,
                number: 1,
              });
            }
          }
        }
        for (const obj of this.GET_SEARCH_RESULTS) {
          const rating = obj.reviews;
          if (ratingOriginalMap.has(rating)) {
            if(rating > 0){
              const ratingObj = ratingOriginalMap.get(rating);
              ratingObj.number++;
            }
          } else {
            if(rating > 0){
              ratingOriginalMap.set(rating, {
                rating: rating,
                number: 1,
                disabled: false,
              });
            }
          }
        }

        this.maxReview = Array.from(ratingMap.values())
        this.maxReviewOriginal = Array.from(ratingOriginalMap.values())

        // Preserve old results and disable the ones that don't appear in new results
        for (const ratingObj of this.maxReviewOriginal) {
          const rating = ratingObj.rating;
          if (!ratingMap.has(rating)) {
            ratingObj.disabled = true;
          }
        }
        this.maxReview = this.maxReviewOriginal

        if(this.maxReview.length > 0){
          this.ratingFilter = true
        }
      },

      // getting all the genders from the result services
      getResultGenders() {
        const genderMap = new Map();
        const genderOriginalMap = new Map();

        this.genderFilter = false
        for (const obj of this.GET_FILTERED_SEARCH_RESULTS) {
          if(typeof obj.gender !== 'undefined' && obj.gender !== '') {

              // setting the services filter
              const gender = obj.gender;
              if (genderMap.has(gender) && gender !== null) {
                  const rgenderObj = genderMap.get(gender);
                  rgenderObj.number++;
              } else if (gender !== null){
                  genderMap.set(gender, {
                    gender: gender,
                    number: 1,
                  });
              }
          }
        }
        for (const obj of this.GET_SEARCH_RESULTS) {
          if(typeof obj.gender !== 'undefined' && obj.gender !== '') {

              // setting the services filter
              const gender = obj.gender;
              if (genderOriginalMap.has(gender) && gender !== null) {
                  const rgenderObj = genderOriginalMap.get(gender);
                  rgenderObj.number++;
              } else if (gender !== null){
                  genderOriginalMap.set(gender, {
                    gender: gender,
                    number: 1,
                  });
              }
          }
        }
        this.resultGenders = Array.from(genderMap.values())
        this.resultOriginalGenders = Array.from(genderOriginalMap.values())


        // Preserve old results and disable the ones that don't appear in new results
        for (const genderObj of this.resultOriginalGenders) {
          const gender = genderObj.gender;
          if (!genderMap.has(gender)) {
            genderObj.disabled = true;
          }
        }
        this.resultGenders = this.resultOriginalGenders

        if(this.resultGenders.length > 0){
          this.genderFilter = true
        }
      },



      getClinicResultPractitionerProfessions() {
          const professionCounts = [];

          this.professionFilter = false;
          for (const obj of this.GET_FILTERED_SEARCH_RESULTS) {
            if (
              typeof obj.clinic_practitioner_professions !== 'undefined' &&
              obj.clinic_practitioner_professions !== '' &&
              obj.clinic_practitioner_professions !== null
            ) {
              let professions = obj.clinic_practitioner_professions;
              professions.forEach((profession) => {
                const existingProfession = professionCounts.find(
                  (item) => item.profession === profession
                );

                if (existingProfession) {
                  existingProfession.number++;
                } else {
                  professionCounts.push({ profession, number: 1 });
                }
              });
            }
          }

          // Preserve old results and disable the ones that don't appear in new results
          for (const professionObj of this.resultProfessions) {
            const profession = professionObj.profession;
            const professionExists = professionCounts.some(
              (item) => item.profession === profession
            );

            if (!professionExists) {
              professionObj.disabled = true; // Disable professions that don't appear in new results
            }
          }

          this.resultProfessions = professionCounts;
          if (this.resultProfessions.length > 0) {
            this.professionFilter = true;
          }
        },

      getClinicResultPractitionerGenders() {

        const genderCounts = [];

        this.genderFilter = false
        for (const obj of this.GET_FILTERED_SEARCH_RESULTS) {
          if(typeof obj.clinic_genders !== 'undefined' && obj.clinic_genders !== '' && obj.clinic_genders !== null) {
              // Create an empty array to store the objects
            let genders = obj.clinic_genders;
              // Iterate over the array and count the occurrences
              genders.forEach(gender => {
                const existingGender = genderCounts.find(item => item.gender === gender);

                if (existingGender) {
                  existingGender.number++;
                } else {
                  genderCounts.push({ gender, number: 1 });
                }
              });

          }
        }

        // Preserve old results and disable the ones that don't appear in new results
        for (const genderObj of this.resultGenders) {
          const gender = genderObj.gender;
          const genderExists = genderCounts.some(
            (item) => item.gender === gender
          );

          if (!genderExists) {
            genderObj.disabled = true; // Disable professions that don't appear in new results
          }
        }

        this.resultGenders = genderCounts
        if(this.resultGenders.length > 0){
          this.genderFilter = true
        }
      },
      // getting all the profession from the result services for practitioners result
      getResultProfessions() {
        const practitionerMap = new Map();
        const practitionerOriginalMap = new Map();
          this.professionFilter = false
        for (const obj of this.GET_FILTERED_SEARCH_RESULTS) {
          if(typeof obj.profession !== 'undefined' && obj.profession !== '' && obj.profession !== null) {

              // setting the services filter
              const profession = obj.profession;

              if (practitionerMap.has(profession)) {
                  const rprofessionObj = practitionerMap.get(profession);
                  rprofessionObj.number++;
              } else {
                  practitionerMap.set(profession, {
                    profession: profession,
                    number: 1,
                  });
              }
          }
        }
        for (const obj of this.GET_SEARCH_RESULTS) {
          if(typeof obj.profession !== 'undefined' && obj.profession !== '' && obj.profession !== null) {

              // setting the services filter
              const profession = obj.profession;

              if (practitionerOriginalMap.has(profession)) {
                  const rprofessionObj = practitionerOriginalMap.get(profession);
                  rprofessionObj.number++;
              } else {
                practitionerOriginalMap.set(profession, {
                    profession: profession,
                    number: 1,
                  });
              }
          }
        }
        this.resultProfessions = Array.from(practitionerMap.values())
        this.resultOriginalProfessions = Array.from(practitionerOriginalMap.values())

        // Preserve old results and disable the ones that don't appear in new results
        for (const practitionerObj of this.resultOriginalProfessions) {
          const profession = practitionerObj.profession;
          if (!practitionerMap.has(profession)) {
            practitionerObj.disabled = true;
          }
        }
        this.resultProfessions = this.resultOriginalProfessions

        if(this.resultProfessions.length > 0){
          this.professionFilter = true
        }
      },

      // getting the price scale and the radius tier
      setMinMaxRadiusPrice(pricings) {
        if(pricings.pricing_modality === 'radius'){
          for(const tier in pricings.radius_tier) {
            const tierData = pricings.radius_tier[tier]
            for(const key in tierData) {
              const value = tierData[key]
              if (value !== null) {
                const radiusValue = parseInt(value);
                if (key.startsWith('radius_miles_tier')) {
                  if (radiusValue < this.minServiceRadiusTier) {
                    this.minServiceRadiusTier = radiusValue;
                  }
                  if (radiusValue > this.maxServiceRadiusTier) {
                    this.maxServiceRadiusTier = radiusValue;
                  }
                  this.allRadiusTiers.push(radiusValue)
                }
                if (key.startsWith('radius_miles_price')) {
                  if (radiusValue < this.minServiceRadiusPrice) {
                    this.minServiceRadiusPrice = radiusValue;
                  }
                  if (radiusValue > this.maxServiceRadiusPrice) {
                    this.maxServiceRadiusPrice = radiusValue;
                  }
                  this.allRadiusPrices.push(radiusValue)
                }
              }
            }
          }
        }else if(pricings.pricing_modality === 'fixed'){
          if(this.minFixedModality > pricings.fixed_service_price){
            this.minFixedModality = pricings.fixed_service_price
          }
          if(this.maxFixedModality < pricings.fixed_service_price) {
            this.maxFixedModality = pricings.fixed_service_price
          }

        }
      },

      // setting the filters according to the result services
      getResultServices() {
        const serviceMap = new Map();
        const childserviceMap = new Map();
        const serviceOriginalMap = new Map();
        const childserviceOriginalMap = new Map();
        this.mainServicePriceFilter = false
        this.mainServiceRadiusTierFilter = false
        this.serviceFilter = false
        this.childServiceFilter = false

        this.allRadiusPrices = []
        this.allRadiusTiers = []
        for (const obj of this.GET_FILTERED_SEARCH_RESULTS) {
          if(typeof obj.services !== 'undefined') {

            for(const oneService of obj.services) {
              // setting the services filter
              const service = oneService.main_service_name;
              // setting the pricings from string to json
              var pricings = JSON.parse(oneService.pricings);
              this.setMinMaxRadiusPrice(pricings)

              if (serviceMap.has(service)) {
                  const ratingObj = serviceMap.get(service);
                  ratingObj.number++;
              } else {
                  serviceMap.set(service, {
                    service: service,
                    number: 1,
                  });
              }
              // setting the child services filter
              for(const oneChildService of oneService.childServices) {
                const childService = oneChildService.main_service_name;
                if (childserviceMap.has(childService)) {
                    const ratingObj = childserviceMap.get(childService);
                    ratingObj.number++;
                } else {
                  childserviceMap.set(childService, {
                      service: childService,
                      number: 1,
                    });
                }
              }
            }
            this.mainServicePriceRange = this.generateRanges(this.minServiceRadiusPrice, this.maxServiceRadiusPrice, 50)
            this.mainServiceaRadiusTierRange = this.generateRanges(this.minServiceRadiusTier, this.maxServiceRadiusTier, 10)

            if(this.mainServicePriceRange.length > 0) {
              this.mainServicePriceFilter = true
            }
            if(this.mainServiceaRadiusTierRange.length > 0) {
              this.mainServiceRadiusTierFilter = true
            }
          }
        }
        for (const obj of this.GET_SEARCH_RESULTS) {
          if(typeof obj.services !== 'undefined') {

            for(const oneService of obj.services) {
              // setting the services filter
              const service = oneService.main_service_name;
              // setting the pricings from string to json
              var pricings = JSON.parse(oneService.pricings);
              // this.setMinMaxRadiusPrice(pricings)

              if (serviceOriginalMap.has(service)) {
                  const ratingObj = serviceOriginalMap.get(service);
                  ratingObj.number++;
              } else {
                serviceOriginalMap.set(service, {
                    service: service,
                    number: 1,
                  });
              }
              // setting the child services filter
              for(const oneChildService of oneService.childServices) {
                const childService = oneChildService.main_service_name;
                if (childserviceOriginalMap.has(childService)) {
                    const ratingObj = childserviceOriginalMap.get(childService);
                    ratingObj.number++;
                } else {
                  childserviceOriginalMap.set(childService, {
                      service: childService,
                      number: 1,
                    });
                }
              }
            }
            this.mainServicePriceRange = this.generateRanges(this.minServiceRadiusPrice, this.maxServiceRadiusPrice, 50)
            this.mainServiceaRadiusTierRange = this.generateRanges(this.minServiceRadiusTier, this.maxServiceRadiusTier, 10)
            // this.childServiceFixedSurchargeRange = this.generateRanges(this.minChildFixedSurcharge, this.maxChildFixedSurcharge, 50)
            this.mainServicePriceRange = this.updateDisabledRanges(this.mainServicePriceRange, this.allRadiusPrices)
            this.mainServiceaRadiusTierRange = this.updateDisabledRanges(this.mainServiceaRadiusTierRange, this.allRadiusTiers)
            if(this.mainServicePriceRange.length > 0) {
              this.mainServicePriceFilter = true
            }
            if(this.mainServiceaRadiusTierRange.length > 0) {
              this.mainServiceRadiusTierFilter = true
            }
            if(this.childServiceFixedSurchargeRange.length > 0) {
              // this.mainServiceFixedSurchargeFilter = true
            }
          }
        }
        this.resultServices = Array.from(serviceMap.values())
        this.resultChildServices = Array.from(childserviceMap.values())
        this.resultOriginalServices = Array.from(serviceOriginalMap.values())
        this.resultOriginalChildServices = Array.from(childserviceOriginalMap.values())

        // Preserve old results and disable the ones that don't appear in new results
        for (const servicesObj of this.resultOriginalServices) {
          const service = servicesObj.service;
          if (!serviceMap.has(service)) {
            servicesObj.disabled = true;
          }
        }

        // Preserve old results and disable the ones that don't appear in new results

        for (const childservicesObj of this.resultOriginalChildServices) {
          const service = childservicesObj.service;
          if (!childserviceMap.has(service)) {
            childservicesObj.disabled = true;
          }
        }

        this.resultServices = this.resultOriginalServices
        this.resultChildServices = this.resultOriginalChildServices
        if(this.resultServices.length > 0){
          this.serviceFilter = true
        }
        if(this.resultChildServices.length > 0){
          this.childServiceFilter = true
        }
      },
      updateDisabledRanges(A, B) {
        for (let i = 0; i < A.length; i++) {
          for (let j = 0; j < B.length; j++) {
            if (B[j] >= A[i].start && B[j] <= A[i].end) {
              A[i].disabled = false;
            }
            if(A[i].disabled == false) {
              A[i].count++;
              break
            }
          }
        }
        return A;
      },
      applyFilters(filterForm) {
        this.searchResultss = this.searchResults
        this.applyRealFilters(this.GET_FILTERED_SEARCH_RESULTS, filterForm)
        // this.SET_FILTERED_SEARCH_RESULTS(this.searchResultss)
      },
      applyRealFilters(objects, filterForm) {
        let filteredData = objects

        // filter by service name
        if(filterForm.selectedService != null && filterForm.selectedService != ''){
          objects = objects.filter(obj => {
            if (obj.services) {
              return obj.services.some(service => service.main_service_name === filterForm.selectedService);
            }
            return false;
          });
        }
        // filter by child service name
        if(filterForm.selectedChildService != null && filterForm.selectedChildService != ''){
          objects = objects.filter(obj => {
            if (obj.services) {
              return obj.services.some(service => {
                if (service.childServices) {
                  return service.childServices.some(childService => childService.main_service_name === filterForm.selectedChildService);
                }
                return false;
              });
            }
            return false;
          });

        }
        // filter by service price range
        if(filterForm.selectedPriceRange != null){
          objects = objects.filter(obj => {
            if (obj.services) {
              return obj.services.some(service => {
                if (service.pricings) {
                  const pricing = JSON.parse(service.pricings);
                  const radiusTiers = pricing.radius_tier;

                  for (let i = 1; i <= 4; i++) {
                    const priceKey = `radius_miles_price_${i}`;
                    if (radiusTiers[`tier${i}`] && radiusTiers[`tier${i}`][priceKey]) {
                      const price = parseFloat(radiusTiers[`tier${i}`][priceKey]);
                      if (price >= filterForm.selectedPriceRange.start && price <= filterForm.selectedPriceRange.end) {
                        return true;
                      }
                    }
                  }
                }
                return false;
              });
            }
            return false;
          });
        }

        // filter by service tier range
        if(filterForm.selectedRadiusTier != null) {
           objects = objects.filter(obj => {
            if (obj.services) {
              return obj.services.some(service => {
                if (service.pricings) {
                  const pricing = JSON.parse(service.pricings);
                  const radiusTiers = pricing.radius_tier;

                  for (const tierKey in radiusTiers) {
                    if (radiusTiers.hasOwnProperty(tierKey)) {
                      const tier = radiusTiers[tierKey];
                      for (let i = 1; i <= 4; i++) {
                        const priceKey = `radius_miles_tier_${i}`;
                        if (tier && tier[priceKey]) {
                          const price = parseFloat(tier[priceKey]);
                          if (price >= filterForm.selectedRadiusTier.start && price <= filterForm.selectedRadiusTier.end) {
                            return true;
                          }
                        }
                      }
                    }
                  }
                }
                return false;
              });
            }
            return false;
          });
        }

        // filter by child fixed surcharge
        if(filterForm.selectedChildFixedSurcharge != null){
           objects = objects.filter(obj => {
            if (obj.services) {
              return obj.services.some(service => {
                if (service.childServices) {
                  return service.childServices.some(childService => {
                    if (childService.pricings) {
                      const pricing = JSON.parse(childService.pricings);
                      const pricingModality = pricing.pricing_modality;

                      // uncomment this in case you want to show the service even if the price of it is not in the filter but you just want to show it anyway because it's included
                      // if (pricingModality === "included") {
                      //   return true;  // Don't filter if pricing_modality is "included"
                      // } else
                      if (pricingModality === "surcharge") {
                        if (pricing.surcharge_service_price) {
                          const surchargePrice = parseFloat(pricing.surcharge_service_price);
                          if (surchargePrice >= filterForm.selectedChildFixedSurcharge.start && surchargePrice <= filterForm.selectedChildFixedSurcharge.end) {
                            return true;  // Filter if surcharge_service_price is within the selected range
                          }
                        }
                      }
                    }
                    return false;
                  });
                }
                return false;
              });
            }
            return false;
          });

        }

        // filter by service clinic review
        if(filterForm.selectedReview != null && filterForm.selectedReview != ''){
           objects = objects.filter(obj => {
            if (obj.reviews) {
              return obj.reviews == filterForm.selectedReview;
            }
            return false;
          });
        }

        // filter by distance lower than the chosen distance
        if(filterForm.selectedDistance != null) {

          objects = objects.filter(obj => {
            if (obj.distance) {
              return obj.distance < filterForm.selectedDistance && obj.distance > filterForm.selectedDistance - 5;
            }
            return false;
          });

        }


        // filter by gender same as the chosen gender
        if(filterForm.selectedGender != null && filterForm.selectedGender != '') {

          objects = objects.filter(obj => {
            if (obj.gender) {
              return obj.gender == filterForm.selectedGender;
            }
            if (obj.clinic_genders) {
              return obj.clinic_genders.includes(filterForm.selectedGender);
            }
            return false;
          });

        }

        // filter by gender same as the chosen gender
        if(filterForm.selectedProfession != null && filterForm.selectedProfession != '') {

          objects = objects.filter(obj => {
          if (obj.gender) {
            return obj.profession == filterForm.selectedProfession;
          }
          if (obj.clinic_practitioner_professions) {
            return obj.clinic_practitioner_professions.includes(filterForm.selectedProfession);
          }
          return false;
          });

        }

        this.SET_FILTERED_SEARCH_RESULTS(objects)
        this.getFilterParameters()

        // this.$emit('closeDrawer')
      },
    },
    computed: {
      ...mapGetters('search', ['GET_SEARCH_RESULTS', 'GET_FILTERED_SEARCH_RESULTS']),
      isResultReturned() {
        if(typeof this.GET_SEARCH_RESULTS[0]?.ref  !== 'undefined') {
            return typeof this.GET_SEARCH_RESULTS[0].ref !== 'undefined'
          }else {
            return false
          }
      }
    },
    created() {
      if(this.isResultReturned){
        this.SET_FILTERED_SEARCH_RESULTS(this.GET_SEARCH_RESULTS)

        this.getFilterParameters()
      }
    },
    watch: {
      filterForm: {
            handler(newValue) {
              this.applyFilters(newValue)
            },
            deep: true
        },
      isResultReturned: {
            handler(newValue) {
              this.SET_FILTERED_SEARCH_RESULTS(this.GET_SEARCH_RESULTS)

              this.getFilterParameters()
            },
        }
    }
}
</script>

<style lang="scss" scoped>
    .scrollableDrawer {
        overflow-y: scroll;
        width: 280px;
        padding: 20px 10px;
        margin-top: 95px;

        label {
            font-size: 17px!important;
        }

        label > span {
            font-size: 16px!important;
        }
    }
</style>
