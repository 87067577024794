<template>
    <transition name="fade">
        <div class="modal-overlay fixed inset-0 w-full h-screen flex items-center justify-center bg-semi-75" style="background-color:rgba(0,0,0,0.6);z-index:101;" @click="$emit('close')">
            <div class="card-det modal-card modal modal-wrapper w-full max-w-2xl bg-white shadow-lg rounded-lg addModal" style="z-index:101; max-height: 600px; position:relative">
                <div class="modal-container flex flex-col h-full" @click.stop>
                    <!-- Header -->
                    <div class="modal-header block-title sticky top-0 bg-white z-10 p-4">
                        <slot name="header">
                            <div class="header-bit w-full flex">
                                <p class="text-left card-title font-bold uppercase my-auto flex-grow mb-0">{{title}}</p>
                                <a href="#" @click.prevent="$emit('close')" class="ml-auto close-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-9 w-9" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </a>
                            </div>
                        </slot>
                    </div>

                    <!-- Scrollable Body -->
                    <div class="modal-body flex-grow overflow-y-auto p-4">
                        <slot name="content"></slot>
                    </div>

                    <!-- Footer -->
                    <div class="modal-footer sticky bottom-0 bg-white z-10 p-4">
                        <slot name="footer">
                            <div class="action-buttons w-full flex">
                                <a href="#" class="modal-default-button cancel-button text-gray-600 my-auto" @click.prevent="$emit('close')">
                                    Cancel
                                </a>
                                <div v-if="showActionButton" class="ml-auto">
                                    <button class="rounded capitalize px-3 py-1 text-white font-semibold text-[17px] bg-[#0EE2B1]" @click.prevent="$emit('save')" :disabled="disabled">
                                        <span v-if="loading" class="spinner-border" role="status" aria-hidden="true"></span>
                                        <span v-else>{{buttonText}}</span>
                                    </button>
                                </div>
                            </div>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        title: String,
        buttonText: String,
        showActionButton: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped>
.modal-card {
    display: flex;
    flex-direction: column;
    max-height: 100%; /* Limit the height of the modal */
}

.modal-header, .modal-footer {
    position: sticky; /* Keep header and footer fixed */
    background-color: white;
    z-index: 10;
}

.modal-body {
    flex-grow: 1; /* Allow body to take up remaining space */
    overflow-y: auto; /* Enable vertical scrolling for the body */
}
</style>
