<template>
    <div>
        <b-button style="background-color: rgb(14, 226, 177)!important; border-color: rgb(14, 226, 177)!important;" :disabled="disabled" @click="activateService(service)">
            {{ btnText }}
        </b-button>
        <div class="p-2 rounded bg-red-100 flex mt-3" v-if="showErrorMessage">
            <p class="text-red-800 m-auto">{{errorMessage}}</p>
        </div>
    </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import { useStripe } from '@vue-stripe/vue-stripe';

export default {
    name: 'PaymentProcessingButton',
    props: ['stripe_key', 'buttonText', 'service'],
    data() {
        return {
            disabled: false,
            btnText: 'Activate Service',
            showErrorMessage: false,
            errorMessage: ''
        };
    },
    methods: {
        async activateService(service) {
            this.disabled = true;
            this.btnText = 'Activating ... Please wait';
            const serviceCode = service.toLowerCase();
            const url = `/dashboard/activate-service/${serviceCode}/user`;

            try {
                // Make the API call to your backend to initiate the service activation
                const response = await axios.post(url);
                console.log(response.data)
                const { requires_action, payment_intent_client_secret } = response.data;

                if (requires_action) {
                    // If 3D Secure authentication is required, initiate 3DS
                    this.handle3DSecure(payment_intent_client_secret);
                } else {
                    // If no 3D Secure is required, just reload the page
                    this.btnText = 'Successful';
                    window.location.reload();
                }
            } catch (error) {
                console.error('Error during activation:', error);
                this.showErrorMessage = true;
                this.errorMessage = error.response.data.message ?? error.response.data.error ?? 'An Error Has Occurred. Speak To Support'
                this.disabled = false;
                this.btnText = 'Activation Failed';
            }
        },

        async handle3DSecure(clientSecret) {
            // Initialize Stripe.js using the publishable key
            const stripe = await loadStripe(this.stripe_key);

            // Handle 3D Secure authentication
            const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret);

            if (error) {
                // Handle error from Stripe
                console.error('3D Secure authentication failed:', error);
                this.disabled = false;
                this.btnText = 'Activation Failed - Please Contact Support';
                this.showErrorMessage = true;
                this.errorMessage = error.response.data.message ?? error.response.data.error ?? 'An Error Has Occurred. Speak To Support'
            } else if (paymentIntent.status === 'succeeded') {
                // Successfully authenticated with 3D Secure
                this.completePayment(paymentIntent.id);
                this.btnText = 'Service Successfully Activated'
                this.disabled = true;
            }
        },

        async completePayment(paymentIntentId) {
            try {
                // Send the payment intent ID to the backend to confirm the payment
                const response = await axios.post(`/dashboard/complete-transaction/${this.service.toLowerCase()}`, {
                    payment_intent_id: paymentIntentId,
                });
                this.btnText = 'Successful';
                window.location.reload();
            } catch (error) {
                console.error('Error confirming payment intent:', error);
                this.showErrorMessage = true;
                this.errorMessage = error.response.data.message ?? error.response.data.error ?? 'An Error Has Occurred. Speak To Support'
                this.disabled = false;
                this.btnText = 'Activation Failed';
            }
        },
    },
};
</script>
