import axios from 'axios';
export default {
    namespaced: true,
    state: {
        loadingSubmitButton: false,
        tabs: {},
        active_tab: '',
        ShowErrors: false,
        deletionRequest: false,
        workflow: {},
        services: [],
        user_has_card_on_system: false,
        user_billing_details: [],
        user_card_details: [],
        transactions: [],
        loading: true,
        active_services: []
    },
    mutations: {
        setLoadingSubmitButton(state, loadingState) {
            state.loadingSubmitButton = loadingState
        },
        setAccountTabs(state, tabs)
        {
            state.tabs = tabs;
        },
        setActiveTab(state, tab)
        {
            state.active_tab = tab
        },
        setShowErrors(state, show)
        {
            state.ShowErrors =  show
        },
        setWorkflow(state, workflow)
        {
            state.workflow = workflow
        },
        setDeletionRequest(state, deletionRequest)  {
            state.deletionRequest = deletionRequest
        },
        setServices(state, data)
        {
            state.services = data
        },
        SET_HAS_CARD_ON_SYSTEM(state, data)
        {
            state.user_has_card_on_system = data;
        },
        SET_USER_BILLING_DETAILS(state, data)
        {
            state.user_billing_details = data;
        },
        SET_USER_CARD_DETAILS(state, data)
        {
            state.user_card_details =  data;
        },
        SET_TRANSACTIONS(state, data)
        {
            state.transactions =  data;
        },
        SET_LOADER(state, data)
        {
            state.loading = data;
        },
        SET_ACTIVE_SERVICES(state, data)
        {
            state.active_services = data;
            state.loading = false;
        }


    },
    actions: {
        // Change tab
        changeActiveTab({commit, dispatch}, payload)
        {

            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set('tab', payload);
            const newUrl = window.location.origin + window.location.pathname + '?' + searchParams;
            window.history.pushState({path:newUrl},'',newUrl);

            commit('setActiveTab', payload);
        },
        setupAccountTabs({commit}){
            axios.get('/dashboard/account/setup-account-tabs')
            .then((res) => {
                commit('setAccountTabs', res.data)
            }).catch((e) => {
                console.log(e)
            });
        },
        setDeleteDetails({}){
          return new Promise((resolve, reject) => {
            axios.get('/dashboard/account/set-delete-details')
            .then((res) => {
                resolve(res)
            }).catch((e) => {
                reject(e)
                console.log(e)
            });
          })
        },
        loadWorkflow({commit}, type) {
          return new Promise((resolve, reject) => {
            axios.post('/get-workflow', {
                type: type || 'updatePassword'
            })
            .then((res) => {
              commit('setWorkflow', res.data)
              resolve(res)
            }).catch((e) => {
              console.log(e)
              reject(e)
            })
          })
        },
        saveNewPassword({commit}, form) {
          return new Promise((resolve, reject) => {
            axios.post('/dashboard/account/update-password', form)
            .then((res) => {
              resolve(res)
            }).catch((e) => {
              console.log(e)
              reject(e)
            })
          })
        },
        confirmDeleteAccount({commit, dispatch}, payload)
        {
            return new Promise((resolve, reject)=> {

                Swal.fire({
                    title: "You're deleting your account",
                    text: "Are you sure you want to delete your account?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        axios.post('/dashboard/clinic/delete-clinic-request', payload)
                        .then(res => {
                            Swal.fire({
                                toast: true,
                                icon: 'success',
                                title: 'Deletion request sent',
                                animation: true,
                                position: 'top-right',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true
                            }).then(() => {
                                resolve(res)
                                commit('setDeletionRequest', true)
                            })
                        })
                    }else {
                        resolve(res)
                    }
                }).catch((e) => {
                    reject(e)
                    Swal.fire({
                        toast: true,
                        icon: 'error',
                        title: e.response.status+' Error.',
                        animation: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true
                    }).then(e => {
                        reject(e)
                        commit('setDeletionRequest', true)
                    });
                });
            })
        },
        confirmCodeDeleteAccount({commit, dispatch}, payload)
        {
                Swal.fire({
                title: "You're deleting your account",
                text: "Are you sure you want to delete your account? This is the last warning",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post('/dashboard/clinic/delete-confirmation', payload)
                    .then(() => {
                        Swal.fire({
                            toast: true,
                            icon: 'success',
                            title: 'Account Deleted Successfully',
                            animation: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true
                        }).then(e => {
                            window.location.reload();
                            reject(e)
                        })

                    }).catch((e) => {
                        Swal.fire({
                            toast: true,
                            icon: 'error',
                            title: 'Wrong Confirmation Code !',
                            animation: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true
                        });
                    });
                }
            }).catch((e) => {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: e.response.status+' Error.',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true
                });
            });
        },
        abortCodeDeleteAccount({commit, dispatch})
        {
                Swal.fire({
                title: "You're aborting the deletion of your account",
                text: "Are you sure you want to abort ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, abort!'
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post('/dashboard/clinic/abort-delete-confirmation')
                    .then(() => {
                        Swal.fire({
                            toast: true,
                            icon: 'success',
                            title: 'Operation Aborted Successfully',
                            animation: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true
                        }).then(e => {
                            window.location.reload();
                            reject(e)
                        })

                    })

                }
            }).catch((e) => {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: e.response.status+' Error.',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true
                });
            });
        },
        GET_APP_SERVICES({commit})
        {
            axios.get('/dashboard/account/get-app-services').then((res) => {
                commit('setServices', res.data)
            }).catch((e) => {
               console.log(e);
            });
        },
        GET_USER_HAS_VALID_CARD({commit})
        {
            axios.get('/user/has-card-on-system').then((res) => {
                commit('SET_HAS_CARD_ON_SYSTEM', res.data)
            }).catch((e) => {
               console.log(e)
            });
        },
        GET_USER_BILLING_DETAILS({commit})
        {
            axios.get('/user/billing-details').then((res) => {
                commit('SET_USER_BILLING_DETAILS', res.data)
            }).catch((e) => {
                console.log(e)
            })
        },
        GET_USER_CARD_DETAILS({commit})
        {
            axios.get('/user/card-details').then((res) => {
                commit('SET_USER_CARD_DETAILS', res.data)
            }).catch((e) => {
                console.log(e)
            })
        },
        GET_TRANSACTIONS({commit})
        {
            axios.get('/user/transactions').then((res) => {
                commit('SET_TRANSACTIONS', res.data)
            }).catch((e) => {
                console.log(e)
            })
        },
        START_LOADER({commit})
        {
            commit('SET_LOADER', true);
        },
        RETRIEVE_ACTIVE_SERVICES({commit})
        {
            axios.get('/user/active-services').then((res) => {
                commit('SET_ACTIVE_SERVICES', res.data)
                commit('SET_LOADER', false)
            }).catch((e) => {
               console.log(e)
            });
        }
    },
    getters: {
        getLoadingSubmitButton(state){
            return state.loadingSubmitButton
        },
        GET_PAGE_TABS(state)
        {
           return state.tabs
        },
        GET_ACTIVE_TAB(state)
        {
            return state.active_tab
        },
        GET_SHOW_ERRORS(state)
        {
            return state.ShowErrors
        },
        GET_WORKFLOW(state)
        {
            return state.workflow
        },
        GET_DELETION_REQUEST(state)
        {
            return state.deletionRequest
        },
        GET_LOADER(state) {
            return state.loading;
        },
        GET_ACTIVE_SERVICES(state)
        {
            return state.active_services;
        }
    }

}


