<template>
<div class="" style="height: 300px;">
    <div
      class="page-header min-height-300 border-radius-xl"
      :style="{
        backgroundImage:
          'url(' + require('../../../../../public/images/curved14.jpg') + ')',
        backgroundPositionY: '50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }"
    >
      <span class="mask bg-gradient-success opacity-6"></span>
    </div>
    <div class="absolute bottom-20 z-10 right-0">
      <ProfileWarnings v-if="!isCompliant && !isAdmin"></ProfileWarnings>
    </div>
        <div class="flex-col w-full p-4 absolute" style="top:0">
            <div class="toptop w-full flex">
                <div class="">
                    <Breadcrumbs :color="'#fffff'" :size="'20px'"></Breadcrumbs>
                </div>
                <div class="ml-auto">
                    <div class="w-full flex ">
                        <ul class="my-auto flex" style="margin-left:auto; list-style: none">
                            <li class="nav-item dropdown">
                                <a class="text-white font-bold nav-link uppercase text-sm"
                                   href="/conversations">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                         stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                              d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 0 1-.923 1.785A5.969 5.969 0 0 0 6 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337Z" />
                                    </svg>
                                </a>
                            </li>
                            <li class="nav-item dropdown">
                                <a id="navbarDropdown" class="text-white font-bold mb-0 nav-link mx-1 uppercase text-sm" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <Icon :icon="'bell'"></Icon>
                                </a>
                                <ul class="px-2 py-3 dropdown-menu dropdown-menu-end me-sm-n4" aria-labelledby="dropdownMenuButton">
                                    <li class="mb-2">
                                        <a href="" class="dropdown-item border-radius-md">No notifications</a>
                                    </li>
                                </ul>
                            </li>
                            <li class="nav-item dropdown">
                                <a id="navbarDropdown" class="text-white font-bold mb-0 nav-link  uppercase text-sm" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <Icon :icon="'account_circle'"></Icon>
                                </a>
                                <div class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                                    <a class="dropdown-item border-bottom py-2">
                                        {{ clinicname }}
                                    </a>
                                    <a class="dropdown-item" :href="homeroute ? homeroute : '/home' ">
                                        Dashboard
                                    </a>
                                    <a class="dropdown-item cursor-pointer" @click="logout()">
                                        Logout
                                    </a>
                                </div>
                            </li>
                        </ul>

                    </div>
                </div>

            </div>
            <div class="container-fluid" style="margin-top:80px;">
                <h1 class="text-white font-bold">{{ pageDetails.title }}</h1>
                <p class="text-white font-semibold">{{ pageDetails.description }}</p>
            </div>
        </div>

    </div>
</template>

<script>
import { mapActions } from 'vuex';
import Breadcrumbs from '../../Breadcrumbs.vue';
import Icon from '../../Icon';
import ProfileWarnings from "../../Utilities/ProfileWarnings.vue";

export default {
    props: {
      clinicname : {
        type: String
      },
        homeroute: {
          type: String
        },
      pages: {
        type: Array | Object
      }
    },
    components: {
      ProfileWarnings,
    Icon,
    Breadcrumbs
},
    data() {
        return {
            pageDetails: {},
            isCompliant: true,
            isAdmin: false
        }
    },
    methods: {
        ...mapActions('commonFunctions', ['logout']),
      checkIfAdmin()
      {
        axios.get('/check-if-admin').then((res) => {
          this.isAdmin = res.data
        });
      }
    },
    mounted()
    {
        this.checkIfAdmin();
        const pageArr = Object.keys(this.pages).map((key) => [Number(key), this.pages[key]]);
        pageArr.forEach(element => {
            if(window.location.pathname == element[1].url)
            {
               this.pageDetails = element[1];
            }
        });
        axios.get('/dashboard/check-if-overall-compliant').then((res) => {
          this.isCompliant = res.data
          console.log(res);
        }).catch((e) => {
          console.log(e)
        });
    }
}

</script>
