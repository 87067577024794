import axios from 'axios';
import { defaultsDeep } from 'lodash';
export default {
    namespaced: true,
    state: {
        practitioners: {},
        practitioner: {},
        tabs: {},
        active_tab: '',
        errors: '',
        practitioners_satelites: '',
        associations_list: {},
        publicationModal: false,
        qualificationModal: false,
        associationModal: false,
        satelite_services: '',
        logs: []
    },
    getters: {
        GET_PAGE_TABS(state)
        {
           return state.tabs
        },
        GET_PRACTITIONER(state)
        {
          return state.practitioner
        },
        GET_ACTIVE_TAB(state)
        {
            return state.active_tab
        },
        GET_ERRORS(state)
        {
            return state.errors
        },
        GET_PUBLICATION_MODAL_STATUS(state)
        {
            return state.publicationModal
        },
        GET_QUALIFICATION_MODAL_STATUS(state)
        {
            return state.qualificationModal
        },
        GET_ASSOCIATION_MODAL_STATUS(state)
        {
            return state.associationModal
        },
        GET_ASSOCIATION_LIST(state)
        {
            return state.associations_list
        },
        GET_ALL_PRACTITIONERS(state)
        {
            return state.practitioners
        },
        GET_PRACTITIONER_SATELITES(state)
        {
            return state.practitioners_satelites
        },
        GET_LOGS(state)
        {
            return state.logs
        }
    },
    actions:{
        setAssociatedServices({commit}, payload)
        {
            return new Promise((resolve, reject) => {
                axios.put('/dashboard/services/set-associated-practitioners/' + payload.ref, {
                    servicesList: payload.servicesList
                })
                .then(response => {
                    resolve(response)
                    context.commit('SET_SATELITE_SERVICES', response.data)
                })
                .catch(error => {
                    reject(error)
                })
            })
        },
        getAssociatedServices(context, ref)
        {
            return new Promise((resolve, reject) => {
                axios.get('/dashboard/services/get-associated-practitioners/' + ref)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
            })
        },
        setAssociatedSateliteClinics(context, payload)
        {
            return new Promise((resolve, reject) => {
                axios.put('/dashboard/practitioners/set-associated-satelite-clinics/' + payload.ref, {
                    sateliteClinicsList: payload.sateliteClinicsList
                })
                .then(response => {
                    resolve(response)
                    context.commit('SET_PRACTITIONER_SATELITES', response.data)
                })
                .catch(error => {
                    reject(error)
                })
            })
        },
        getAssociatedSateliteClinics(context, ref)
        {
            return new Promise((resolve, reject) => {
                axios.get('/dashboard/practitioners/get-associated-satelite-clinics/' + ref)
                .then(response => {
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
            })
        },
        getPractitioners(context, { page, perPage, search }) {
            return new Promise((resolve, reject) => {
                axios.get(`/dashboard/practitioners/get-practitioners?page=${page}&perPage=${perPage}&search=${search}`)
                    .then(response => {
                        context.commit('SET_USER_PRACTITIONERS', response.data);
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        // pulls practitioner
        setUpPractitionerPage({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios.post('/dashboard/practitioners/setup-practitioner-page/'+ payload)
                .then((res) => {
                    resolve(res)
                    commit('SET_PRACTITIONER_DETAILS', res.data)
                }).catch((e) => {
                    reject(e)
                    console.log(e)
                });
            })
        },
        // Change tab
        changeActiveTab({commit, dispatch}, payload)
        {

            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set('tab', payload);
            const newUrl = window.location.origin + window.location.pathname + '?' + searchParams;
            window.history.pushState({path:newUrl},'',newUrl);

            commit('SET_ACTIVE_TAB', payload);
        },
        setUrlTab({commit}, payload)
        {
            let a_tab = '';
            if (window.location.search.includes('Qualifications')){
                commit('SET_ACTIVE_TAB', 'Qualifications')
            }
        },
        // Set True or False on Publication Modal
        togglePublicationModal({commit})
        {
            commit('SET_PUBLICATION_MODAL');
        },
        // Set True or False on Qualification Modal
        toggleQualificationModal({commit})
        {
            commit('SET_QUALIFICATION_MODAL');
        },
        // Set True or False on Qualification Modal
        toggleAssociationModal({commit})
        {
            commit('SET_ASSOCIATION_MODAL');
        },
        // Save/edit publication
        savePublication({commit, dispatch}, payload)
        {
            // check if form is not empty
            if(payload.data.name && payload.data.url)
            {
                axios.post('/dashboard/practitioners/save-publication', {payload})
                .then(() => {
                    commit('SET_PUBLICATION_MODAL', false);
                    Swal.fire({
                        toast: true,
                        icon: 'success',
                        title: 'Publication Saved',
                        animation: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true
                    }).then(() => {
                            dispatch('commonFunctions/redirectAction', 'Publications', { root: true });
                    })

                }).catch((e) => {
                    Swal.fire({
                        toast: true,
                        icon: 'error',
                        title: e.response.status+' Error.',
                        animation: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true
                    });
                })
            } else {
                // if form is empty throw message
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'Please fill all the mandatory fields',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true
                });
                commit('SET_EMPTY_ERRORS', 'has_empty_mandatory_fields')
                return
            }
        },
        // Save/edit qualification
        saveQualification({commit, dispatch}, payload)
        {
            if(typeof(payload.data) !== 'undefined' && payload.data.length !== 0) {
                // if(payload.data.length !== 0 ){
                    axios.post('/dashboard/practitioners/save-qualification', payload)
                    .then(() => {
                        commit('SET_QUALIFICATION_MODAL');
                        Swal.fire({
                            toast: true,
                            icon: 'success',
                            title: 'Qualification Saved',
                            animation: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true
                        }).then(() => {
                            dispatch('commonFunctions/redirectAction', 'Qualifications', { root: true });

                        })

                    }).catch((e) => {
                        Swal.fire({
                            toast: true,
                            icon: 'error',
                            title: e.response.status+' Error.',
                            animation: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true
                        });
                    })
                // }
            }else {
                // if form is empty throw message
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'Please fill all the mandatory fields',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true
                });
                commit('SET_EMPTY_ERRORS', 'has_empty_mandatory_fields')
                return
            }
        },
        saveNewPractitioner(context, addNewPractitioner) {
          // this in order to give response to the component and there we can use another promise for when this finishes executing
          return new Promise((resolve, reject) => {
            axios.post('/dashboard/practitioners/save-new-practitioner', {
                title: addNewPractitioner.title,
                first_name: addNewPractitioner.first_name,
                last_name: addNewPractitioner.last_name,
              }).then(response => {
                window.location = '/dashboard/practitioners/get-practitioner/'+response.data;
                resolve(response)
              }).catch(error => {
                reject(error)
              })
          })
        },
        // Save/edit association
        saveAssociation({commit, dispatch}, payload)
        {
            if(typeof(payload.data) !== 'undefined' && payload.data.length !== 0) {
                // if(payload.data.length !== 0 ){
                    axios.post('/dashboard/practitioners/save-association', payload)
                    .then(() => {
                        commit('SET_ASSOCIATION_MODAL');
                        Swal.fire({
                            toast: true,
                            icon: 'success',
                            title: 'Association Saved',
                            animation: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 2000,
                            timerProgressBar: true
                        }).then(() => {
                            dispatch('commonFunctions/redirectAction', 'Associations', { root: true });
                        })

                    }).catch((e) => {
                        Swal.fire({
                            toast: true,
                            icon: 'error',
                            title: e.response.status+' Error.',
                            animation: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true
                        });
                    })
                // }
            }else {
                // if form is empty throw message
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: 'Please fill all the mandatory fields',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true
                });
                commit('SET_EMPTY_ERRORS', 'has_empty_mandatory_fields')
                return
            }
        },
        // delete publication
        deletePublication({commit, dispatch}, payload)
        {
            Swal.fire({
                title: "You're deleting this publication",
                text: "Are you sure you want to delete this publication?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
              }).then((result) => {
                if (result.isConfirmed) {
                    axios.delete('/dashboard/practitioners/delete-publication/'+payload)
                    .then(() => {
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                          ).then(() => {
                            dispatch('commonFunctions/redirectAction', 'Publications', { root: true });
                          })
                    })

                }
              }).catch((e) => {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: e.response.status+' Error.',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true
                });
            });
        },
        // delete qualification
        deleteQualification({commit, dispatch}, payload)
        {
            Swal.fire({
                title: "You're deleting this qualification",
                text: "Are you sure you want to delete this qualification?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
              }).then((result) => {
                if (result.isConfirmed) {
                    axios.put('/dashboard/practitioners/delete-qualification/'+payload.deletedId, {
                        practitioner: payload.practitioner
                    })
                    .then(() => {
                        Swal.fire(
                            'Deleted!',
                            'A qualification has been deleted.',
                            'success'
                          ).then(() => {
                            dispatch('commonFunctions/redirectAction', 'Qualifications', { root: true });
                          })
                    })

                }
              }).catch((e) => {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: e.response.status+' Error.',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true
                });
            });
        },
        // delete qualification
        deleteAssociation({commit, dispatch}, payload)
        {
            Swal.fire({
                title: "You're deleting this association",
                text: "Are you sure you want to delete this association?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
              }).then((result) => {
                if (result.isConfirmed) {
                    axios.put('/dashboard/practitioners/delete-association/'+payload.deletedId, {
                        practitioner: payload.practitioner
                    })
                    .then(() => {
                        Swal.fire(
                            'Deleted!',
                            'An association has been deleted.',
                            'success'
                          ).then(() => {
                            dispatch('commonFunctions/redirectAction', 'Associations', { root: true });
                          })
                    })

                }
              }).catch((e) => {
                Swal.fire({
                    toast: true,
                    icon: 'error',
                    title: e.response.status+' Error.',
                    animation: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true
                });
            });
        }
    },
    mutations: {
        SET_USER_PRACTITIONERS(state, practitioners)
        {
            state.practitioners = practitioners
        },
        SET_PRACTITIONER_DETAILS(state, practitioner)
        {
            state.practitioner = practitioner;
            state.tabs = practitioner.tabs;
            // state.active_tab = practitioner.tabs[0]
        },
        SET_ACTIVE_TAB(state, tab)
        {
            state.active_tab = tab
        },
        SET_EMPTY_ERRORS(state, msg)
        {
            state.errors = msg;
        },
        SET_PUBLICATION_MODAL(state)
        {
            if(state.publicationModal == true)
            {
                state.publicationModal = false;

            } else {
                state.publicationModal = true;

            }
        },
        SET_QUALIFICATION_MODAL(state)
        {
            if(state.qualificationModal == true)
            {
                state.qualificationModal = false;

            } else {
                state.qualificationModal = true;

            }
        },
        SET_ASSOCIATION_MODAL(state)
        {
            if(state.associationModal == true)
            {
                state.associationModal = false;

            } else {
                state.associationModal = true;

            }
        },
        SET_ASSOCIATION_LIST(state, associations_list)
        {
            state.associations_list = associations_list
        },
        SET_PRACTITIONER_SATELITES(state, data)
        {
            state.practitioners_satelites = data
        },
        SET_SATELITE_SERVICES(state, data)
        {
            state.satelite_services = data
        }
    },

}
