<template>
    <div>
        <div class="chat-window">
<!--            <div v-for="message in messages" :key="message.id">-->
<!--                <p :class="{ 'user-message': isUser(message.sender_type) }">{{ message.message }}</p>-->
<!--                <button v-if="message.is_payment_request" @click="sendPaymentRequest(message)">Pay Now</button>-->
<!--            </div>-->

            <div v-for="message in messages" :key="message.id">
                <SenderMessage v-if="message.sender_id === loggedInUserId" :message="message"/>
                <ReceiverMessage v-else :message="message"/>
            </div>
        </div>



        <textarea v-model="newMessage"></textarea>
        <button class="btn btn-secondary"  @click.prevent="sendMessage">Send</button>
        <button @click="sendPaymentRequest">Request Payment</button>
    </div>
</template>

<script>
import SenderMessage from "./SenderMessage.vue";
import ReceiverMessage from "./ReceiverMessage.vue";

export default {
    name: 'TestChat',
    components: {ReceiverMessage, SenderMessage},
    data() {
        return {
            messages: [],
            newMessage: '',
        };
    },
    props: ['userId', 'recipientId', 'recipientType', 'conversationId'], // Pass the current user and recipient details
    mounted() {
        this.loadMessages();
        Echo.private(`chat.${this.userId}.${this.recipientId}`)
            .listen('MessageSent', (e) => {
                this.messages.push(e.message);
            });
    },
    methods: {
        loadMessages() {
            axios.get(`/conversations/chat/messages/${this.recipientId}/${this.recipientType}`)
                .then(response => {
                    this.messages = response.data;
                });
        },
        sendMessage() {
            axios.post('/conversations/chat/messages', {
                message: this.newMessage,
                recipient_id: this.recipientId,
                recipient_type: this.recipientType,
                conversation_id: this.conversationId
            }).then(response => {
                this.messages.push(response.data);
                this.newMessage = '';
            });
        },
        sendPaymentRequest() {
            axios.post('/api/chat/messages', {
                message: 'Payment request',
                recipient_id: this.recipientId,
                recipient_type: this.recipientType,
                is_payment_request: true
            }).then(response => {
                this.messages.push(response.data);
            });
        },
        isUser(senderType) {
            return senderType === 'user';
        }
    }
};
</script>
