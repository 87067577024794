<template>
  <div class="bg-white rounded-lg shadow">
      <div class=" pb-0 p-3">
          <div class="row">
              <div class="col-6 d-flex align-items-center">
                  <h6 class="mb-0">Billing Address</h6>
              </div>
              <div class="col-6 text-end">
                  <MediButton color="dark" variant="gradient" @buttonClicked="$emit('buttonClicked')">
                      <i class="fas fa-plus me-2"></i>
                      Update
                  </MediButton>
              </div>
          </div>
      </div>
      <div class="p-3 pt-0 card-body">
          <div class="mb-0">
              <slot></slot>
          </div>
      </div>
  </div>
</template>

<script>
import MediButton from "./MediButton.vue";

export default {
  name: "DefaultInfoCard",
    components: {MediButton},
  props: {
  },
};
</script>
