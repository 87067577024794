<template>
    <div class="container">
        <div class="flex">
            <button @click.prevent="openUpdateClinicModal('addPractitioner')" class="ml-auto p-2 rounded text-white font-bold bg-[#0BD2A4] mb-3">Add New Practitioner</button>
        </div>
        <table-framework
            :theads="theads"
            :tableTitle="'Your Practitioners'"
            :showTableSearch="true"
            :tableSearchText="searchInput"
            @textSearch="textSearch"
            :showTableActionButton="false"
            :filteredOptions="filteredPractitioners"
            :paginationEnabled="true"
            :previousPaginationButtonDisabled="previousPaginationButtonDisabled"
            :nextPaginationButtonDisabled="nextPaginationButtonDisabled"
            :paginationCurrentPage="currentPage"
            :paginationPerPage="perPage"
            :paginationTotalResults="totalPractitioners"
            @changePage="changePage"
        >

            <tbody slot="table-body"  v-if="filteredPractitioners.length > 0">
            <tr
                v-for="(practitioner, index) in filteredPractitioners"
                :key="practitioner.id"
                :class="{
                    'border-b': true,
                    'dark:border-gray-700': index !== filteredPractitioners.length - 1,
                    'hover:bg-gray-30': true,
                    'dark:hover:bg-gray-600': true,
                    }"
            >

                <td scope="row" class="py-2 px-6  whitespace-nowrap" >
                    <img class="roundImage ml-2 w-50 bg-light" :src="practitioner.image_path" />
                </td>
                <td scope="row" class="py-4 px-6  whitespace-nowrap capitalize" >
                    {{practitioner.title}} {{practitioner.first_name}} {{practitioner.middle_name}} {{practitioner.last_name}} {{practitioner.known_as ? '('+ practitioner.known_as + ')' : ''}}
                </td>
                <td scope="row" class="py-4 px-6  whitespace-nowrap capitalize" >
                    {{practitioner.profession}}
                </td>
                <td scope="row" class="py-4 px-6  whitespace-nowrap capitalize" >
                    {{practitioner.gender}}
                </td>
                <td class="py-4 px-6 my-auto">
                    <a class="mx-2 btn btn-sm" role="button" @click.prevent="grabPractitioner(practitioner.ref);">View/Edit</a>
                    <button @click.prevent="openDeleteModal(practitioner.id)" v-if="practitioners.length > 1" class="mx-2" name="Delete Service"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5" style="color:red">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                    </svg>
                    </button>
                    <update-create-clinic-modal class="text-left" :practitioner="practitioner" :chosenModal="chosenModal" v-if="updateClinicModal"></update-create-clinic-modal>
                    <DeleteClinicOrPractitionerModal v-if="deleteModal"  :title="'Delete a practitioner'" :deleteType="'removePractitioner'" :idToDelete="idToDelete" @close="deleteModal = false" />
                </td>
            </tr>
            </tbody>
            <div class="mt-5" v-if="filteredPractitioners.length === 0">
                <p>You don't have any practitioner yet.</p>
            </div>
        </table-framework>
        <UpdateProfileImagesModal @closeModal="closeModal" v-if="UpdateProfileImagesModal" :idConcerned="idConcerned" :pictureConcerned="practitionerImagePath" :chosenModal="modalId"/>
    </div>
</template>


<script>
import { mapActions } from 'vuex';
import allPractitionersTableHeads from '../../../constants/satelites/allPractitionersTableHeads';
import UpdateCreateClinicModal from '../Clinics/UpdateCreateClinicModal';
import UpdateProfileImagesModal from '../Clinics/UpdateProfileImagesModal.vue';
import DeleteClinicOrPractitionerModal from '../Clinics/DeleteClinicOrPractitionerModal.vue';
import DataTable from "../../Utilities/DataTable.vue";
import InputToggle from "../Clinics/TabComponents/InputToggle.vue";
export default {
    props: [],
    components: {
        InputToggle,
        DataTable,
        UpdateCreateClinicModal,
        UpdateProfileImagesModal,
        DeleteClinicOrPractitionerModal
    },
    data()
    {
        return {
            theads: ['picture', 'name', 'profession', 'gender', 'actions'],
            deleteModal: false,
            updateClinicModal: false,
            chosenModal: '',
            practitioners: [],
            practitionerImagePath: '',
            modalId: '',
            idConcerned: '',
            idToDelete: '',
            addPractitionerModal: false,
            UpdateProfileImagesModal: false,
            currentPage: 1,
            totalPractitioners: 0,
            perPage: 10,
            filteredPractitioners: [],
            searchInput: '',
            nextPaginationButtonDisabled: false,
            previousPaginationButtonDisabled: true,
        }
    },
    methods:
    {
        ...mapActions('practitioner', ['getPractitioners']),
        ...mapActions('mainClinic', ['getUserSateliteClinics']),
        openUpdateClinicModal(modalName)
        {
            this.chosenModal = modalName
            this.updateClinicModal = true
        },
        async loadPractitioners(page = 1)
        {
             await this.getPractitioners({ page, perPage: this.perPage, search: this.searchInput })
                .then(async res => {
                    this.practitioners = await res.data.data; // Adjust based on your API response structure
                    this.filteredPractitioners = res.data.data;
                    this.totalPractitioners = res.data.total; // Total practitioners count
                    this.currentPage = page; // Update current page
                }).catch(error => {
                console.log(error);
            });
        },
        changePage(page) {
            if(page === -1)
            {
                if(this.currentPage !== 1)
                {
                    this.loadPractitioners(this.currentPage - 1);
                    this.currentPage -= 1;
                }
            } else {
                this.loadPractitioners(this.currentPage + 1);
                this.currentPage += 1;

            }

            this.previousPaginationButtonDisabled = this.currentPage === 1;

            if(this.currentPage === (Math.ceil(this.totalPractitioners/this.perPage)))
            {
                this.nextPaginationButtonDisabled = true;
            }


        },
        grabPractitioner(id)
        {
            window.location.href="/dashboard/practitioners/get-practitioner/"+id;
        },
        openUpdateModal(practitionerImagePath, idConcerned){
            this.UpdateProfileImagesModal = true
            this.practitionerImagePath = practitionerImagePath
            this.idConcerned = idConcerned
            this.modalId = 'updatePractitionerImage'
        },
        closeModal(){
            this.updateCreateClinicModal = false
        },
        openDeleteModal(idConcerned) {
            this.idToDelete = idConcerned
            this.deleteModal = true
        },
        textSearch(Searchtext)
        {
            this.searchInput = Searchtext;
            this.loadPractitioners()
        },
    },
    mounted()
    {
        this.getUserSateliteClinics();
        this.loadPractitioners();
    }
}
</script>

<style lang="scss" scoped>
.roundImage{
    width: 50px  !important;
}
</style>
