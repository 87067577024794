<style scoped lang="scss">
    .location-map {

        position: relative;
       // height: 265px!important;
    }

    #map {
        position: absolute;
        top: 0;
        left: 0;
        width: 90%;
        height: 210px!important;
    }

    .mapboxgl-canvas-container {
        height: 100%!important;
        width: 100%!important;
        border-radius: 15px!important;
    }

    .mapboxgl-ctrl-bottom-right{
        display: none!important;
    }

    .mapboxgl-canvas {
        border-radius: 15px!important;
    }
</style>

<template>
    <div class="bg-white shadow-sm p-4" style="border-radius: 15px;">
        <div class="flex">
            <div class="location-details w-full mr-3">
                <div class="flex my-3">
                    <div class="table-headings w-1/2 my-auto">
                        <p class="mb-0 font-bold">City <span class="text-red-400">*</span></p>
                    </div>
                    <div class="table-input w-1/2">
                        <input class="" v-model="location.city" type="text" @change="save(['saveClinicCity', clinic.id, location.city])">
                        <p v-if="GET_ERRORS" class="text-red-400 font-sm">* {{ GET_ERRORS }}</p>

                    </div>
                </div>
                <div class="flex my-3">
                    <div class="table-headings w-1/2 my-auto">
                        <p class="mb-0 font-bold">Postcode <span class="text-red-400">*</span></p>
                    </div>
                    <div class="table-input w-1/2">
                        <input class="" v-model="location.postcode"  type="text" @change="formatPostcode(location.postcode); save(['saveClinicPostcode', clinic.id, location.postcode])">
                        <p v-if="GET_ERRORS" class="text-red-400 font-sm">* {{ GET_ERRORS }}</p>

                    </div>
                </div>
                <div class="flex my-3">
                    <div class="table-headings w-1/2 my-auto">
                        <p class="mb-0 font-bold">Maximum Radius Range <span class="text-red-400">*</span></p>
                    </div>
                    <div class="table-input w-1/2 flex my-auto">

                        <dropdown
                          :options="mileRanges"
                          :mappings="milesMappings"
                          v-model="location.max_radius_range"
                          style="width: 80px!important"
                          @input="save(['saveClinicMaxMilesRadius', clinic.id, location.max_radius_range])"
                          >

                        </dropdown>
                        <p class="my-auto ml-4">Mile<span v-if="location.max_radius_range>1">s</span></p>
                    </div>
                </div>
                <p class="mt-4">Your details will be saved automatically</p>
            </div>
            <div class="location-map w-full h-100 ml-3">
                <div id="map" class="my-2 mx-5" style="border-radius: 15px;"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import RadiusSelection from '../../../constants/milesRadiusSelection.js';
import mapboxgl from 'mapbox-gl';

mapboxgl.accessToken = 'pk.eyJ1IjoibWVkaW1vYmNsaW5pY3MiLCJhIjoiY2xlYnpxZ3VsMWV4MTN5cXg4eW5ueTVndCJ9.rUWK6NsXGGlAxswGX1gGWg';


export default {
    props: ['clinic'],
    data() {
        return {
            mileRanges: [],
            location: {
                city: this.clinic.city,
                postcode: this.clinic.postcode,
                max_radius_range: this.clinic.max_miles_radius,
            },
            coords: [Number(this.clinic.lon), Number(this.clinic.lat)],
        }
    },
    computed: {
        ...mapGetters('satelite', ['GET_ERRORS']),
        ...mapGetters('commonFunctions', ['GET_POSTCODE']),
        milesMappings() {
            return this.mileRanges.reduce((mappings, index) => {
              mappings[index] = index;
              return mappings;
            }, {});
        },

    },
    methods: {
        ...mapActions('satelite', ['save']),
        ...mapActions('commonFunctions', ['formatPostcodeAction']),
        formatPostcode(postcode)
        {
          // calling the postcode action from vuex and assigning the value got from the Getter to the local variable
          this.formatPostcodeAction(postcode)
          this.location.postcode = this.GET_POSTCODE

        },
    },
    mounted()
    {
      this.mileRanges = RadiusSelection;
        const map = new mapboxgl.Map({
                container: "map",
                style: "mapbox://styles/mapbox/streets-v12",
                center: this.coords,
                zoom: 8,
            });

        const clearances = {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: this.coords
                },
                properties: {
                  clearance: "13' 2"
                }
              }
            ]
        }

        const obstacle = turf.buffer(clearances, this.clinic.max_miles_radius, { units: 'miles' });
        const bbox = [0, 0, 0, 0];
        const polygon = turf.bboxPolygon(bbox);

        map.on('load', () => {
            map.resize();
            map.addLayer({
                id: 'clearances',
                type: 'fill',
                source: {
                    type: 'geojson',
                    data: obstacle
                },
                layout: {},
                paint: {
                    'fill-color': '#0BD2A4',
                    'fill-opacity': 0.5,
                    'fill-outline-color': '#0BD2A4'
                }
            });
        });

    }

}
</script>
