<style lang="scss" scoped>
.table-framework {
    box-shadow: 0 3px 5px 0 rgba(0,0,0,.2) !important;
    background-color: #ffff!important;
    border-radius: 15px;
    // border: 1px solid #344767;
}
.pointerCursor{

  cursor: pointer;
}
body {
  color: #344767;
}
.table-title{
  color: #344767;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.thead-style {
  color: rgba(52, 71, 103, 0.50);
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.image-td{
  padding: 20px;
}
</style>

<style lang="scss">

th {
  color: rgba(52, 71, 103, 0.70) !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

td {
  color: rgba(52, 71, 103, 0.70) !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}
.roundImage{
    width: 50px  !important;
    border-radius: 50px;
}

.search-input {
  border-radius: 5px !important;
  border: 1px solid rgba(52, 71, 103, 0.50) !important;
  line-height: 17px !important;
}

.checkbox-toggle {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 0.5px solid #344767;
  background: #FFF;
  cursor: pointer;
  margin-left: 20px;
}

.table > tbody{
  vertical-align: middle !important;
}
</style>
<template>
    <div class="mb-4 table-framework shadow-sm">
      <div class="table-card-header p-4 flex">
        <span class="table-title flex-grow my-auto opacity-80">{{ tableTitle }}</span>

        <div :class="showTableToggle && 'grid grid-cols-1 md:grid-cols-2'">
          <div class="table-action flex">
              <input v-if="showTableSearch" type="text" v-model="searchText" class="search-input border mb-2 p-2 mr-2 rounded-md" placeholder="Search"/>
              <button v-if="showTableActionButton" class="px-3 py-2 capitalize text-white my-auto bg-[#0EE2B1] rounded" @click.prevent="$emit('tableAction')">{{ tableButtonText }}</button>
          </div>

          <div v-if="showTableToggle" class="flex justify-end items-center">
            <span v-if="!allSelected" class="mb-2 mr-3 font-medium">Select All</span>
            <span v-else class="mb-2 mr-3 font-medium">Deselect All</span>
            <label class="relative flex items-center cursor-pointer">
              <input type="checkbox" v-model="togglee" @change="$emit('onToggleChange', togglee)" class="sr-only peer">
              <div class="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            </label>
          </div>
        </div>


      </div>
      <div class="table-card-body px-0 pt-0 pb-2">
        <div class="table-responsive p-0">
          <table class="table align-items-center justify-content-center mb-0">
            <thead>
              <tr>
                <th
                  class="thead-style opacity-7 px-2 py-2 capitalize"

                  v-for="thead in theads"
                >
                  <span style="display: -webkit-inline-box;" @click="$emit('whichHead', thead.title)" v-if="typeof thead.title !== 'undefined' " :class="thead.sortable && 'pointerCursor'">
                    {{thead.title}}

                    <svg v-if="thead.sortable && orderAscOrDesc[thead.var] == 1" xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                    </svg>

                    <svg v-else-if="thead.sortable && orderAscOrDesc[thead.var] == 2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
                    </svg>
                  </span>
                  <span v-else>{{ thead }}</span>

                </th>
              </tr>
            </thead>
            <slot name="table-body"></slot>
          </table>
            <!-- Pagination controls -->
            <div class="pagination w-full flex items-center justify-between px-4" v-if="paginationEnabled">
                <button
                    @click="$emit('changePage', -1)"
                    :disabled="previousPaginationButtonDisabled"
                    class="pagination-button">
                    Previous
                </button>

                <span class="pagination-info">
                    Page {{ paginationCurrentPage }} of {{ Math.ceil(paginationTotalResults / paginationPerPage) }}
                </span>

                <button
                    @click="$emit('changePage', +1)"
                    :disabled="nextPaginationButtonDisabled"
                    class="pagination-button">
                    Next
                </button>
            </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  export default {
    name: "table-framework",
    data(){
      return {
        searchText: '',
        togglee: false
      }
    },
    props: {
        orderAscOrDesc: {
          type: Object,
          required: false
        },
        tableTitle: {
            type: String,
            required: true
        },
        showTableActionButton: {
            type: Boolean,
            required: true
        },
        showTableSearch: {
          type: Boolean,
          required: false
        },
        tableSearchText: {
          type: String,
          required: false
        },
        tableButtonText: {
            type: String,
            required: false
        },
        theads: {
            type: Array,
            required: true
        },
        tableAction: {
            type: String,
            required: false
        },
        allSelected: {
            type: Boolean,
            required: false
        },
        toggle: {
            type: Boolean,
            required: false
        },
        selectedIds: {
            type: Array,
            required: false
        },
        filteredOptions: {
            type: Array|Object,
            required: false
        },
        showTableToggle: {
            type: Boolean,
            required: false
        },
        paginationEnabled:  {
            type: Boolean,
            required: false
        },
        previousPaginationButtonDisabled: {
            type: Boolean,
            required: false
        },
        nextPaginationButtonDisabled: {
            type: Boolean,
            required: false
        },
        paginationCurrentPage: {
            type: Number,
            default: 1
        },
        paginationTotalResults: {
            type: Number,
            default: 0
        },
        paginationPerPage: {
            type: Number,
            default: 0
        }
    },
    components: {
    },
    methods: {
        triggerTableAction(tableAction)
        {

        }
    },
    watch: {
      orderAscOrDesc: {
        handler(e){
          console.log(e)
        }
      },
      searchText: {
        handler(e){
          this.$emit('textSearch', this.searchText)
        }
      },
      toggle: {
        handler(e){
          if(typeof(this.toggle) != 'undefined') {
            this.togglee = this.toggle
          }
        }
      }
    }
  };
  </script>

  <style lang="scss" scoped>
.roundImage{
    width: 50px  !important;
}
.form-check-label {
    font-weight: bold;
    text-transform: uppercase;
  }
  .form-check-input:checked {
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
  }
  .form-check-input:checked + .form-check-label {
    color: #0d6efd !important;
  }

.pagination {
    padding: 1rem 0;
    border-top: 1px solid #e0e0e0; /* Optional top border */
}

.pagination-button {
    padding: 0.5rem 1rem;
    background-color: #0BD2A4; /* Primary color */
    color: white;
    border: none;
    border-radius: 0.375rem; /* Rounded button */
    transition: background-color 0.2s;
}

.pagination-button:disabled {
    background-color: #d0d0d0; /* Disabled button color */
    cursor: not-allowed;
}

.pagination-button:hover:not(:disabled) {
    background-color: #0056b3; /* Hover color */
}

.pagination-info {
    font-weight: 500;
    color: #333; /* Info text color */
}
</style>
