<template>
    <div class="data-table">
        <!-- Search bar, conditionally rendered -->
        <div v-if="searchEnabled" class="data-table-search">
            <input
                type="text"
                v-model="searchQuery"
                placeholder="Search..."
                @input="fetchData"
            />
        </div>

        <!-- Table -->
        <table>
            <thead>
            <tr>
                <!-- Multiselect column, if enabled -->
                <th v-if="multiselectEnabled">
                    <input
                        type="checkbox"
                        @change="toggleSelectAll"
                        :checked="allSelected"
                    />
                </th>
                <th v-for="column in columns" :key="column.key">{{ column.label }}</th>
                <th v-if="actions.length">Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="row in rows" :key="row.id">
                <!-- Multiselect column, if enabled -->
                <td v-if="multiselectEnabled">
                    <input
                        type="checkbox"
                        :value="row.id"
                        v-model="selectedRows"
                    />
                </td>
                <td v-for="column in columns" :key="column.key">{{ row[column.key] }}</td>
                <td v-if="actions.length">
                    <button
                        v-for="action in actions"
                        :key="action.label"
                        @click="() => action.handler(row)"
                    >
                        {{ action.label }}
                    </button>
                </td>
            </tr>
            </tbody>
        </table>

        <!-- Pagination controls -->
        <div class="pagination" v-if="paginationEnabled">
            <button @click="fetchData(currentPage - 1)" :disabled="currentPage === 1">Previous</button>
            <span>Page {{ currentPage }} of {{ totalPages }}</span>
            <button @click="fetchData(currentPage + 1)" :disabled="currentPage === totalPages">Next</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DataTable',
    props: {
        actions: {
            type: Array,
            default: () => [],
        },
        multiselectEnabled: {
            type: Boolean,
            default: false,
        },
        searchEnabled: {
            type: Boolean,
            default: false,
        },
        paginationEnabled: {
            type: Boolean,
            default: true,
        },
        apiUrl: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            rows: [],
            currentPage: 1,
            totalPages: 1,
            searchQuery: '',
            selectedRows: [],
            columns: [],
            totalEntries: 0,
            perPage: 10,
        };
    },
    computed: {
        allSelected() {
            return this.selectedRows.length === this.rows.length;
        },
    },
    methods: {
        async fetchData(page = 1) {
            try {
                const response = await fetch(
                    `${this.apiUrl}?page=${page}&perPage=${this.perPage}&search=${this.searchQuery}`
                );
                const data = await response.json();
                this.rows = data.data;
                this.currentPage = data.current_page;
                this.totalPages = data.last_page;
                this.columns = Object.keys(data.data[0]).filter(col => col !== "actions");
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        },
        toggleSelectAll() {
            if (this.allSelected) {
                this.selectedRows = [];
            } else {
                this.selectedRows = this.rows.map(row => row.id);
            }
        },
    },
    watch: {
        searchQuery: {
            handler: "fetchData",
            immediate: true,
        },
    },
    mounted() {
        this.fetchData();
    },
};
</script>

<style scoped>
.data-table {
    /* Add your styling here */
}
.data-table-search {
    margin-bottom: 1rem;
}
.pagination {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
}
</style>
