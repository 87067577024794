<template>
  <div class="card mt-4 shadow bg-white rounded-lg">
    <div class=" pb-0 p-3">
      <div class="row">
        <div class="col-6 d-flex align-items-center">
          <h6 class="mb-0">Payment Methods</h6>
        </div>
        <div class="col-6 text-end">
          <MediButton color="dark" variant="gradient" @buttonClicked="toggleEditMode">
            <i class="fas fa-plus me-2"></i>
            {{buttonText}}
          </MediButton>
        </div>
      </div>
    </div>
    <div class="card-body p-3">
      <div class="row">
        <div class="mb-md-0 mb-4">
          <div
              v-if="!editMode"
            class="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row"
          >
            <img
              class="w-10 me-3 mb-0"
              :src="cardBrand"
              alt="logo"
            />
            <h6 class="mb-0">
              ****&nbsp;&nbsp;&nbsp;****&nbsp;&nbsp;&nbsp;****&nbsp;&nbsp;&nbsp;{{card.last4}}
            </h6>
            <i
              class="fas fa-pencil-alt ms-auto text-dark cursor-pointer"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title
              aria-hidden="true"
              data-bs-original-title="Edit Card"
              aria-label="Edit Card"
            ></i>
            <span class="sr-only">Edit Card</span>
          </div>
            <div
                v-else
                class="card card-body border card-plain border-radius-lg d-flex align-items-center flex-row"
            >
                <StripeCardCapture :stripe_key="stripe_key" :buttonText="'Save Payment Method'"/>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mastercard from "../../../../public/images/logos/mastercard.png";
import visa from "../../../../public/images/logos/visa.png";
import amex from "../../../../public/images/logos/amex.png";
import MediButton from "./MediButton.vue";
import StripeCardCapture from "./StripeCardCapture.vue";
import {mapState} from "vuex";

export default {
    name: "PaymentMethodsCard",
    props: {
        card: {
            type: Object | Array,
            default: () => ({
                last4: '1234',
                type: 'mastercard'
            })
        },
        stripe_key: {
            type: String
        }
    },
    data()
    {
          return {
              editMode: false,
              buttonText: 'Add New Card'
          }
    },
    computed: {
        ...mapState('account', ['user_has_card_on_system']),
        cardBrand()
          {
              switch (this.card.type) {
                  case 'mastercard':
                    return mastercard;
                  case 'visa':
                      return visa;
                  case 'amex':
                      return amex;
                  default:
                      return mastercard
              }
          }
    },
    components: {
        StripeCardCapture,
        MediButton,
    },
    methods: {
          toggleEditMode()
          {
              if(!this.editMode)
              {
                  this.editMode = true;
                  this.buttonText = 'Keep Old Card'
              } else {
                  this.editMode = false;
                  this.buttonText = 'Add New Card'


              }
          }
    }
};
</script>
