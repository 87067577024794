<template>
    <div class="container">
      <div class="flex">
        <button @click.prevent="goToPractitioner" class="ml-auto p-2 rounded text-white font-bold bg-[#0BD2A4] mb-3">Add New Practitioner</button>
      </div>
      <table-framework
            :theads="theads"
            :tableTitle="'Your Practitioners'"
            :showTableSearch="true"
            :showTableToggle="true"
            :tableSearchText="searchInput"
            @textSearch="textSearch"
            :showTableActionButton="false"
            :allSelected="allSelected"
            :toggle="toggle"
            :filteredOptions="filteredPractitioners"
            :selectedIds="selectedIds"
            @onToggleChange="onToggleChange"
            :paginationEnabled="true"
            :previousPaginationButtonDisabled="previousPaginationButtonDisabled"
            :nextPaginationButtonDisabled="nextPaginationButtonDisabled"
            :paginationCurrentPage="currentPage"
            :paginationPerPage="perPage"
            :paginationTotalResults="totalPractitioners"
            @changePage="changePage"
        >

            <tbody slot="table-body"  v-if="filteredPractitioners.length > 0">
                <tr
                    v-for="(practitioner, index) in filteredPractitioners"
                    :key="practitioner.id"
                    :class="{
                    'selectedRow': selectedIds.includes(practitioner.ref),
                    'bg-white': !selectedIds.includes(practitioner.ref),
                    'border-b': true,
                    'dark:border-gray-700': index !== filteredPractitioners.length - 1,
                    'hover:bg-gray-30': true,
                    'dark:hover:bg-gray-600': true,
                    }"
                >
                    <th class="ml-2 py-4 px-6  whitespace-nowrap">
                        <InputToggle :selectedIds="selectedIds" :element="practitioner.ref" @toggleSelected="toggleSelected" />
                    </th>
                    <th scope="row" class="py-2 px-6  whitespace-nowrap" >
                        <img class="roundImage ml-2 w-50 bg-light" :src="practitioner.image_path" />
                    </th>
                    <th scope="row" class="py-4 px-6  whitespace-nowrap" >
                        {{practitioner.first_name}} {{practitioner.middle_name}} {{practitioner.last_name}}
                    </th>
                    <th scope="row" class="py-4 px-6  whitespace-nowrap" >
                        {{practitioner.ref}}
                    </th>
                    <th scope="row" class="py-4 px-6  whitespace-nowrap" >
                        {{practitioner.known_as}}
                    </th>
                </tr>
            </tbody>
            <div class="mt-5" v-if="filteredPractitioners.length === 0">
              <p>You don't have any practitioner yet.</p>
            </div>
      </table-framework>
    </div>
</template>


<script>
import TableFramework from '../../../Table.vue';
import InputToggle from './InputToggle.vue';
import practitionerToClinicTableHead from '../../../../constants/services/practitionerToClinicTableHead';
import { mapActions } from 'vuex';
export default {
    components: {TableFramework, InputToggle},
    props: ['clinicRef'],
    data()
    {
        return {
            theads: practitionerToClinicTableHead,
            selectedIds: [],
            practitioners: [],
            filteredPractitioners: [],
            addPractitionerModal: false,
            toggle: false,
            searchInput: '',
            currentPage: 1,
            totalPractitioners: 0,
            perPage: 10,
            nextPaginationButtonDisabled: false,
            previousPaginationButtonDisabled: true,
        }
    },
    methods:
    {
        ...mapActions('practitioner', ['getPractitioners']),
        ...mapActions('satelite', ['setAssociatedPractitioners', 'getAssociatedPractitioners']),
        ...mapActions('mainClinic', ['getUserSateliteClinics']),
        textSearch(Searchtext)
        {
            this.searchInput = Searchtext;
            this.loadPractitioners()
        },
        async loadPractitioners(page = 1)
        {
            await this.getPractitioners({ page, perPage: this.perPage, search: this.searchInput })
                .then(async res => {
                    this.practitioners = await res.data.data; // Adjust based on your API response structure
                    this.filteredPractitioners = res.data.data;
                    this.totalPractitioners = res.data.total; // Total practitioners count
                    this.currentPage = page; // Update current page
                }).catch(error => {
                    console.log(error);
                });
        },
        toggleSelected(id) {
            if (this.selectedIds.includes(id)) {
                // Remove id from selected ids array if it's already selected
                this.selectedIds = this.selectedIds.filter((selectedId) => selectedId !== id);
            } else {
                // Add id to selected ids array if it's not already selected
                if (!Array.isArray(this.selectedIds)) {
                this.selectedIds = [];
                }
                // Add id to selected ids array if it's not already selected
                this.selectedIds.push(id);
            }
            this.setAssociatedPractitioners({
                ref: this.clinicRef,
                practitionersList: this.selectedIds
            })
        },
        onToggleChange(toggle) {
            if(toggle) {
                this.selectedIds = this.filteredPractitioners.map(practitioner => practitioner.ref);
            }else {
                this.selectedIds = [];
            }
            this.setAssociatedPractitioners({
                ref: this.clinicRef,
                practitionersList: this.selectedIds
            })
        },
        goToPractitioner()
        {
          window.location.href= '/dashboard/practitioners';
        },
        changePage(page) {
            if(page === -1)
            {
                if(this.currentPage !== 1)
                {
                    this.loadPractitioners(this.currentPage - 1);
                    this.currentPage -= 1;
                }
            } else {
                this.loadPractitioners(this.currentPage + 1);
                this.currentPage += 1;
            }
            this.previousPaginationButtonDisabled = this.currentPage === 1;
            if(this.currentPage === (Math.ceil(this.totalPractitioners/this.perPage)))
            {
                this.nextPaginationButtonDisabled = true;
            }
        },
    },
    watch: {
        searchInput: {
            handler(newValue) {
                this.filteredPractitioners = this.practitioners
                this.filteredPractitioners = this.practitioners.filter(practitioner => {
                const searchLower = this.searchInput.toLowerCase();
                const hasName = practitioner.first_name && practitioner.first_name.toLowerCase().includes(searchLower);
                const hasLName = practitioner.last_name && practitioner.last_name.toLowerCase().includes(searchLower);
                const hasMName = practitioner.middle_name && practitioner.middle_name.toLowerCase().includes(searchLower);
                const hasRef = practitioner.ref && practitioner.ref.toLowerCase().includes(searchLower);
                const hasKnownAs = practitioner.known_as && practitioner.known_as.toLowerCase().includes(searchLower);

                return hasName || hasLName || hasMName || hasRef || hasKnownAs;
                });

            }
        }
    },
    mounted()
    {
        this.getUserSateliteClinics();
        this.loadPractitioners();
    },
    created()
    {
        this.getAssociatedPractitioners(this.clinicRef)
        .then(response => {
            this.selectedIds = response.data
        }).catch(err => {
            console.log(err)
        })
    },
    computed: {
        allSelected() {
            if(this.selectedIds.length === this.filteredPractitioners.length){
                this.toggle = true
            }else {
                this.toggle = false
            }
            return this.selectedIds.length === this.filteredPractitioners.length;
        },
    }
}
</script>

<style lang="scss" scoped>
.roundImage{
    width: 50px  !important;
}
.form-check-label {
    font-weight: bold;
    text-transform: uppercase;
  }
  .form-check-input:checked {
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
  }
  .form-check-input:checked + .form-check-label {
    color: #0d6efd !important;
  }
</style>
