<style scoped lang="scss">
    .active-tab {
        background-color: #d2f7efb3;
        color:  #0EE2B1;
    }
</style>

<template>
    <div>
        <div class="pb-4" style="margin-top: -130px; position: fixed; width: 90%; z-index: 100;">
            <dashboard-info-topbar
                :id="clinic.id"
                :image="clinic.image_path"
                @openUpdateModal="openUpdateModal"
                :title="clinic.name"
                :description="clinic.city+' ('+clinic.postcode+')'"
                :lastUpdated="clinic.updated_at"
            ></dashboard-info-topbar>
        </div>
        <div class="container mt-5 overflow-hidden">

            <div class="main-section mt-5">
                <div class="tab-wrapper my-5">
                    <div class="tab-section overflow-x-scroll">
                        <ul class="flex p-0">
                            <li v-for="tab in tabs" :key="tab" class="mr-4 px-4 py-2 cursor-pointer rounded-lg" :class="{'active-tab': GET_ACTIVE_TAB == tab}" @click="changeActiveTab(tab)">{{tab}}</li>
                        </ul>
                    </div>
                </div>

                <div class="inner-page">
                    <div v-if="loading">
                        <component :clinicRef="clinic.ref" :is="pageComponent" v-if="clinic" :clinic="clinic"></component>
                    </div>
                </div>
            </div>
        </div>
        <UpdateProfileImagesModal @closeModal="closeModal" v-if="UpdateProfileImagesModal" :idConcerned="idConcerned" :pictureConcerned="ClinicImagePath" :chosenModal="modalId"/>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations} from 'vuex';
import Breadcrumbs from '../../Breadcrumbs.vue';
import DashboardInfoTopbar from '../DashboardInfoTopbar.vue';
import SateliteTabs from '../../../constants/satelites/sateliteTabs.js';
import EditProfileSingleClinic from './EditProfileSingleClinic.vue';
import AssociatedPractitionersTable from './AssociatedPractitionersTable.vue';
import UpdateProfileImagesModal from './UpdateProfileImagesModal.vue'
import EditLocationSingleClinic from './EditLocationSingleClinic.vue'
import AssociatedServices from './AssociatedServices.vue'
import OnlineBooking from './OnlineBooking.vue'
import SingleClinicAnalytics from './SingleClinicAnalytics.vue'
import HistoryLogs from './HistoryLogs.vue';
export default {
    props: ['clinic'],
    components: {
        DashboardInfoTopbar,
        Breadcrumbs,
        EditProfileSingleClinic,
        AssociatedPractitionersTable,
        UpdateProfileImagesModal,
        EditLocationSingleClinic,
        AssociatedServices,
        OnlineBooking,
        SingleClinicAnalytics,
        HistoryLogs

    },
    data() {
        return {
            crumbs: [
                {name: 'Clinic', url: '/dashboard/clinic'},
                {name: 'View & Edit', url: '/dashboard/clinic'},
                {name: this.clinic.name+' ('+this.clinic.postcode+')' , url: ''},
            ],
            tabs: SateliteTabs,
            ClinicImagePath: '',
            modalId: '',
            idConcerned: '',
            UpdateProfileImagesModal: false,
            active_tab: this.GET_ACTIVE_TAB,
            activePageComponent: null,
            loading: true
        }
    },
    computed: {
        ...mapGetters('satelite', ['GET_ACTIVE_TAB']),
        pageComponent()
        {
            switch (this.GET_ACTIVE_TAB) {
                case 'Profile':
                    return EditProfileSingleClinic;
                case 'Location':
                    return EditLocationSingleClinic;
                case 'Practitioners':
                    return AssociatedPractitionersTable;
                case 'Services':
                    return AssociatedServices;
                case 'Online Booking':
                    return OnlineBooking;
                case 'Analytics':
                    return SingleClinicAnalytics;
                case 'History Logs':
                    return HistoryLogs;
                default:
                    return EditProfileSingleClinic;
            }
        }
    },
    methods: {
        ...mapMutations('satelite', ['SET_ACTIVE_TAB']),
        ...mapActions('commonFunctions', ['loadBreadcrumbs']),
        ...mapActions('satelite', ['changeActiveTab', 'setClinicPage']),
        ...mapActions('log', ['setLogs']),
        closeModal(){
            this.UpdateProfileImagesModal = false
        },
        openUpdateModal(ClinicImagePath, idConcerned){
            this.UpdateProfileImagesModal = true
            this.ClinicImagePath = ClinicImagePath
            this.idConcerned = idConcerned
            this.modalId = 'updateSatelliteClinicLogo'
        }
    },
    mounted() {
      //  this.setUpPractitionerPage(this.practitioner.ref);
        this.loadBreadcrumbs(this.crumbs);
        this.setClinicPage();

        const searchParams = new URLSearchParams(window.location.search);
        const tab = searchParams.get('tab') || 'Profile';
        this.SET_ACTIVE_TAB(tab);
        this.setLogs({type: 'satelite', data: this.clinic});

    }
}
</script>
