<template>
<div class="container-fluid">
    <div class=" flex">
        <div class="left-container-img-box left w-full">
            <img class="background-patterns" src="../../../../../public/images/curved14.jpg" alt="medimob-background-wave-pattern">
            <div class="flex h-full">
                <div class="w-full img-bg-section rounded-md my-auto flex-col">
                    <div class="left-section-title-wrapper">
                        <h4>Welcome To Medimob</h4>
                    </div>
                    <div class="left-section-description-wrapper">
                        <p>Patient Registration Form</p>
                    </div>
                    <div class="left-section-description-wrapper">
                        <hr>
                        <p>Already have an account?</p>
                        <p><a href="/patient/login" class="text-white font-semibold">Sign In</a></p>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!loadingRegister" class="right w-full flex">
            <div class="stepper-header tabs flex w-full scroll-x my-auto">
                <div class="mx-auto">
                    <h4 class="text-slate-800 font-bold text-center">Patient's Registration Form</h4>
                    <div class="card">
                         <div class="card-body">
                            <form method="POST"  @submit.prevent="submitForm()" class="modal-form">
                                <div class="row mb-3">
                                    <label for="email" class="block text-gray-500 font-bold mb-1 pr-4 my-auto">Email</label>

                                    <div>
                                        <input v-model="form.email" type="email" class="appearance-none border  rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-yellow-500 form-control" name="email" required>
                                    </div>
                                </div>

                                <div class="row mb-3">
                                    <label for="firstname" class="block text-gray-500 font-bold mb-1 pr-4 my-auto">First Name</label>

                                    <div>
                                        <input id="firstname" v-model="form.firstname" type="text" class="appearance-none border  rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-yellow-500 form-control " name="firstname"  required>

                                    </div>
                                </div>

                                <div class="row mb-3">
                                    <label for="lastname" class="block text-gray-500 font-bold mb-1 pr-4 my-auto">Last Name</label>

                                    <div>
                                        <input id="lastname" v-model="form.lastname" type="text" class="appearance-none border  rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-yellow-500 form-control " name="lastname"  required>

                                    </div>
                                </div>

                                <div class="row mb-3">
                                    <label for="password" class="block text-gray-500 font-bold mb-1 pr-4 my-auto">Password</label>

                                    <div>
                                        <input id="password" v-model="form.password" type="password" class="appearance-none border  rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-yellow-500 form-control " name="password"  required>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <label for="confirm_password" class="block text-gray-500 font-bold mb-1 pr-4 my-auto">Confirm Password</label>

                                    <div>
                                        <input id="confirm_password" v-model="form.confirm_password" type="password" class="appearance-none border  rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-yellow-500 form-control " name="confirm_password"  required>

                                    </div>
                                </div>
                                <password-validator @readyTosend="readyTosend" :password="form.password" :confirm_password="form.confirm_password"></password-validator >

                                <br>
                                <div class="row mb-3">
                                    <div class="flex">
                                        <input id="agreeWithTerms" v-model="form.agreeWithTerms" type="checkbox" class="cursor-pointer border rounded text-gray-700" name="agreeWithTerms" required style="width: 20px; height: 20px;">
                                        <label for="agreeWithTerms" class="block text-gray-500 font-bold mb-1 ml-3 my-auto pb-0 cursor-pointer">I agree with Terms and Conditions</label>
                                    </div>
                                </div>
                              <div class="cf-turnstile my-4 w-full"
                                   :data-sitekey="cfkey"
                                   data-callback="onTurnstileSuccess"
                              ></div>
                                <div class="form-footer">
                                    <div class="">

                                        <button type="submit" :disabled="isLoading" class="action-button ml-auto border cursor-pointer rounded-md submit-button w-full" :class="{'!bg-emerald-400': isLoading}">
                                           <span v-if="isLoading" class="w-full py-2 text-center">Creating Profile ...</span>
                                           <span v-else>Create Account</span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Loader from "../../Loader.vue";

    export default {
        name: 'PatientRegister',
        props: ['registration-workflow', 'cfkey'],
        components: {Loader},
        data() {
            return {
              form: {},
              ReadyStatus: false,
              loadingRegister: true,
                isLoading: false
            }
        },
        methods: {
            ...mapActions('guestFunctions', ['loadGuestWorkflow']),
            ...mapActions('auth', ['register']),
            readyTosend(ReadyStatus) {
                this.ReadyStatus = ReadyStatus
            },
            submitForm()
            {
                this.isLoading = true;
                if(this.form.password !== this.form.confirm_password ) {
                    this.isLoading = false;
                    Swal.fire({
                        toast: true,
                        icon: 'error',
                        title: 'Passwords do not match',
                        animation: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true
                    });
                }else if(this.ReadyStatus === 'false'){
                    this.isLoading = false;
                    Swal.fire({
                        toast: true,
                        icon: 'error',
                        title: 'Please respect the password rules',
                        animation: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true
                    });
                }
                else {
                    axios.post('/patient/register/submit', this.form)
                        .then(res => {
                            this.isLoading = false;
                            Swal.fire({
                            toast: true,
                            icon: res.status === 201 ? 'success' : 'error',
                            title: res.status === 201 ? "You're Registered" : 'Something went wrong. Contact support',
                            animation: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: res.status === 201 ? 1500 : 1500,
                            timerProgressBar: true
                            });


                            window.location.href = '/patient/verify-email';

                        })
                        .catch(() => {
                            this.isLoading = false;
                            Swal.fire({
                            toast: true,
                            icon: 'error',
                            title: 'Something went wrong. Contact support',
                            animation: true,
                            position: 'top-right',
                            showConfirmButton: false,
                            timer: 1500,
                            timerProgressBar: true
                            });
                        });
                }
            }
        },
        created() {
            this.loadGuestWorkflow('register')
            .then(() => {
                this.loadingRegister = false
            }).catch(err => {
                this.loadingRegister = false
            })
        },
        computed: {
        // getting the postcode stored in vuex
        ...mapGetters('guestFunctions', ['GET_GUEST_WORKFLOW']),
        },
    }
</script>

<style lang="scss" scoped>
</style>
