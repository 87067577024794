<script>
import {mapActions, mapGetters, mapState} from "vuex";
import StripeCardCapture from "../../Utilities/StripeCardCapture.vue";
import PaymentProcessingButton from "../../Utilities/PaymentProcessingButton.vue";

export default {
    name: "AccountServices",
    components: {PaymentProcessingButton, StripeCardCapture},
    props: ['stripe_key'],
    data()
    {
      return {
         btnText: 'Activate',
          disabled: false
      }
    },
    methods: {
        ...mapActions('account', ['GET_APP_SERVICES', 'GET_USER_HAS_VALID_CARD']),
        activateService(service)
        {
            this.disabled = true;
            this.btnText = 'Activating ... Please wait';
            const servicecode = service.toLowerCase();
            const url = `/dashboard/activate-service/${servicecode}/user`;
            axios.post(url)
                .then((res) => {
                    this.btnText = 'Successful';
                    window.location.reload();
                }).catch((e) => {
                console.log(e)
            })
        }
    },
    computed: {
      ...mapState('account', ['services', 'user_has_card_on_system'])
    },
    mounted()
    {
        this.GET_APP_SERVICES();
        this.GET_USER_HAS_VALID_CARD();
    }
}
</script>

<template>
    <div>
        <div>

            <div class="w-full rounded shadow bg-white p-8">
                <div v-if="services.length">
                    <div class="flex flex-wrap">
                        <div
                            class="p-4 shadow rounded-lg cursor-pointer flex-col position-relative m-2 w-1/3"
                            v-for="(service, index) in services"
                        >
                            <div class="h-full w-full position-absolute top-0 left-0" type="button" data-bs-toggle="offcanvas" :data-bs-target="'#staticBackdrop' + service.id" aria-controls="staticBackdrop"></div>
                            <div class="m-auto">
                                <h6>{{service.name}}</h6>
                                <span class="badge rounded-pill text-bg-secondary" v-if="service.price === 0">Free</span>
                                <span class="badge rounded-pill text-bg-warning" v-if="service.price > 0 && !service.has_active_subscription">For a fee</span>
                                <span class="badge rounded-pill text-bg-success" v-if="service.has_active_subscription || service.price === 0">Active</span>
                            </div>
                            <div style="width:650px;" class="offcanvas offcanvas-end" data-bs-backdrop="static" tabindex="-1" :id="'staticBackdrop'+ service.id" :aria-labelledby="'staticBackdropLabel'+ service.id">
                                <div class="offcanvas-header">
                                    <h5 class="offcanvas-title" :id="'staticBackdropLabel'+ service.id">{{service.name}}</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                </div>
                                <div class="offcanvas-body">
                                    <div>
                                        <table class="w-full">
                                            <tr>
                                                <th>
                                                    <p class="font-bold">Name</p>
                                                </th>
                                                <td class="p-2">
                                                    <p>{{service.name}}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <p class="font-bold">Price</p>
                                                </th>
                                                <td class="p-2">
                                                    <p>£{{service.price}}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <p class="font-bold">Billing Cycle</p>
                                                </th>
                                                <td class="capitalize p-2">
                                                    <p>
                                                        {{service.bill_cycle}}
                                                    </p>
                                                </td>
                                            </tr>
                                        </table>
                                        <div class="my-4">
                                            <h6 class="font-bold text-slate-700">Description</h6>
                                            <p v-html="service.main_description"></p>
                                            <br><br>
                                            <p v-html="service.second_description"></p>
                                        </div>
                                        <div v-if="service.has_active_subscription || service.code === 'FREEDIRECTORY'">
                                            <p class="rounded shadow bg-green-200 text-green-900 text-center p-2">Active</p>
                                        </div>
                                        <div v-else>
                                            <div>
                                                <div v-if="user_has_card_on_system">
                                                    <PaymentProcessingButton :stripe_key="stripe_key" :service="service.code" :button-text="'activate'"></PaymentProcessingButton>
                                                </div>
                                                <div class="" v-else>
                                                    <p class="text-center p-3 rounded shadow bg-amber-300">You don't have a valid payment method setup. Please add a valid payment method to activate this service.</p>
                                                    <a role="button" href="/dashboard/account?tab=Billing" class="mt-3 btn btn-success">Add Payment Method</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <p>No services to show</p>
                </div>

            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">

</style>
