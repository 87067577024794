<template>
    <div class="">

        <div class="container">
        <div class="main-section mt-5">
            <div class="tab-wrapper my-5">
                <div class="tab-section overflow-x-scroll">
                    <ul class="flex p-0">
                        <li v-for="tab in GET_PAGE_TABS" :key="tab" class="mr-4 px-4 py-2 cursor-pointer rounded-lg" :class="{'active-tab': GET_ACTIVE_TAB == tab}" @click="changeActiveTab(tab)">{{tab}}</li>
                    </ul>
                </div>
            </div>

            <div class="inner-page">
                <component :is="pageComponent" :stripe_key="stripe_key"></component>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import ResetPasswordForm from './ResetPasswordForm.vue'
import DeleteAccountForm from './DeleteAccountForm.vue'
import AccountBilling from './AccountBilling.vue'

import { mapActions, mapGetters, mapMutations } from 'vuex';
import AccountServices from "./AccountServices.vue";

    export default {
        props: ['reviews', 'stripe_key'],
        data() {
            return {
            }
        },
        components:{
            ResetPasswordForm,
            DeleteAccountForm,
            AccountServices,
            AccountBilling
        },
        methods: {
            ...mapActions('account', ['changeActiveTab', 'setupAccountTabs']),
            ...mapMutations('reviews', ['setActiveTab'])
        },
        computed:{
            ...mapGetters('account', ['GET_PAGE_TABS', 'GET_ACTIVE_TAB']),
            pageComponent()
            {
                if(this.GET_ACTIVE_TAB !== 'undefined'){

                    switch (this.GET_ACTIVE_TAB) {
                        case 'Billing':
                            return AccountBilling;break;
                        case 'Services':
                            return AccountServices;break;
                        case 'Update Password':
                            return ResetPasswordForm;break;
                        case 'Delete Account':
                            return DeleteAccountForm;break;
                    }
                }
            }
        },
        created(){
            this.setupAccountTabs()
            const searchParams = new URLSearchParams(window.location.search);
            const tab = searchParams.get('tab') || 'Billing';
            this.setActiveTab(tab)
        },
    }
</script>
<style scoped lang="scss">
    .active-tab {
        background-color: #d2f7efb3;
        color:  #0EE2B1;
    }
</style>
