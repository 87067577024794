<style scoped lang="scss">
    .active-tab {
        background-color: #d2f7efb3;
        color:  #0EE2B1;
    }
</style>

<template>
    <div>
        <div class="pb-4" style="margin-top: -85px; position: fixed; width: 90%; z-index: 50;">
            <dashboard-info-topbar
                :id="clinician.id"
                :image="clinician.image"
                @openUpdateModal="openUpdateModal"
                :title="clinician.first_name+' '+clinician.last_name"
                :description="clinician.profession"
                :lastUpdated="clinician.updated_at"
            ></dashboard-info-topbar>
        </div>
        <div class="container overflow-hidden">

            <div class="main-section mt-5 overflow-y-scroll">
                <div class="tab-wrapper my-5">
                    <div class="tab-section overflow-x-scroll">
                        <ul class="flex p-0">
                            <li v-for="tab in GET_PAGE_TABS" :key="tab" class="mr-4 px-4 py-2 cursor-pointer rounded-lg" :class="{'active-tab': GET_ACTIVE_TAB == tab}" @click="changeActiveTab(tab)">{{tab}}</li>
                        </ul>
                    </div>
                </div>
                <div class="inner-page">
                    <component :practitionerRef="practitioner.ref" :is="pageComponent"></component>
                </div>
            </div>
        </div>
      <UpdateProfileImagesModal @closeModal="closeModal" v-if="UpdateProfileImagesModal" :idConcerned="idConcerned" :pictureConcerned="PractitionerImagePath" :chosenModal="modalId"/>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Breadcrumbs from '../../Breadcrumbs.vue';
import EditPersonalInformationSinglePractitioner from './EditPersonalInformationSinglePractitioner.vue';
import EditProfessionSinglePractitioner from './EditProfessionSinglePractitioner.vue';
import EditRegulatoryBodySinglePractitioner from './EditRegulatoryBodySinglePractitioner.vue';
import EditAssociationSinglePractitioner from './EditAssociationSinglePractitioner.vue';
import SinglePractitionerPersonalInformation from './SinglePractitionerPersonalInformation.vue';
import SinglePractitionerProfession from './SinglePractitionerProfession.vue';
import SinglePractitionerAssociation from './SinglePractitionerAssociation.vue';
import SinglePractitionerQualifications from './SinglePractitionerQualifications.vue';
import SinglePractitionerPublications from './SinglePractitionerPublications.vue';
import DashboardInfoTopbar from '../DashboardInfoTopbar.vue';
import UpdateProfileImagesModal from '../Clinics/UpdateProfileImagesModal.vue'
import AssociatedSateliteClinicsTable from './AssociatedSateliteClinicsTable.vue'
import AssociateServiceToPracitioner from './TabComponents/AssociateServiceToPracitioner.vue';
import PractitionerCompliance from './TabComponents/PractitionerCompliance.vue';
import HistoryLogs from '../Clinics/HistoryLogs.vue';
import PractitionerBio from './PractitionerBio.vue';

export default {
    props: ['practitioner', 'associations_list'],
    components: {
        EditPersonalInformationSinglePractitioner,
        EditProfessionSinglePractitioner,
        EditRegulatoryBodySinglePractitioner,
        EditAssociationSinglePractitioner,
        SinglePractitionerAssociation,
        SinglePractitionerQualifications,
        SinglePractitionerPublications,
        DashboardInfoTopbar,
        Breadcrumbs,
        AssociatedSateliteClinicsTable,
        UpdateProfileImagesModal,
        PractitionerCompliance,
        PractitionerBio,
        HistoryLogs,
    },
    data() {
        return {
            crumbs: [
                {name: 'Practitioners', url: '/dashboard/practitioners'},
                {name: 'View & Edit', url: '/dashboard/practitioners'},
                {name: this.practitioner.first_name+' '+this.practitioner.last_name , url: ''},
            ],
            clinician: {
                title: this.practitioner.title,
                first_name: this.practitioner.first_name,
                middle_name: this.practitioner.middle_name,
                last_name: this.practitioner.last_name,
                known_as: this.practitioner.known_as,
                is_owner: this.practitioner.is_owner,
                profession: this.practitioner.profession,
                has_professional_body: this.practitioner.has_professional_body,
                professional_body_name: this.practitioner.professional_body_name,
                professional_body_ID: this.practitioner.professional_ID,
                has_association: this.practitioner.has_association,
                association_name: this.practitioner.association_name,
                qualfications: this.practitioner.qualifications,
                years_of_experience: this.practitioneryears_of_experience,
                image: this.practitioner.image_path,
                id: this.practitioner.id,
            },
            professional_body: {
                id: this.practitioner.id,
                has_professional_body: this.practitioner.has_professional_body,
                professional_body_name: this.practitioner.professional_body_name,
                professional_body_id: this.practitioner.professional_body_ID,
            },
            association: {
                id: this.practitioner.id,
                has_association: this.practitioner.has_association,
                association_name: this.practitioner.association_name,
            },

            PractitionerImagePath: '',
            modalId: '',
            idConcerned: '',
            UpdateProfileImagesModal: false,
            active_tab: this.GET_ACTIVE_TAB,
            activePageComponent: null,
            loading: true,


        }
    },
    computed: {
        ...mapGetters('practitioner', ['GET_PAGE_TABS', 'GET_PRACTITIONER', 'GET_ACTIVE_TAB']),
        pageComponent()
        {
            if(this.GET_ACTIVE_TAB !== 'undefined'){

                switch (this.GET_ACTIVE_TAB) {
                    case 'Personal':
                        return SinglePractitionerPersonalInformation;break;
                    case 'Profession':
                        return SinglePractitionerProfession;break;
                    case 'Associations':
                        return SinglePractitionerAssociation;break;
                    case 'Qualifications':
                        return SinglePractitionerQualifications;break;
                    case 'Publications':
                        return SinglePractitionerPublications;break;
                    case 'Clinics':
                        return AssociatedSateliteClinicsTable;break;
                    case 'Services':
                        return AssociateServiceToPracitioner;break;
                    case 'Compliance':
                        return PractitionerCompliance;break;
                    case 'Bio':
                        return PractitionerBio;break;
                    case 'Logs':
                        return HistoryLogs;break;

                }
            }
        }
    },
    methods: {
        ...mapMutations('practitioner', ['SET_ASSOCIATION_LIST', 'SET_ACTIVE_TAB']),
        ...mapActions('commonFunctions', ['loadBreadcrumbs']),
        ...mapActions('practitioner', ['setUpPractitionerPage', 'changeActiveTab', 'setUrlTab']),
        ...mapActions('log', ['setLogs']),
        closeModal(){
            this.UpdateProfileImagesModal = false
        },
        openUpdateModal(PractitionerImagePath, idConcerned){
            this.UpdateProfileImagesModal = true
            this.PractitionerImagePath = PractitionerImagePath
            this.idConcerned = idConcerned
            this.modalId = 'updatePractitionerImage'
        }
    },
    mounted() {
        this.setUpPractitionerPage(this.practitioner.ref);
        this.loadBreadcrumbs(this.crumbs);
        this.SET_ASSOCIATION_LIST(this.associations_list);
        this.setUrlTab();
    },
    created(){
        const searchParams = new URLSearchParams(window.location.search);
        const tab = searchParams.get('tab') || 'Personal';
        this.SET_ACTIVE_TAB(tab)
        this.setLogs({type:'practitioner', data: this.practitioner})
    }

}
</script>
