<script>
import {mapActions, mapState} from "vuex";

export default {
    name: "StripeTransactions",
    methods: {
        ...mapActions('account', ['GET_TRANSACTIONS'])
    },
    computed: {
        ...mapState('account', ['transactions'])
    },
    mounted()
    {
        this.GET_TRANSACTIONS();
    }
}
</script>

<template>
    <div>
        <div v-if="transactions">
            <div class="mt-3">
                <table class="w-full">
                    <thead>
                        <th>Description</th>
                        <th>Amount</th>
                        <th>ID</th>
                        <th>Card</th>
                        <th>Status</th>
                        <th>Date</th>
                        <th></th>
                    </thead>
                    <tbody class="table-group-divider">
                        <tr v-for="transaction in transactions">
                            <td>
                                <p>{{transaction.description}}</p>
                            </td>
                            <td>
                                <p>£{{transaction.amount_charged}}</p>
                            </td>
                            <td>
                                <p>{{transaction.id}}</p>
                            </td>
                            <td>
                                <p>{{transaction.payment_method_brand.toUpperCase() + ' ending in ' + transaction.payment_method_last_four }}</p>
                            </td>
                            <td>
                                <p>{{transaction.refunded ? 'Refunded' : 'Paid' }}</p>
                            </td>
                            <td>
                                <p>{{transaction.date }}</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div v-else>
            <p>No Transactions to show</p>
        </div>
    </div>
</template>

<style scoped lang="scss">

</style>
