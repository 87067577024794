<style lang="scss" scoped>
  li {
    list-style: none;
  }

  .active-tab {
    background-image: linear-gradient(310deg, #17ad37, #98ec2d);
    border-radius: 50px;
    color: #ffff;
    font-weight: 500;
  }

  th, td {
    width: 20%!important;
  }

</style>

<template>
    <div class="wrapper mb-3">
      <div class="flex">
        <div class="text-separation flex mr-5">
          <h5 class="my-auto font-bold text-slate-500">Recent Profile Activity</h5>
        </div>
        <hr class="flex-grow my-auto opacity-50" style="background-color: rgba(0,0,0,0.2)!important">
      </div>
        <div class="mr-5">
          <div class="card bg-white shadow-lg rounded-xl container mt-5 p-4" v-if="!analy">
            <p class="mb-0">There are no analytics to show yet for this profile.</p>
          </div>
          <div class="card bg-white shadow-lg rounded-xl mt-5 container p-3" v-else>
            <div class="flex w-full justify-center mt-2">
                <div class="mr-5 ml-5" v-for="anaMenu in anaMenus" :key="anaMenu">
                  <li class="analytics-tab py-2 px-3 cursor-pointer transition"
                      :class="{'active-tab': current_step == anaMenu}"
                      @click.prevent="current_step = anaMenu"
                      v-text="anaMenu">
                  </li>
                </div>
              </div>
            <div class="container">
              <hr>
              <div v-show="current_step== 'Last Week'" class="">
                  <table class="table-auto w-full">
                    <thead>
                      <tr class="table-stats-results">
                        <th class="font-bold text-slate-500">Appearance in search results</th>
                        <th class="font-bold text-slate-500">Profile views</th>
                        <th class="font-bold text-slate-500">Website clicks</th>
                        <th class="font-bold text-slate-500">Telephone number views</th>
                        <th class="font-bold text-slate-500">Mobile number views</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ analy.intervals[7].num_searches }}</td>
                        <td>{{ analy.intervals[7].num_views }}</td>
                        <td>{{ analy.intervals[7].num_clicks }}</td>
                        <td>{{ analy.intervals[7].num_tel_clicks }}</td>
                        <td>{{ analy.intervals[7].num_mobile_clicks }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table class="table-auto w-full mt-3">
                    <thead>
                      <tr class="table-stats-results">
                        <th class="font-bold text-slate-500">Facebook clicks</th>
                        <th class="font-bold text-slate-500">Instagram clicks</th>
                        <th class="font-bold text-slate-500">Twitter clicks</th>
                        <th class="font-bold text-slate-500">LinkedIn clicks</th>
                        <th class="font-bold text-slate-500">Email sent</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="">{{ analy.intervals[7].num_fb_clicks }}</td>
                        <td class="">{{ analy.intervals[7].num_ig_clicks }}</td>
                        <td class="">{{ analy.intervals[7].num_tw_clicks }}</td>
                        <td class="">{{ analy.intervals[7].num_linkedin_clicks }}</td>
                        <td class="">{{ analy.intervals[7].num_emails_sent }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <hr>
                  <br>
                  <div class="second-section-result">
                        <h4 class="mb-4 font-bold text-slate-500">Searches you are appearing in</h4>
                        <table class="table-auto w-full">
                            <thead>
                              <tr class="table-stats-results">
                                <th class="font-bold text-slate-600">Search Term</th>
                                <th class="font-bold text-slate-600">Stats</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="term in analy.intervals[7].searches_keys.slice(0, 50)" :key="term">
                                <td>
                                    <p> {{term }} </p>
                                </td>
                                <td>
                                    <span>{{ analy.intervals[7].searches[term] != null ? analy.intervals[7].searches[term] : 0 }}</span>
                                    <span class="faded">(<span>{{ analy.intervals[7].searches[term] != null ? Math.round(analy.intervals[7].searches[term] / analy.intervals[7].num_searches * 10000) / 100 : 0 }}</span>%)</span>

                                </td>
                              </tr>
                            </tbody>
                        </table>
                  </div>
              </div>
              <div v-show="current_step== 'Last 30 Days'" class="">
                  <table class="table-auto w-full">
                    <thead>
                      <tr class="table-stats-results">
                        <th class="font-bold text-slate-500">Appearance in search results</th>
                        <th class="font-bold text-slate-500">Profile views</th>
                        <th class="font-bold text-slate-500">Website clicks</th>
                        <th class="font-bold text-slate-500">Telephone number views</th>
                        <th class="font-bold text-slate-500">Mobile number views</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ analy.intervals[30].num_searches }}</td>
                        <td>{{ analy.intervals[30].num_views }}</td>
                        <td>{{ analy.intervals[30].num_clicks }}</td>
                        <td>{{ analy.intervals[30].num_tel_clicks }}</td>
                        <td>{{ analy.intervals[30].num_mobile_clicks }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table class="table-auto w-full mt-3">
                    <thead>
                      <tr class="table-stats-results">
                        <th class="font-bold text-slate-500">Facebook clicks</th>
                        <th class="font-bold text-slate-500">Instagram clicks</th>
                        <th class="font-bold text-slate-500">Twitter clicks</th>
                        <th class="font-bold text-slate-500">LinkedIn clicks</th>
                        <th class="font-bold text-slate-500">Email sent</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ analy.intervals[30].num_fb_clicks }}</td>
                        <td>{{ analy.intervals[30].num_ig_clicks }}</td>
                        <td>{{ analy.intervals[30].num_tw_clicks }}</td>
                        <td>{{ analy.intervals[30].num_linkedin_clicks }}</td>
                        <td>{{ analy.intervals[30].num_emails_sent }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <hr>
                  <br>
                  <div class="second-section-result">
                        <h4 class="mb-4 font-bold text-slate-500">Searches you are appearing in</h4>
                        <table class="table-auto w-full">
                            <thead>
                              <tr class="table-stats-results">
                                <th class="font-bold text-slate-600">Search Term</th>
                                <th class="font-bold text-slate-600">Stats</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="term in analy.intervals[30].searches_keys" :key="term">
                                <td>
                                    <p> {{term }} </p>
                                </td>
                                <td>
                                    <span>{{ analy.intervals[30].searches[term] != null ? analy.intervals[30].searches[term] : 0 }}</span>
                                    <span class="faded">(<span>{{ analy.intervals[30].searches[term] != null ? Math.round(analy.intervals[30].searches[term] / analy.intervals[30].num_searches * 10000) / 100 : 0 }}</span>%)</span>

                                </td>
                              </tr>
                            </tbody>
                        </table>
                  </div>
              </div>

              <div v-show="current_step== 'Last 90 Days'" class="">
                  <table class="table-auto w-full">
                    <thead>
                      <tr class="table-stats-results">
                        <th class="font-bold text-slate-500">Appearance in search results</th>
                        <th class="font-bold text-slate-500">Profile views</th>
                        <th class="font-bold text-slate-500">Website clicks</th>
                        <th class="font-bold text-slate-500">Telephone number views</th>
                        <th class="font-bold text-slate-500">Mobile number views</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ analy.intervals[90].num_searches }}</td>
                        <td>{{ analy.intervals[90].num_views }}</td>
                        <td>{{ analy.intervals[90].num_clicks }}</td>
                        <td>{{ analy.intervals[90].num_tel_clicks }}</td>
                        <td>{{ analy.intervals[90].num_mobile_clicks }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table class="table-auto w-full mt-3">
                    <thead>
                      <tr class="table-stats-results">
                        <th class="font-bold text-slate-500">Facebook clicks</th>
                        <th class="font-bold text-slate-500">Instagram clicks</th>
                        <th class="font-bold text-slate-500">Twitter clicks</th>
                        <th class="font-bold text-slate-500">LinkedIn clicks</th>
                        <th class="font-bold text-slate-500">Email sent</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ analy.intervals[90].num_fb_clicks }}</td>
                        <td>{{ analy.intervals[90].num_ig_clicks }}</td>
                        <td>{{ analy.intervals[90].num_tw_clicks }}</td>
                        <td>{{ analy.intervals[90].num_linkedin_clicks }}</td>
                        <td>{{ analy.intervals[90].num_emails_sent }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <hr>
                  <br>
                  <div class="second-section-result">
                        <h4 class="mb-4 font-bold text-slate-500">Searches you are appearing in</h4>
                        <table class="table-auto w-full">
                            <thead>
                              <tr class="table-stats-results">
                                <th class="font-bold text-slate-600">Search Term</th>
                                <th class="font-bold text-slate-600">Stats</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="term in analy.intervals[90].searches_keys" :key="term">
                                <td>
                                    <p> {{term }} </p>
                                </td>
                                <td>
                                    <span>{{ analy.intervals[90].searches[term] != null ? analy.intervals[90].searches[term] : 0 }}</span>
                                    <span class="faded">(<span>{{ analy.intervals[90].searches[term] != null ? Math.round(analy.intervals[90].searches[term] / analy.intervals[90].num_searches * 10000) / 100 : 0 }}</span>%)</span>

                                </td>
                              </tr>
                            </tbody>
                        </table>
                  </div>
              </div>
            </div>

          </div>



        </div>
    </div>


</template>

<script>
export default {
    props: {
      reference: {
        type: String,
        required: false,
        default: null
      }
    },
    data() {
        return {
            anaMenus: [
                "Last Week",
                "Last 30 Days",
                "Last 90 Days",
            ],
            current_step: "Last Week",
            analy: '',
        }
    },

    methods: {

        async getStats(cref) {
            await axios.get('/dashboard/clinic-analytics/get/'+cref)
            .then( async function(response) {
                this.analy = await response.data;
            }.bind(this));
        },

        nextView(value) {
            this.current_step = value

        }

    },
    created() {
        this.getStats(this.reference);
    }

}
</script>
