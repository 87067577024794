<template>
    <div class="sender-message">
        <p class="message">{{ message.message }}</p>
    </div>
</template>

<script>
export default {
    name: 'SenderMessage',
    props: ['message']
};
</script>

<style scoped>
.sender-message {
    background-color: #d1e7dd;
    text-align: right;
    padding: 10px;
    margin: 5px;
    border-radius: 10px;
}
</style>
