<script>
import SingleChatPreview from "./SingleChatPreview.vue";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "ChatList",
    components: {SingleChatPreview},
    methods: {
      ...mapActions('chat', ['retrieveConversations', 'retrieveActiveChat']),
        updateChat() {
          this.retrieveConversations();
        }
    },
    computed: {
        ...mapGetters('chat', ['GET_CONVERSATIONS'])
    },
    mounted()
    {
        this.retrieveConversations();
        this.interval = setInterval(this.updateChat, 60000);


    },
    beforeDestroy() {
        clearInterval(this.interval);
    }}
</script>

<template>
    <div class="">
        <div>
            <div class="top-bar p-2 mb-3">
                <h3 class="font-bold">Chats</h3>
            </div>
            <div class="chat-list-body px-2" v-if="GET_CONVERSATIONS">
                <div v-for="conversation in GET_CONVERSATIONS" @click.prevent="retrieveActiveChat(conversation.id)">
                    <SingleChatPreview :conversation="conversation"/>
                </div>

            </div>
            <div v-else>
                <p>No chats to show</p>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">

</style>
