<template>

<div class="table-wrapper">
    <div class="table-header-wrapper">
        <div class="table-header w-full flex pb-4 border-bottom">
            <div class="table-title flex-grow h-full my-auto">
                <h4 class="my-auto p-0">{{ title }}</h4>
            </div>
            <div class="table-action-btn">
                <button class="px-3 py-2 capitalize text-white my-auto bg-[#0EE2B1] rounded flex" @click.prevent="triggerTableAction"><span class="mr-3"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg></span><span>{{ buttonText }}</span>
                </button>
            </div>
        </div>
        <div class="innertable-wrap">
            <table class="w-full">
                <thead class="bg-gray-200 border-b">
                    <tr>
                        <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-4 text-left" v-for="thead in theads">
                          {{ thead }}
                        </th>
                        <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- Dynamic Table Body -->
                    <slot class="" name="table-body"></slot>
                </tbody>
            </table>
        </div>
    </div>


</div>
</template>

<script>
export default {
    props: {
        title: String,
        buttonText: String,
        buttonFunction: String,
        theads: Array,
        refID: String,
        data: {
            required: false
        },
        ifempty: String
    },
    methods:
    {
        triggerTableAction()
        {
            this.$emit('triggerNewEntry', 'thisData')
        },
    }
}
</script>
