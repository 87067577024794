<template>
    <div class="">
        <table-framework
            :theads="theads"
            :tableTitle="'Your Services'"
            :showTableActionButton="true"
            :tableButtonText="'Add Service'"
            @tableAction="openAddServiceModal()"
        >
            <tbody slot="table-body">
                <tr class="" v-if="services.services.length == 0">
                    <p class="p-3 mx-2">No services added yet.</p>
                </tr>
                <tr v-else v-for="service in services.services">
                    <td style="padding: 12px 25px!important">{{ service.main_service_name }}</td>
                    <td class="capitalize" style="padding: 12px 25px!important">{{ JSON.parse(service.pricings).pricing_modality}}</td>
                    <td class="flex" style="padding: 12px 25px!important">
                        <a class="mx-2 btn btn-sm" role="button" :href="'/dashboard/services/view-service/'+service.clinic_ref+'/'+service.ref">Edit</a>
                        <button @click.prevent="deleteService(service.id)" class="mx-2" name="Delete Service"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5" style="color:red">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                            </svg>
                        </button>
                    </td>
                </tr>
            </tbody>
         </table-framework>
         <create-update-modal
            v-if="toggleServiceModal"
            @close="toggleServiceModal = false;"
            :title="'Add a new service'"
            :buttonText="'Add Service'"
            @save="saveService(modalData)"
         >
            <div class="" slot="content">
                    <AddServiceModalContent @eventListener="updateModalData($event)" :target="services" :type="'add'"></AddServiceModalContent>
            </div>

         </create-update-modal>
    </div>
</template>

<script>
import TableFramework from '../../Table.vue';
import clinicServicesTableHeads from '../../../constants/services/clinicServicesTableHeads';
import CreateUpdateModal from '../../CreateUpdateModal.vue';
import AddServiceModalContent from './AddServiceModalContent.vue';
import { mapActions } from 'vuex';

    export default {
        props: ['services'],
        components: {
            TableFramework,
            CreateUpdateModal,
            AddServiceModalContent
        },
        data() {
            return {
                theads: clinicServicesTableHeads,
                toggleServiceModal: false,
                modalData: {}
            }
        },
        methods: {
            ...mapActions('service', ['saveService', 'deleteService']),
            openAddServiceModal()
            {
                this.toggleServiceModal = true;
            },
            updateModalData(data)
            {
                this.modalData = data;
            }
        },
        created(){
            const searchParams = new URLSearchParams(window.location.search);
            const tab = searchParams.get('action') || '';
            if(tab === 'add'){
                this.openAddServiceModal()

            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set('action', '');
            const newUrl = window.location.origin + window.location.pathname + '?' + searchParams;
            window.history.pushState({path:newUrl},'',newUrl);
            }
        }
    }
</script>
