<script>
import {mapActions, mapGetters} from "vuex";
import PaginationTable from "../../PaginationTable.vue";
import MediButton from "../../Utilities/MediButton.vue";

export default {
    name: "ActiveServices",
    components: {MediButton, PaginationTable},
    data()
    {
      return {
          modalData: {},
          showModal: false
      }
    },
    methods: {
        ...mapActions('account', ['RETRIEVE_ACTIVE_SERVICES', 'START_LOADER']),
        triggerTableAction(eventData)
        {
            if(eventData.action === 'delete')
            {
                this.modalData.title = 'Are you sure you want to delete this subscription?'
                this.modalData.description = 'This service will remain active until the end of your billing period.'
                this.modalData.actionLink = '/user/subscription/delete/' + eventData.id;
                this.modalData.buttonText = 'Cancel Subscription';
                this.showModal = true;
            }
        },
        closeModal() {
            this.showModal = false;
            this.modalData = {};
        },
        triggerModalAction(action)
        {
            axios.post(action).then((res) => {

                    this.window.location.reload();

            }).catch((e) => {
               console.log(e)
            });
        }
    },
    computed: {
        ...mapGetters('account', ['GET_ACTIVE_SERVICES', 'GET_LOADER']),
        getModalData()
        {
            return this.modalData
        }
    },
    mounted() {
        this.START_LOADER();
        this.RETRIEVE_ACTIVE_SERVICES();
    }
}
</script>

<template>
    <div class="rounded-lg shadow bg-white h-100 mt-4">
        <div class="card-header p-4">
            <div class="col-6 d-flex align-items-center">
                <h6 class="mb-0">Active Services</h6>
            </div>
        </div>
        <div class="flex" v-if="GET_LOADER">
            <div class="spinner-border text-secondary mx-auto" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <div class="card-body p-3" v-if="GET_ACTIVE_SERVICES">
            <table class="w-full table">
                <tr v-for="(active_service, index) in GET_ACTIVE_SERVICES.data" key="index">
                    <td>
                        <p class="mb-0">{{active_service.service_quantity}} x {{active_service.service_name}}</p>
                    </td>
                    <td>
                        <p class="mb-0 capitalize">{{active_service.service_status}}</p>
                    </td>
                    <td>
                        <p class="mb-0"><span>{{active_service.cancel_at_period_end ? 'Ends on: ' : 'Next Billing: '}}</span> {{active_service.current_period_end}}</p>
                    </td>
                    <td v-if="!active_service.cancel_at_period_end">
                        <MediButton variant="gradient" color="dark" size="sm" @buttonClicked="triggerTableAction({action: 'delete', id:active_service.id})">Cancel</MediButton>
                    </td>
                </tr>
            </table>
            <div v-if="showModal" class="modal w-full show" ref="modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style="position: fixed; top:0; left: 50%; transform: translateX(-50%); background-color: transparent;">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="staticBackdropLabel">{{getModalData.title}}</h1>
                            <button type="button" class="btn-close"  @click.prevent="closeModal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            {{modalData.description}}
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" @click.prevent="closeModal">Close</button>
                            <button type="button" class="btn btn-primary" @click.prevent="triggerModalAction(modalData.actionLink)">{{modalData.buttonText}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <p>You don't have any active services on this account.</p>
        </div>
    </div>
</template>

<style scoped lang="scss">

</style>
