<template>
    <div class="receiver-message">
        <p class="message">{{ message.message }}</p>
    </div>
</template>

<script>
export default {
    name: 'ReceiverMessage',
    props: ['message']
};
</script>

<style scoped>
.receiver-message {
    background-color: #f8d7da;
    text-align: left;
    padding: 10px;
    margin: 5px;
    border-radius: 10px;
}
</style>
